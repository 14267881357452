import React, { Component, Fragment } from 'react';
import { Table, Button, Grid, Row, Col, FormGroup, FormControl, ControlLabel, Well } from 'react-bootstrap';
import Paginazione from '../components/Paginazione';
import { Redirect, Link } from "react-router-dom";
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoRichiedenti from '../components/ModaleErroreScaricamentoRichiedenti';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';

class RicercaUtentiPerISA extends Component {

    state = {
        nome: null,
        cognome: null,
        codiceFiscale: null,
        risultatiRicerca: null,
        risultatiPerPagina: 5,
        paginaCorrente: 1,
        redirectToHome: false,
        messaggioErrore: null,
        redirectToQuestionario: false,
    };
    // #region Eventi

    nomeCambiatoHandler = (event) => {
        this.setState({
            nome: event.target.value
        });
    }

    cognomeCambiatoHandler = (event) => {
        this.setState({
            cognome: event.target.value
        });
    }

    codiceFiscaleCambiatoHandler = (event) => {
        this.setState({
            codiceFiscale: event.target.value
        });
    }

    cercaClickedHandler = () => {

        this.performSearch(this.setRichiedentiResults, this.setErrorMessage);
    }


    cambiaPaginaHandler = (nuovaPagina) => {
        this.setState({
            paginaCorrente: nuovaPagina
        });
    }

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }



    vaiAQuestionarioClickedHandler = (richiedente) => {
        this.setState({
            redirectToQuestionario: true,
            richiedente: richiedente
        });
    }
    // #endregion    

    // #region Render  
    render() {
        if (this.state.redirectToHome === true) {
            return <Redirect to={{ pathname: "/" }} />
        }

        if (this.state.redirectToQuestionario === true) {
            return <Redirect to={{ pathname: "/RicercaQuestionariISA", state: { utente: this.state.richiedente } }} />
        }

        if (this.state.messaggioErrore !== null) {
            return <ModaleErroreScaricamentoRichiedenti
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        if (this.state.risultatiRicerca === null) {
            return <Spinner />;
        }

        return (
            <Fragment>
                <Well>
                    <Link to="/">Home</Link> / Ricerca ISA
                </Well>
                <Grid fluid={true}>
                    <Row>
                        <h3>Ricerca questionari ISA / ricerca utente</h3>
                    </Row>
                    <div className="MascheraRicerca">
                    <Row>
                        <FormGroup controlId="NomeFG">
                            <ControlLabel>Nome</ControlLabel>
                            <FormControl onKeyUp={(event) => { this.nomeCambiatoHandler(event); }} type="text" />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup controlId="CognomeFG">
                            <ControlLabel>Cognome</ControlLabel>
                            <FormControl onKeyUp={(event) => { this.cognomeCambiatoHandler(event); }} type="text" />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup controlId="CodiceFiscaleFG">
                            <ControlLabel>Codice fiscale</ControlLabel>
                            <FormControl onKeyUp={(event) => { this.codiceFiscaleCambiatoHandler(event); }} type="text" />
                        </FormGroup>
                    </Row>
                    <Row>
                        <Col xs={2} md={1}>
                            <Button onClick={this.cercaClickedHandler} bsStyle="danger">Cerca</Button>
                        </Col>
                    </Row>
                    </div>
                    <Row>&nbsp;</Row>
                    <Row>
                        {this.state.risultatiRicerca === null
                            ?
                            <Spinner />
                            :
                            <Table striped bordered condensed hover responsive>
                                <thead>
                                    <tr>
                                        <th className="text-center">Dettaglio</th>
                                        <th>Cognome</th>
                                        <th>Nome</th>
                                        <th>Codice fiscale</th>
                                        <th className="text-right">Compil.validate</th>
                                        <th className="text-right">Ultima validazione</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderRisultatiRicerca()}
                                </tbody>
                            </Table>
                        }
                    </Row>
                    <Row className="text-center">
                        {this.state.risultatiRicerca !== null ?
                            <Paginazione
                                paginaClicked={this.cambiaPaginaHandler}
                                paginaCorrente={this.state.paginaCorrente}
                                risultati={this.state.risultatiRicerca.length}
                                elementiPerPagina={this.state.risultatiPerPagina}>
                            </Paginazione> : null}
                    </Row>
                </Grid>
            </Fragment>
        );
    }
    // #endregion    

    componentDidMount = () => {

        this.performSearch(this.setRichiedentiResults, this.setErrorMessage);
    }
    // #endregion  


    //#region Logica applicativa )con Rendering 

    setRichiedentiResults = (richiedenti) => {
        this.setState({
            risultatiRicerca: richiedenti.AnagraficheRichiedenti,
            paginaCorrente: 1,
            creazioneUtente: false
        });
    }

    setErrorMessage = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage
        });
    }


    //#region Logica applicativa )con Rendering 
    performSearch = (successFn, errorFn) => {
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const codiceFiscale = this.state.codiceFiscale;

        const data = {
            token: this.props.loginToken,
            method: "getutentiquestionariisa",
            param: {
                nome: nome,
                cognome: cognome,
                codiceFiscale: codiceFiscale
            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const utenti = JSON.parse(response.data.data);
                    if (globals.debug < 3) console.log(utenti);
                    successFn(utenti);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti compilazioni error: " + error);
                errorFn(error.message);
            });

        return;
    }


    renderRisultatiRicerca = () => {

        const paginaAttuale = this.state.risultatiRicerca.filter((obj, i) => {

            return (i >= (this.state.paginaCorrente - 1) * this.state.risultatiPerPagina &&
                i < this.state.paginaCorrente * this.state.risultatiPerPagina);
        });

        const risultati = paginaAttuale.map((obj, i) => {
            let dataUltimaCompilazione = (obj.dataUltimaCompilazione === null ? "" : moment(obj.dataUltimaCompilazione, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY"));

            const handlerClickVaiAQuestionario = () => {
                this.vaiAQuestionarioClickedHandler(obj);
            };

            let occhio = (obj.nrQuestionariCompilati > 0
                ?
                <i style={{ cursor: "pointer", color: "black", fontSize: "xx-large" }} onClick={handlerClickVaiAQuestionario} className="fa fa-eye"></i>
                : null
            )

            return (
                <tr key={obj.id}>
                    <td className="text-center">{occhio}</td>
                    <td>{obj.cognome}</td>
                    <td>{obj.nome}</td>
                    <td>{obj.codiceFiscale}</td>
                    <td className="text-right">{obj.nrQuestionariCompilati}</td>
                    <td className="text-right">{dataUltimaCompilazione}</td>

                </tr>
            );
        });

        return risultati;

    }

}
//#endregion 

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(RicercaUtentiPerISA);
