import React, { Component } from 'react';
import { Button, Grid, Row, Col, Panel, ListGroup, ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Redirect } from "react-router-dom";
import Spinner from '../components/Spinner';
import axios from 'axios';
import ModaleErroreStampaPdf from '../components/ModaleErroreStampaPdf';
import ModaleErroreScaricamentoDefinizioneQuestionarioASSO from '../components/ModaleErroreScaricamentoDefinizioneQuestionarioASSO';
import ModaleErroreScaricamentoDefinizioneQuestionarioISA from '../components/ModaleErroreScaricamentoDefinizioneQuestionarioISA';
import ModaleStampaQuestionario from '../components/ModaleStampaQuestionario';
import * as globals from '../comuni/constants';


class StampeQuestionari extends Component {

  state = {
    redirectToHome: false,
    apriModaleStampaASSO: false,
    apriModaleStampaISA: false,
    messaggioErroreStampaPdf: null,
    isaVisibility: "none",
    assoVisibility: "none",
    chiudiModaleCliccato: false,
    nomeFileStampaPdf: null,
    base64PdfQuestionario: null,
    showSpinner: false,
  };

  // #region modali    
  chiudiModaleCliccato = () => {
    this.setState({
      apriModaleStampaASSO: false,
      apriModaleStampaISA: false,
    });
  }

  popolaModale = () => {
    let modale = null;

    if (this.state.apriModaleStampaASSO === true) {
      modale = <ModaleStampaQuestionario
        chiudiModaleClicked={this.chiudiModaleCliccato}
        nomeFilesPdf={this.state.nomeFileStampaPdf}
        filePdf={this.state.base64PdfQuestionario}
        tipoQuestionario="ASSO"
      />;
    }

    if (this.state.apriModaleStampaISA === true) {
      modale = <ModaleStampaQuestionario
        chiudiModaleClicked={this.chiudiModaleCliccato}
        nomeFilesPdf={this.state.nomeFileStampaPdf}
        filePdf={this.state.base64PdfQuestionario}
        tipoQuestionario="ISA"
      />;
    }

    if (this.state.messaggioErroreStampaPdf !== null) {
      modale = <ModaleErroreStampaPdf
        chiudiModaleClicked={() => this.setState({ messaggioErroreStampaPdf: null })}
        messaggioErrore={this.state.messaggioErroreStampaPdf}
      />;
    }

    if (this.props.messaggioErroreScaricamentoQuestionarioISA !== null) {
      return <ModaleErroreScaricamentoDefinizioneQuestionarioISA
        messaggioErrore={this.props.messaggioErroreScaricamentoQuestionarioISA}
        chiudiModaleClicked={this.chiudiModaleErroreClicked} />
    }

    if (this.props.messaggioErroreScaricamentoQuestionarioASSO !== null) {
      return <ModaleErroreScaricamentoDefinizioneQuestionarioASSO
        messaggioErrore={this.props.messaggioErroreScaricamentoQuestionarioASSO}
        chiudiModaleClicked={this.chiudiModaleErroreClicked} />
    }

    return modale;
  }

  chiudiModaleErroreClicked = () => {
    this.setState({ redirectToHome: true });
  }
  // #endregion


  // #region render
  render() {

    if (this.state.showSpinner === true) {
      return <Spinner />;
    }

    if (this.state.redirectToHome === true) {
      return <Redirect to={{ pathname: "/" }} />
    }

    const modale = this.popolaModale();

    return (
      <React.Fragment key="f1">
        <Grid fluid={true}>
          <Row>
            <Col xs={12} className="text-center"><Button onClick={this.stampaASSOHandler} style={{ width: "50%" }} bsStyle="default">Stampa ASSO vuoto</Button></Col>
          </Row>
          <Row>
            <Col xs={12}>&nbsp;</Col>
          </Row>
          <Row>
            <Col xs={12}>&nbsp;</Col>
          </Row>
          <Row>
            <Col xs={12} className="text-center"><Button onClick={this.stampaISAHandler} style={{ width: "50%" }} bsStyle="default">Stampa ISA vuoto</Button></Col>
          </Row>
        </Grid>
        {modale}
        {this.props.questionarioISA !== null ?
          <Grid fluid={true} id="isaPDF" style={{ display: this.state.isaVisibility }}>
            <Row>
              <Col xs={12} className="text-center"><h4>Dati identificativi del beneficiario</h4></Col>
            </Row>
            <Panel >
              <Panel.Body>
                <Row>
                  <Col xs={6} className="text-left"><h5>Nome</h5></Col>
                  <Col xs={6} className="text-left"><h5>Cognome</h5></Col>
                </Row>
                <Row>
                  <Col xs={6} className="text-left"><h5>Data di nascita (GG/MM/AAAA)</h5></Col>
                  <Col xs={6} className="text-left"><h5>Sesso</h5></Col>
                </Row>
                <Row>
                  <Col xs={6} className="text-left"><h5>Cittadinanza</h5></Col>
                  <Col xs={6} className="text-left"><h5>Comune residenza</h5></Col>
                </Row>
              </Panel.Body>
            </Panel>
            <div className="page_break">&nbsp;</div>
            {this.getDomandeISA()}
          </Grid>
          :
          null}
        {this.props.questionarioASSO !== null ?
          <Grid fluid={true} id="assoPDF" style={{ display: this.state.assoVisibility }}>
            <Row>
              <Col xs={12} className="text-center"><h4>Dati identificativi del beneficiario</h4></Col>
            </Row>
            <Panel >
              <Panel.Body>
                <Row>
                  <Col xs={6} className="text-left"><h5>Nome</h5></Col>
                  <Col xs={6} className="text-left"><h5>Cognome</h5></Col>
                </Row>
                <Row>
                  <Col xs={6} className="text-left"><h5>Data di nascita (GG/MM/AAAA)</h5></Col>
                  <Col xs={6} className="text-left"><h5>Sesso</h5></Col>
                </Row>
                <Row>
                  <Col xs={6} className="text-left"><h5>Cittadinanza</h5></Col>
                  <Col xs={6} className="text-left"><h5>Comune residenza</h5></Col>
                </Row>
              </Panel.Body>
            </Panel>
            <div className="page_break">&nbsp;</div>
            {this.getDomandeASSO()}
          </Grid>
          :
          null}
      </React.Fragment>
    );
  }
  // #endregion

  toggleISAVisbility = () => {
    if (this.state.isaVisibility === "none") {
      this.setState({
        isaVisibility: "block"
      });
    } else {
      this.setState({
        isaVisibility: "none"
      });
    }
  }

  toggleASSOVisbility = () => {
    if (this.state.assoVisibility === "none") {
      this.setState({
        assoVisibility: "block"
      });
    } else {
      this.setState({
        assoVisibility: "none"
      });
    }
  }

  getDomandeISA = () => {
    return this.props.questionarioISA.domande.map((obj, i) => {
      return (<React.Fragment key={"f2-" + i}>
        <Row>
          <Col xs={12}><h4>Item n. {obj.numDomanda} Dim.di riferim. {obj.titolo}</h4></Col>
        </Row>
        <Row>
          <Panel >
            <Panel.Body>
              <Row>
                {obj.descrizione}
                &nbsp;&nbsp;
              </Row>
              &nbsp;
              <Row style={{ fontStyle: "Italic" }}>
                {obj.help}
              </Row>
            </Panel.Body>
          </Panel>
        </Row>
        <Row>
          <Col xs={12}><h4>Indicare il grado di accordo con l'affermazione dell'item.</h4></Col>
        </Row>
        <ListGroup className="striped">
          {obj.risposte.map((obj2, i2) => {
            return <ListGroupItem key={"rispostaISA" + obj.id + "-" + i2}><Row><Col xs={12}>{obj2.numero}. {obj2.descrizione}</Col></Row></ListGroupItem>
          })}
        </ListGroup>
        <div className="page_break">&nbsp;</div>
      </React.Fragment>);
    });
  }


  getDomandeASSO = () => {
    return this.props.questionarioASSO.domande.map((obj, i) => {
      return (<React.Fragment key={"f3-" + i}>
        <Row>
          <Col xs={12}><h4>{obj.titolo}, Item n. {obj.numDomanda}</h4></Col>
        </Row>
        <Row>
          <Panel >
            <Panel.Body>
              <Row>
                {obj.descrizione}
                &nbsp;&nbsp;
              </Row>
            </Panel.Body>
          </Panel>
        </Row>
        <Row>
          <Col xs={12}><h4>Risposte possibili</h4></Col>
        </Row>
        <ListGroup className="striped">
          {obj.risposte.map((obj2, i2) => {
            return <ListGroupItem key={"rispostaASSO" + obj.id + "-" + obj2.id + "-" + i2}><Row><Col xs={12}>{obj2.numero}. {obj2.descrizione}</Col></Row></ListGroupItem>
          })}
        </ListGroup>
        <div className="page_break">&nbsp;</div>
      </React.Fragment>);
    });
  }


  componentDidMount = () => {

    if (this.props.questionarioISA === null) {
      this.props.scaricaQuestionarioISA(this.props.loginToken);
    }

    if (this.props.questionarioASSO === null) {
      this.props.scaricaQuestionarioASSO(this.props.loginToken);
    }

  }

  // #region stampa pdf
  mostraStampaPdf = (datiPdfCreato, tipo) => {

    if (tipo === "asso") {
      this.setState({
        apriModaleStampaASSO: true,
        showSpinner: false,
      });
    }

    if (tipo === "isa") {
      this.setState({
        apriModaleStampaISA: true,
        showSpinner: false,
      });
    }

    this.setState({
      messaggioErroreStampaPdf: null,
      base64PdfQuestionario: datiPdfCreato.base64Pdf,
      nomeFileStampaPdf: datiPdfCreato.nomeFilesPdf,
    });
  }

  mostraModaleErroreStampaPdf = (messaggioErrore) => {
    this.setState({
      messaggioErroreStampaPdf: messaggioErrore,
      apriModaleStampa: false,
      base64PdfQuestionario: null,
      nomeFileStampaPdf: null,
      showSpinner: false,
    });
  }

  stampaASSOHandler = () => {
    this.creaStampaPdf(document.getElementById("assoPDF").innerHTML, "asso");
  }

  stampaISAHandler = () => {
    this.creaStampaPdf(document.getElementById("isaPDF").innerHTML, "isa");
  }

  // tipo = "asso" o "isa"
  creaStampaPdf = (htmlString, tipo) => {
    const successFn = this.mostraStampaPdf;
    const errorFn = this.mostraModaleErroreStampaPdf;

    htmlString = this.htmlStringPre + htmlString + this.htmlStringPost;

    const data = {
      token: this.props.loginToken,
      method: "getpdf" + tipo,
      param: {
        stringaHtml: htmlString,
        codicePseudonomizzato: tipo,
        tipoQuestionarioAsso: true,
      }
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    this.setState({showSpinner: true});

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          const datiPdfCreato = JSON.parse(response.data.data);
          successFn(datiPdfCreato, tipo);
        } else {
          if (globals.debug < 3) console.log(response.data.result.message);
          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        if (globals.debug < 3) console.log("chiamata creazione pdf Asso compilazione error: " + error);
        errorFn(error.message);
      });
  }

  htmlStringPre = "<!DOCTYPE html>  " +
    " <html lang=\"en\" style=\"font-size: 1.2em\"> " +
    " <head> " +
    "    <meta charset=\"utf-8\">" +
    "   <meta name=\"viewport\" content=\"width=device-width, initial-scale=1, shrink-to-fit=no\"> " +
    " <meta name=\"theme-color\" content=\"#000000\">" +
    "<link" +
    "   href=\"https://fonts.googleapis.com/css?family=Titillium+Web:400,200,200italic,300italic,300,400italic,600,600italic,700,700italic,900\" " +
    "  rel=\"stylesheet\" type=\"text/css\">" +
    "<link rel=\"stylesheet\" title=\"Carattere normale\" type=\"text/css\" media=\"screen\"" +
    "    href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/struttura.css\">" +
    "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/bootstrap-3.3.7.min.css\">" +
    "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/bootstrap-3.3.7-theme.min.css\">" +
    "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/all.css\">" +
    "<link rel=\"stylesheet\" type=\"text/css\" media=\"screen\"" +
    "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/ita-bootstrap.css\">" +
    "<link rel=\"stylesheet\" title=\"Carattere normale\" type=\"text/css\" media=\"screen\"" +
    "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/0-template-agid_modello_11947_16589.css\">" +
    "<link rel=\"alternate stylesheet\" title=\"Carattere grande\" type=\"text/css\" media=\"screen\"" +
    "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/caratteregrande.css\" disabled=\"\">" +
    "<link rel=\"alternate stylesheet\" title=\"Contrasto elevato\" type=\"text/css\" media=\"screen\"" +
    "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/fortecontrasto.css\" disabled=\"\">" +
    "<link rel=\"alternate stylesheet\" title=\"Senza grafica\" type=\"text/css\" media=\"screen\"" +
    "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/senzagrafica.css\" disabled=\"\">" +
    "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/nyroModal.css\" type=\"text/css\"" +
    "   media=\"screen\">" +
    "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/tipsy.css\">" +
    "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/jquery.tagit.css\">" +
    "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/tagit.ui-zendesk.css\">" +
    "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/VisualizzaQuestionarioISA.css\">" +
    "<link rel=\"stylesheet\" type=\"text/css\" media=\"print\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/stampa.css\">" +
    "<title>Stampa ASSO-ISA</title>" +
    "</head><body>";

  htmlStringPost = "</body></html>";

  // #endregion
}

// #region Redux
const mapStateToProps = state => {
  return {
    questionarioASSO: state.questionarioASSO,
    messaggioErroreScaricamentoQuestionarioASSO: state.messaggioErroreASSO,
    questionarioISA: state.questionarioISA,
    messaggioErroreScaricamentoQuestionarioISA: state.messaggioErroreISA,
    loginToken: state.loginToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    scaricaQuestionarioASSO: (token) => dispatch(actions.scaricaQuestionarioASSO(token)),
    scaricaQuestionarioISA: (token) => dispatch(actions.scaricaQuestionarioISA(token)),
    effettuaLogout: () => dispatch(actions.effettuaLogout()),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(StampeQuestionari);
