import React, { Component } from 'react';
import { Button, Grid, Row, Col, Radio, FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom";
import ModaleUtenteCreato from '../components/ModaleUtenteCreato';
import ModaleErroreValidazione from '../components/ModaleErroreValidazione';
import ModaleErroreSalvataggio from '../components/ModaleErroreSalvataggio';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/datepicker.css";
import it from 'date-fns/locale/it';
import "../css/form.css";
import axios from 'axios';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import Autocomplete from '../autocomplete/index'
import * as globals from '../comuni/constants';
import ModaleUtenteAggiornato from '../components/ModaleUtenteAggiornato';

class GestioneDatiUtente extends Component {

  state = {
    visualizzaModaleErroreValidazione: false,
    informazioniErrore: "",
    visualizzaModaleErroreCreazioneUtente: false,
    messaggioErroreCreazioneUtente: null,
    messaggioErroreRecuperoComuni: null,
    visualizzaModaleCreazioneUtenteCliccato: false,
    visualizzaModaleAggiornamentoUtenteCliccato: false,
    nome: "",
    cognome: "",
    codiceFiscale: "",
    sesso: null,
    dataNascita: null,
    cittadinanza: null,
    utenteCreato: null,
    ComuniResidenza: [],
    valueComuneResidenza: '',
  };

  // #region modali  
  popolaModale = () => {
    let modale = null;

    if (this.state.visualizzaModaleCreazioneUtenteCliccato === true) {
      modale = <ModaleUtenteCreato
        codiceUtente={this.state.utenteCreato.codicePseudonomizzato}
        chiudiModaleClicked={this.chiudiModaleCliccato}
      />;
    } else if (this.state.visualizzaModaleErroreValidazione === true) {
      modale = <ModaleErroreValidazione
        chiudiModaleClicked={this.chiudiModaleCliccato}
        informazioniErrore={this.state.informazioniErrore}
      />;
    } else if (this.state.visualizzaModaleErroreCreazioneUtente === true) {
      modale = <ModaleErroreSalvataggio
        chiudiModaleClicked={this.chiudiModaleCliccato}
        messaggioErrore={this.state.messaggioErroreCreazioneUtente}
      />;
    } else if (this.state.visualizzaModaleAggiornamentoUtenteCliccato === true) {
      modale = <ModaleUtenteAggiornato
        codiceUtente={this.state.utenteCreato.cognome + ' ' + this.state.utenteCreato.nome}
        chiudiModaleClicked={this.chiudiModaleCliccato}
      />;
    } else if (this.state.messaggioErroreRecuperoComuni !== null) {
      modale = <ModaleErroreSalvataggio
        chiudiModaleClicked={this.chiudiModaleCliccato}
        messaggioErrore={this.state.messaggioErroreRecuperoComuni}
      />;
    }

    return modale;
  }
  // #endregion    

  // #region eventi  

  creaCliccato = () => {
    if (this.state.nome &&
      this.state.cognome &&
      this.state.codiceFiscale &&
      this.state.sesso &&
      this.state.dataNascita &&
      this.state.cittadinanza &&
      this.state.valueComuneResidenza
      &&
      (/^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/.test(this.state.codiceFiscale))


    ) {

      //Per Max : se togli queste 3 righe viene memorizzato il giorno precedente
      let dataNascita = null;
      let m = moment.tz(moment(this.state.dataNascita).format('YYYY-MM-DD'), "Etc/GMT");
      dataNascita = m.toDate()

      this.creazioneUtenteASSO(this.mostraModaleUtenteCreato,
        this.mostraModaleErroreCreazioneUtente,
        this.state.nome,
        this.state.cognome,
        this.state.codiceFiscale,
        dataNascita,
        this.state.sesso,
        this.state.cittadinanza, this.state.valueComuneResidenza);

    } else {

      let errori = ""
      if (/^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/.test(this.state.codiceFiscale) === false) {

        errori = "Codice fiscale non corretto o incompleto."
      }


      this.setState(
        {
          visualizzaModaleErroreValidazione: true,
          informazioniErrore: errori
        });

    }
  }

  updateCliccato = () => {
    if (this.state.nome &&
      this.state.cognome &&
      this.state.codiceFiscale &&
      this.state.sesso &&
      this.state.dataNascita
      &&
      this.state.cittadinanza &&
      this.state.valueComuneResidenza
      &&
      (/^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/.test(this.state.codiceFiscale))

    ) {

      //Per Max : se togli queste 3 righe viene memorizzato il giorno precedente
      let dataNascita = null;
      let m = moment.tz(moment(this.state.dataNascita).format('YYYY-MM-DD'), "Etc/GMT");
      dataNascita = m.toDate()


      this.updateUtenteASSO(this.mostraModaleUtenteAggiornato,
        this.mostraModaleErroreCreazioneUtente,
        this.props.location.state.richiedente.id,
        this.state.nome,
        this.state.cognome,
        this.state.codiceFiscale,
        dataNascita,
        this.state.sesso,
        this.state.cittadinanza,
        this.state.valueComuneResidenza);
    } else {

      let errori = ""
      if (/^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/.test(this.state.codiceFiscale) === false) {

        errori = "Codice fiscale non corretto o incompleto."
      }


      this.setState(
        {
          visualizzaModaleErroreValidazione: true,
          informazioniErrore: errori
        });
    }
  }


  chiudiModaleCliccato = () => {
    this.setState({
      visualizzaModaleCreazioneUtenteCliccato: false,
      visualizzaModaleErroreValidazione: false,
      visualizzaModaleErroreCreazioneUtente: false,
      visualizzaModaleAggiornamentoUtenteCliccato: false,
      messaggioErroreRecuperoComuni: null,
    });
  }


  nomeCambiatoHandler = (event) => {
    this.setState({
      nome: event.target.value
    });
  }

  cognomeCambiatoHandler = (event) => {
    this.setState({
      cognome: event.target.value
    });
  }

  codiceFiscaleCambiatoHandler = (event) => {
    this.setState({
      codiceFiscale: event.target.value
    });
  }

  sessoCambiatoHandler = (sesso) => {
    this.setState({
      sesso: sesso.substr(0, 1).toUpperCase()
    });
  }

  dataNascitaChange = (data) => {

    if (typeof data === "undefined" || data === null) {
      this.setState({ dataNascita: null });
      return;
    }

    let m = moment.tz(moment(data).format('YYYY-MM-DD HH:mm:ss'), "Etc/GMT");
    this.setState({ dataNascita: m.toDate() });
  }

  cittadinanzaCambiatoHandler = (cittadinanza) => {
    this.setState({
      cittadinanza: cittadinanza
    });
  }

  // #endregion  


  // #region Render

  disegnaButtonCreaSalva = () => {

    let buttonCreaSalva = "";

    const handlerCreaCliccato = () => {
      this.creaCliccato();
    };

    const handlerUpdateCliccato = () => {
      this.updateCliccato();
    };


    if (typeof this.props.location.state === "undefined" || this.props.location.state === null) {
      buttonCreaSalva = <Button onClick={handlerCreaCliccato} bsStyle="success">Crea</Button>;

    }
    else {
      buttonCreaSalva = <Button onClick={handlerUpdateCliccato} bsStyle="success">Salva</Button>
    }
    return (
      <React.Fragment>
        {buttonCreaSalva}
      </React.Fragment>
    );
  }



  render() {
    registerLocale('it', it);

    const modale = this.popolaModale();

    if (modale === null && this.state.utenteCreato !== null) {
      return <Redirect to={{ pathname: "/CreazioneQuestionari", state: { utente: this.state.utenteCreato } }} />
    }

    const nomeClass = this.state.nome ? null : "error";
    const cognomeClass = this.state.cognome ? null : "error";
    const codiceFiscaleClass = this.state.codiceFiscale ? null : "error";
    const dataNascitaClass = this.state.dataNascita ? "form-control" : "form-control error";
    const sessoClass = this.state.sesso ? null : "cbdiverror";

    const cittadinanzaClass = this.state.cittadinanza ? null : "cbdiverror";
    const comuneResidenzaClass = this.state.valueComuneResidenza ? null : "error";


    const sesso = this.state.sesso;
    const cittadinanza = this.state.cittadinanza;



    return (<Grid fluid={true}>
      <Row>
        <h3>Nuovo utente per compilazioni</h3>
      </Row>
      <Row>
        <FormGroup controlId="NomeFG">
          <ControlLabel>Nome</ControlLabel>
          <FormControl className={nomeClass} onKeyUp={(event) => { this.nomeCambiatoHandler(event); }} type="text" onChange={(e) => { this.setState({ nome: e.target.value }) }} value={this.state.nome} />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup controlId="CognomeFG">
          <ControlLabel>Cognome</ControlLabel>
          <FormControl className={cognomeClass} onKeyUp={(event) => { this.cognomeCambiatoHandler(event); }} type="text" onChange={(e) => { this.setState({ cognome: e.target.value }) }} value={this.state.cognome} />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup controlId="CodiceFiscaleFG">
          <ControlLabel>Codice fiscale</ControlLabel>
          <FormControl className={codiceFiscaleClass} onKeyUp={(event) => { this.codiceFiscaleCambiatoHandler(event); }} onChange={(e) => { this.setState({ codiceFiscale: e.target.value }) }} type="text" value={this.state.codiceFiscale} />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className={sessoClass}    >
          <Radio checked={sesso === "M"} onChange={() => { this.sessoCambiatoHandler("maschio"); }} onClick={() => { }} id="sessoMaschio" name="radioGroup" inline>
            Maschio
          </Radio>{' '}
          <Radio checked={sesso === "F"} onChange={() => { this.sessoCambiatoHandler("femmina"); }} onClick={() => { }} id="sessoFemmina" name="radioGroup" inline>
            Femmina
          </Radio>
        </FormGroup>
      </Row>

      <Row>
        <FormGroup controlId="DataNascitaFG">
          <ControlLabel>Data di Nascita</ControlLabel>
          <DatePicker showYearDropdown={true} className={dataNascitaClass} locale='it' dateFormat="dd/MM/yyyy" id="DataNascitaFG" selected={this.state.dataNascita} onChange={(data) => { this.dataNascitaChange(data); }} />
        </FormGroup>
      </Row>

      <Row>
        <FormGroup className={cittadinanzaClass}   >
          <Radio checked={cittadinanza === "Italiana"} onChange={() => { this.cittadinanzaCambiatoHandler("Italiana"); }} onClick={(event) => { }} id="cittadinanzaItaliana" name="radioGroupCittadinanza" inline>
            Italiana
          </Radio>{' '}
          <Radio checked={cittadinanza === "Straniera"} onChange={() => { this.cittadinanzaCambiatoHandler("Straniera"); }} onClick={(event) => { }} id="cittadinanzaStraniera" name="radioGroupCittadinanza" inline>
            Straniera
          </Radio>
        </FormGroup>
      </Row>
      <Row>
        <ControlLabel>Comune di Residenza</ControlLabel>
      </Row>
      <Row>
        <FormGroup controlId="ComuneResidenzaFG">
          <Col >
            <Autocomplete
              inputProps={{ id: 'Comuni-autocomplete', className: comuneResidenzaClass + " form-control" }} //Pier <- comuneResidenzaClass per il rosso quando non valorizzazto; " form-control" per l'altezza uguale agli altri input
              wrapperStyle={{ position: 'relative', display: 'inline' }} //Pier : c'era inline-block
              value={this.state.valueComuneResidenza}
              items={this.state.ComuniResidenza}
              getItemValue={(item) => item.name}
              onSelect={(value, item) => {
                this.setState({
                  ComuniResidenza: [item],
                  valueComuneResidenza: value
                })
              }}
              onChange={(event, value) => {
                this.setState({
                  valueComuneResidenza: value
                });
                this.performSearchComuni(value, (val) => { this.setComuniResults(val); }, this.setErrorMessageComuni);
              }}
              renderMenu={children => (
                <div className="menu">
                  {children}
                </div>
              )}
              renderItem={(item, isHighlighted) => (
                <div
                  className={'item-highlighted'}
                  key={"comune" + item.id}
                >{item.name}</div>
              )}
            />
          </Col>
        </FormGroup>
      </Row>
      &nbsp;
      &nbsp;
      <Row>
      </Row>
      <Row>
        <Col xs={6} md={2}>
          <Link to="/CreaQuestionariASSO"><Button bsStyle="danger">Annulla</Button></Link>
        </Col>
        <Col className="text-right" xs={6} md={2}>
          {this.disegnaButtonCreaSalva()}
        </Col>
      </Row>
      {modale}
    </Grid>);


  }
  // #endregion 



  // #region Ciclo di vita del container
  componentDidMount = () => {

     if (globals.debug < 3) console.log("componentDidMount");

    if (typeof this.props.location.state !== "undefined" && this.props.location.state !== null) {

      this.ripristinaDatiRichiedente();
    }

  }

  ripristinaDatiRichiedente = () => {

    this.setState({
      nome: this.props.location.state.richiedente.nome,
      cognome: this.props.location.state.richiedente.cognome,
      dataNascita: moment(this.props.location.state.richiedente.dataNascita, "YYYY-MM-DDT00:00:00").toDate(),
      codiceFiscale: this.props.location.state.richiedente.codiceFiscale,
      sesso: this.props.location.state.richiedente.sesso,
      cittadinanza: this.props.location.state.richiedente.cittadinanza,
      valueComuneResidenza: this.props.location.state.richiedente.comuneResidenza,
    })

  }

  // #endregion 


  // #region Axios

  creazioneUtenteASSO = (successFn,
    errorFn,
    nome,
    cognome,
    codiceFiscale,
    dataNascita,
    sesso,
    cittadinanza,
    comuneResidenza) => {

    const data = {
      token: this.props.loginToken,
      method: "insertanagraficatrichiedente",
      param: {
        nome: nome,
        cognome: cognome,
        codiceFiscale: codiceFiscale,
        sesso: sesso,
        dataNascita: dataNascita,
        cittadinanza: cittadinanza,
        comuneResidenza: comuneResidenza
      }
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          const nuovoRecord = JSON.parse(response.data.data);
          successFn(nuovoRecord);

        } else {
          if (globals.debug < 3) console.log(response.data.result.message);
          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {

        if (globals.debug < 3) console.log("chiamata creazione richiedente error: " + error);
        errorFn(error.message);
      });

  };



  mostraModaleUtenteCreato = (utenteCreato) => {
    this.setState({
      visualizzaModaleCreazioneUtenteCliccato: true,
      utenteCreato: utenteCreato,
    });

  }

  mostraModaleErroreCreazioneUtente = (messaggioErrore) => {
    this.setState({
      visualizzaModaleErroreCreazioneUtente: true,
      messaggioErroreCreazioneUtente: messaggioErrore
    });
  }


  mostraModaleUtenteAggiornato = (utenteCreato) => {
    this.setState({
      visualizzaModaleAggiornamentoUtenteCliccato: true,
      utenteCreato: utenteCreato,
    });

  }


  updateUtenteASSO = (successFn,
    errorFn,
    idRichiedente,
    nome,
    cognome,
    codiceFiscale,
    dataNascita,
    sesso,
    cittadinanza,
    comuneResidenza
  ) => {

    const data = {
      token: this.props.loginToken,
      method: "updateanagraficatrichiedente",
      param: {
        id: idRichiedente,
        nome: nome,
        cognome: cognome,
        codiceFiscale: codiceFiscale,
        sesso: sesso,
        dataNascita: dataNascita,
        cittadinanza: cittadinanza,
        comuneResidenza: comuneResidenza
      }
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const nuovoRecord = JSON.parse(response.data.data);
          successFn(nuovoRecord);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata update richiedente error: " + error);
        errorFn(error.message);
      });

  };


  setComuniResults = (comuni) => {
    this.setState({
      ComuniResidenza: comuni,
    });
  }

  setErrorMessageComuni = (errorMessage) => {
    this.setState({
      messaggioErroreRecuperoComuni: errorMessage
    });
  }

  performSearchComuni = (filtro, successFn, errorFn) => {

    const data = {
      token: this.props.loginToken,
      method: "getcomuni",
      param: {
        filtro: filtro,

      }
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const utenti = JSON.parse(response.data.data);
          if (globals.debug < 3) console.log(utenti);
          successFn(utenti);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
        errorFn(error.message);
      });


    return;
  }

}
// #endregion     


// #region Redux
const mapStateToProps = state => {
  return {
    loginToken: state.loginToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    effettuaLogout: () => dispatch(actions.effettuaLogout()),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(GestioneDatiUtente);
