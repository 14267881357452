import React, { Component } from 'react';
import { Table, Button, Grid, Row, Col, FormGroup, FormControl, ControlLabel, Well } from 'react-bootstrap';
import Paginazione from '../components/Paginazione';
import { Redirect, Link } from 'react-router-dom';
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoRichiedenti from '../components/ModaleErroreScaricamentoRichiedenti';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';


class RicercaUtente extends Component {

    state = {
        nome: null,
        cognome: null,
        codiceFiscale: null,
        risultatiRicerca: null,
        risultatiPerPagina: 5,
        paginaCorrente: 1,
        creazioneUtente: false,
        editUtente: false,
        redirectToHome: false,
        messaggioErrore: null,
        erroreCredenziali: false,
        richiedente: null,
        editQuestionario:false
    };

    // #region Eventi  

    nomeCambiatoHandler = (event) => {
        this.setState({
            nome: event.target.value
        });
    }

    cognomeCambiatoHandler = (event) => {
        this.setState({
            cognome: event.target.value
        });
    }

    codiceFiscaleCambiatoHandler = (event) => {
        this.setState({
            codiceFiscale: event.target.value
        });
    }

    cercaClickedHandler = () => {
        this.performSearch(this.setRichiedentiResults, this.setErrorMessage);
    }

    annullaCreazioneUtenteClickedHandler = (event) => {
        if (globals.debug < 3) console.log("E' stato cliccato annulla");
        this.setState({
            creazioneUtente: false,
            editUtente: false
        });
    }

    creaUtenteClickedHandler = (event) => {
        if (globals.debug < 3) console.log("E' stato cliccato crea");
        this.setState({
            creazioneUtente: true
        });
    }

    editRichiedenteClickedHandler = (richiedente) => {
        this.setState({
            editUtente: true,
            richiedente: richiedente
        });
    }

    editQuestionarioClickedHandler = (richiedente) => {
        this.setState({
            editQuestionario: true,
            richiedente: richiedente
        });
    }


    cambiaPaginaHandler = (nuovaPagina) => {
        this.setState({
            paginaCorrente: nuovaPagina
        });
    }
    // #endregion 


    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }

    // #region  Render
    render() {

        if (this.state.redirectToHome === true) {
            return <Redirect to={{ pathname: "/" }} />
        }

        if (this.state.editUtente === true) {
            return <Redirect to={{ pathname: "/CreaQuestionariASSO/CreaUtente", state: { richiedente: this.state.richiedente } }} />
        }

        if (this.state.editQuestionario === true) {
            return <Redirect to={{ pathname: "/CreazioneQuestionari", state: { utente: this.state.richiedente } }} />
        }

        if (this.state.messaggioErrore !== null) {
            return <ModaleErroreScaricamentoRichiedenti
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        if (this.props.utentiASSO === null) {
            return <Spinner />;
        }

        return (
            <React.Fragment>
                <Well>
                    <Link to="/">Home</Link> / COMPILAZIONI
                </Well>
                <Grid fluid={true}>
                    <Row>
                        <h3>Anagrafe utenti</h3>
                    </Row>
                    <Row>
                        La compilazione di ASSO o ISA richiede la presenza di una posizione anagrafica utente che potrà essere ricercata tra quelle già esistenti, oppure creata ex novo.

                    </Row>
                    <Row >
                        &nbsp;
                    </Row>
                    <Row>
                        <span style={{ fontWeight: "bold"}}>Ricerca&nbsp;</span><span  >Può avvenire compilando uno dei seguenti campi, oppure scorrendo le pagine dell’elenco sottostante ordinate alfabeticamente in base al cognome.</span>
                    </Row>
                    <Row >
                        &nbsp;
                    </Row>

                    <Row>
                        <span style={{ fontWeight: "bold" }}>Creazione&nbsp;</span><span >Il sistema richiederà i seguenti dati: Nome, Cognome, Codice Fiscale, Genere, Data di nascita, Cittadinanza italiana o straniera, Comune di Residenza. Il tentativo di creare una posizione anagrafica già esistente è segnalato con un messaggio di errore. In tal caso dovrà essere ricercata la posizione anagrafica già esistente.</span>
                    </Row>
                    <Row >
                        &nbsp;
                    </Row>
                    <Row><Col xs={7} md={3} style={{ paddingLeft: "0" }}>
                            <Link to="/CreaQuestionariASSO/CreaUtente"><Button bsStyle="success">Crea utente</Button></Link>
                        </Col> </Row>
                        <Row >
                        &nbsp;
                    </Row>
                    <div className="MascheraRicerca">
                    <Row>
                        <FormGroup controlId="NomeFG">
                            <ControlLabel>Nome</ControlLabel>
                            <FormControl onKeyUp={(event) => { this.nomeCambiatoHandler(event); }} type="text" />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup controlId="CognomeFG">
                            <ControlLabel>Cognome</ControlLabel>
                            <FormControl onKeyUp={(event) => { this.cognomeCambiatoHandler(event); }} type="text" />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup controlId="CodiceFiscaleFG">
                            <ControlLabel>Codice fiscale</ControlLabel>
                            <FormControl onKeyUp={(event) => { this.codiceFiscaleCambiatoHandler(event); }} type="text" />
                        </FormGroup>
                    </Row>
                    <Row>
                        <Col xs={5} md={2} style={{ paddingLeft: "0" }}>
                            <Button onClick={this.cercaClickedHandler} bsStyle="danger">Cerca</Button>
                        </Col>
                    </Row></div>
                    <Row>
                        &nbsp;
                </Row>
                    <Row>
                        {this.state.risultatiRicerca === null
                            ?
                            <Spinner />
                            :
                            <Table striped bordered condensed hover responsive>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Cognome</th>
                                        <th>Codice fiscale</th>
                                        <th>Codice pseud.</th>
                                        <th className="text-center">Anagrafe utente</th>
                                        <th className="text-center">Compila ASSO/ISA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderRisultatiRicerca()}
                                </tbody>
                            </Table>
                        }
                    </Row>

                    <Row className="text-center">
                        {this.state.risultatiRicerca !== null ?
                            <Paginazione
                                paginaClicked={this.cambiaPaginaHandler}
                                paginaCorrente={this.state.paginaCorrente}
                                risultati={this.state.risultatiRicerca.length}
                                elementiPerPagina={this.state.risultatiPerPagina}>
                            </Paginazione> : null}
                    </Row>
                </Grid>
            </React.Fragment>
        );
    }
    // #endregion 

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
        this.performSearch(this.setRichiedentiResults, this.setErrorMessage);
    }
    // #endregion 

    setRichiedentiResults = (richiedenti) => {
        this.setState({
            risultatiRicerca: richiedenti.AnagraficheRichiedenti,
            paginaCorrente: 1,
            creazioneUtente: false
        });
    }

    setErrorMessage = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage
        });
    }

    //#region Logica applicativa )con Rendering 
    performSearch = (successFn, errorFn) => {

        // const risultatiRicerca = chiamaWS(this.state.nome, this.state.cognome, this.state.codiceFiscale);
        const nome = this.state.nome;
        const cognome = this.state.cognome;
        const codiceFiscale = this.state.codiceFiscale;

        const data = {
            token: this.props.loginToken,
            method: "getutentiasso",
            param: {
                nome: nome,
                cognome: cognome,
                codiceFiscale: codiceFiscale
            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const utenti = JSON.parse(response.data.data);
                    if (globals.debug < 3) console.log(utenti);
                    successFn(utenti);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata utenti questionario error: " + error);
                errorFn(error.message);
            });


        return;
    }


    renderRisultatiRicerca = () => {

        const paginaAttuale = this.state.risultatiRicerca.filter((obj, i) => {
            return (i >= (this.state.paginaCorrente - 1) * this.state.risultatiPerPagina &&
                    i < this.state.paginaCorrente * this.state.risultatiPerPagina);
        });

        const risultati = paginaAttuale.map((obj, i) => {

            const handlerClickEditRichiedente = () => {
                this.editRichiedenteClickedHandler(obj);
            };

            const handlerClickEditQuestionario = () => {
                this.editQuestionarioClickedHandler(obj);
            };

            return (
                <tr key={obj.id}>
                    <td>{obj.nome}</td>
                    <td>{obj.cognome}</td>
                    <td>{obj.codiceFiscale}</td>
                    <td>{obj.codicePseudonomizzato}</td>
                    <td className="text-center"><i style={{ cursor: "pointer", color: "black", fontSize: "xx-large" }} onClick={handlerClickEditRichiedente} className="fa fa-male"></i></td>
                    <td className="text-center"><i style={{ cursor: "pointer", color: "black", fontSize: "xx-large" }} onClick={handlerClickEditQuestionario} className="fa fa-pencil"></i></td>
                </tr>
            );
        });

        return risultati;

    }
}
// #endregion 


// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(RicercaUtente);
