
import React, { Component, Fragment } from 'react';
import { Button, Grid, Row, Col, Well } from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import axios from 'axios';
import ModaleErroreValidazioneQuestionarioISA from '../components/ModaleErroreValidazioneQuestionarioISA';
import ModaleErroreScaricamentoInformazioniQuestionarioISA from '../components/ModaleErroreScaricamentoInformazioniQuestionarioISA';
import * as globals from '../comuni/constants';


class VisualizzaValidaQuestionarioISA extends Component {

    state = {
        questionario: null,
        utente: null,
        redirectToHome: false,
        redirectToUltimaDomanda: false,
        redirectToCercaUtenti: false,
        questionarioISATrovato: null,
        messaggioErroreQuestionarioIsaCercato: null,
        messaggioErroreValidazione: null,
        validazioneQuestionario: false,
        redirectToQuestionario: false
    };

    // #region Modale

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }

    popolaModale = () => {
        let modale = null;

        if (this.state.messaggioErroreValidazione !== null) {
            return <ModaleErroreValidazioneQuestionarioISA
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        if (this.props.visualizzaModaleErroreQuestionarioIsaCercato === true) {
            modale = <ModaleErroreScaricamentoInformazioniQuestionarioISA
                messaggioErrore={this.props.messaggioErroreQuestionarioIsaCercato}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        return modale;
    }
    // #endregion 


    // #region Eventi  
    validaClickedHandler = () => {
        this.performValida(this.setResults, this.setErrorMessage);
        this.scaricaQuestionarioByIdQuestionario();
    }

    tornaAllUltimaDomandaQuestionarioClickedHandler = () => {
        this.setState({
            redirectToUltimaDomanda: true
        });
    }

    scaricaQuestionarioByIdQuestionario = () => {

        const data = {
            token: this.props.loginToken,
            method: "getquestionarioISAbyId",
            param: {
                id: this.props.location.state.IdQuestionario,
            }
        };

        const setData = (questionario) => {
            this.setState({
                questionario: questionario,
                redirectToQuestionario: true,
            });
        }

        const errorsetData = (questionario) => {
            this.setState({
                questionario: null,
                redirectToCercaUtenti: true,
            });
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    if (globals.debug < 3) console.log(JSON.parse(response.data.data));
                    setData(JSON.parse(response.data.data));

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    errorsetData(JSON.parse(response.data.data));
                }
            })
            .catch(function (error) {
                // handle error
                errorsetData(JSON.parse(error));
                if (globals.debug < 3) console.log("chiamata scaricaQuestionarioByIdQuestionario ISA error: " + error);
            });

    }

    // #endregion

    render() {

        if (this.state.redirectToCercaUtenti === true) {
            return <Redirect to={{ pathname: "/CreaQuestionariASSO" }} />
        }

        if (this.state.redirectToQuestionario === true) {
            return <Redirect to={{ pathname: "/VisualizzaQuestionarioISA", state: { questionario: this.state.questionario, utente: this.props.location.state.utente } }} />
        }

        if (this.state.redirectToUltimaDomanda === true) {
            return <Redirect to={{
                pathname: "/QuestionarioISA"
                , state: {
                    IdQuestionario: this.props.location.state.questionarioCreato.id,
                    codicePseudonomizzatoQuestionario: this.props.location.state.questionarioCreato.codicePseudonomizzato,
                    utente: this.props.location.state.utente,
                    questionarioCreato: this.props.location.state.questionarioCreato,
                    risposte: this.props.location.state.risposte,
                    redirectFromVisualizzazione: true,
                    checkTrattamentoDati: true,
                    checkValutazioneImpatto: true, 
                    startQuestionario: true,
                }
            }} />
        }

        if (this.state.redirectToHome === true) {
            return <Redirect to={{ pathname: "/" }} />
        }

        const modale = this.popolaModale();
        let prossimoStep = null;
        
        if (this.props.location.state.risposte.length === 50) {
            prossimoStep = (
                <React.Fragment>
                    <Grid fluid={true}>
                        <Row key="o1"><h4>ISA {this.props.location.state.codicePseudonomizzatoQuestionario}</h4></Row>
                        <Row key="o2">
                            <h5>Tutte le domande sono state risposte. Premi il pulsante sotto per completare l'ISA. </h5>
                            <h5>ATTENZIONE: dopo averlo completato non sarà più possibile modificarlo!</h5>
                        </Row>
                        <Row className="text-center" key="o3">
                            <Button onClick={this.validaClickedHandler} bsStyle="success">Completa</Button>
                        </Row>
                        <Row key="o4">&nbsp;&nbsp;&nbsp;&nbsp;</Row>
                        <Row key="o5">
                            <Col xs={9} ><h5 style={{ textAlign: "right" }}>
                                <i style={{ cursor: "pointer", fontSize: "xx-large" }} onClick={this.tornaAllUltimaDomandaQuestionarioClickedHandler} className="fa fa-chevron-circle-left"></i><span style={{ verticalAlign: "text-top", fontStyle: "italic" }} >    Torna all'ultima domanda del questionario</span>  &nbsp;
                            </h5>
                            </Col>
                        </Row>
                        {modale}
                    </Grid>
                </React.Fragment>);
        } else {
            prossimoStep = (
                <React.Fragment>
                    <Grid fluid={true}>
                        <Row key="o1"><h4>ISA {this.props.location.state.codicePseudonomizzatoQuestionario}</h4></Row>
                        <Row key="o2">
                            <h5>Non tutte le domande sono state risposte. </h5>
                            <h5>Torna al questionario e completalo.</h5>
                        </Row>
                        <Row className="text-center" key="o3">
                            <Button onClick={this.tornaAllUltimaDomandaQuestionarioClickedHandler} bsStyle="success">Tona al questionario</Button>
                        </Row>
                        <Row key="o4">&nbsp;&nbsp;&nbsp;&nbsp;</Row>
                        {modale}
                    </Grid>
                </React.Fragment>);
        }

        return (
            <Fragment>
                <Well>
                    <Link to="/">Home</Link> / <Link to="/CreaQuestionariISA">Crea questionario</Link> / <Link to={{ pathname: "/CreazioneQuestionari", state: { utente: this.props.location.state.utente } }}>Scegli ASSO o ISA</Link> / Compilazione ISA
                </Well>
                {prossimoStep}
            </Fragment>
        );
    }
    // #endregion

    //#region Logica applicativa   

    mostraQuestionarioISACercato = (questionarioTrovato) => {
        this.setState({
            questionarioISATrovato: questionarioTrovato,
            messaggioErroreQuestionarioIsaCercato: null,
            messaggioErroreValidazione: null,
            visualizzaModaleErroreQuestionarioIsaCercato: false,
            vaiAQuestionarioISA: true
        });
    }

    mostraModaleErroreQuestionarioIsaCercato = (messaggioErrore) => {
        this.setState({
            visualizzaModaleErroreQuestionarioIsaCercato: true,
            messaggioErroreQuestionarioIsaCercato: messaggioErrore
        });
    }


    cercaQuestionarioISA = () => {

        const successFn = this.mostraQuestionarioISACercato;
        const errorFn = this.mostraModaleErroreQuestionarioIsaCercato;
        const idQuestionariISA = this.props.location.state.questionario.isa_questionari_compilati;

        const data = {
            token: this.props.loginToken,
            method: "",
            param: {
                id: idQuestionariISA,

            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const recordTrovato = JSON.parse(response.data.data);
                    successFn(recordTrovato);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata creazione questionario error: " + error);
                errorFn(error.message);
            });
    };

    setResults = (questionarioValidato) => {
        this.setState({
            chiudiModaleSalvaQuestionario: false,
            validazioneQuestionario: true
        });
    }

    setErrorMessage = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage
        });
    }

    performValida = (successFn, errorFn) => {

        const IdQuestionario = this.props.location.state.IdQuestionario;


        const data = {
            token: this.props.loginToken,
            method: "validazionequestionarioisa",
            param: {
                id: IdQuestionario,
            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const questionarioValidato = JSON.parse(response.data.data);
                    if (globals.debug < 3) console.log(questionarioValidato);
                    successFn(questionarioValidato);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata salva validazione ASSO error: " + error);
                errorFn(error.message);
            });

        return;
    }

}

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};
// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(VisualizzaValidaQuestionarioISA);
