import React, { Component, Fragment } from 'react';
import { Button, Grid, Row, Well, Table } from 'react-bootstrap';
import { Redirect, Link } from "react-router-dom";
import axios from 'axios';
import ModaleErroreCreazioneRecuperoQuestionario from '../components/ModaleErroreCreazioneRecuperoQuestionario';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import ModaleErroreScaricamentoInformazioniQuestionarioISA from '../components/ModaleErroreScaricamentoInformazioniQuestionarioISA';
import * as globals from '../comuni/constants';
import moment from 'moment-timezone';
import Paginazione from '../components/Paginazione';


class CreazioneQuestionari extends Component {

  state = {
    visualizzaModaleErroreCreazioneQuestionario: false,
    messaggioErroreCreazioneQuestionario: null,
    visualizzaModaleErroreQuestionarioISACercato: false,
    messaggioErroreQuestionarioISACercato: null,
    anagrafica_Richiedente: null,
    // impostato dalla creazione con web method del WS oppure recuperato tra quelli meno vecchi di 72 ore e NON validati
    questionarioASSOTrovatoCreato: null,
    // impostato dalla creazione con web method del WS oppure recuperato tra quelli compilatati
    questionarioISATrovatoCreato: null,
    //ASSO CREATI
    risultatiRicercaAsso: null,
    risultatiPerPaginaAsso: 5,
    paginaCorrenteAsso: 1,
    messaggioErroreAsso: null,
    redirectToQuestionarioAsso: false,
    questionarioSelezionatoAsso: null,
    //ISA CREATI
    risultatiRicercaIsa: null,
    risultatiPerPaginaIsa: 5,
    paginaCorrenteIsa: 1,
    messaggioErroreIsa: null,
    redirectToQuestionarioIsa: false,
    questionarioSelezionatoIsa: null, 
  };

  // #region modali  
  popolaModale = () => {
    let modale = null;

    if (this.state.visualizzaModaleErroreCreazioneQuestionario === true) {
      modale = <ModaleErroreCreazioneRecuperoQuestionario
        chiudiModaleClicked={this.chiudiModaleCliccato}
        messaggioErrore={this.state.messaggioErroreCreazioneQuestionario}
      />;
    }

    if (this.props.visualizzaModaleErroreQuestionarioISACercato === true) {
      modale = <ModaleErroreScaricamentoInformazioniQuestionarioISA
        messaggioErrore={this.props.messaggioErroreQuestionarioISACercato}
        chiudiModaleClicked={this.chiudiModaleCliccato} />
    }


    return modale;
  }

  chiudiModaleCliccato = () => {
    this.setState({
      visualizzaModaleErroreCreazioneQuestionario: false,
      visualizzaModaleErroreQuestionarioISACercato: false,
    });
  }

  disegnaButtomQuestionarioISA = () => {

    let buttomQuestionarioISA = "";

    //se esiste ASSO ; questo ID è relativo ad un ASSO validato ; da query del server
    if (this.props.location.state.utente.IdQuestionarioASSO !== "undefined" && this.props.location.state.utente.IdQuestionarioASSO !== null) {

      buttomQuestionarioISA = <Button onClick={this.creaQuestionarioISA} bsStyle="default">Crea ISA</Button>

    }
    // se NON esiste ASSO; pulsante Disabilitato    
    else
      buttomQuestionarioISA = <Button disabled onClick={this.creaQuestionarioISA} bsStyle="default">Crea ISA</Button>

    return (
      <React.Fragment>
        {buttomQuestionarioISA}&nbsp;&nbsp;
         </React.Fragment>
    );


  }

  cambiaPaginaHandlerAsso = (nuovaPaginaAsso) => {
    this.setState({
        paginaCorrenteAsso: nuovaPaginaAsso,
    });
}

cambiaPaginaHandlerIsa = (nuovaPaginaIsa) => {
  this.setState({
      paginaCorrenteIsa: nuovaPaginaIsa,
  });
}


  render() {
    const modale = this.popolaModale();

    if (modale === null && this.state.questionarioASSOTrovatoCreato !== null) {
      const questionarioCreato = this.state.questionarioASSOTrovatoCreato;
      return <Redirect to={{ pathname: "/QuestionarioASSO", state: { questionarioCreato: questionarioCreato, utente: this.props.location.state.utente } }} />
    }

    if (modale === null && this.state.questionarioISATrovatoCreato !== null) {
      const questionarioCreato = this.state.questionarioISATrovatoCreato;
      return <Redirect to={{ pathname: "/QuestionarioISA", state: { questionarioCreato: questionarioCreato, utente: this.props.location.state.utente } }} />
    }

    if (this.state.redirectToQuestionarioAsso === true) {
      return <Redirect to={{ pathname: "/VisualizzaQuestionarioASSO", state: { questionario: this.state.questionarioSelezionatoAsso, utente: this.props.location.state.utente } }} />
    }

    if (this.state.redirectToQuestionarioIsa === true) {
      return <Redirect to={{ pathname: "/VisualizzaQuestionarioISA", state: { questionario: this.state.questionarioSelezionatoIsa, utente: this.props.location.state.utente } }} />
    }


    return (
      <Fragment>
        <Well>
          <Link to="/">Home</Link> / <Link to="/CreaQuestionariASSO">Crea compilazione</Link> / Scegli ASSO o ISA
        </Well>
        <Grid fluid={true}>
          <Row>
            <h3>Dettagli utente</h3>
          </Row>
          <Row style={{ fontStyle: "Italic" }}>
            <h6 xs={12}>{this.props.location.state.utente.nome} {this.props.location.state.utente.cognome}, Codice Fiscale: {this.props.location.state.utente.codiceFiscale}</h6>
          </Row>
          <Row>
            <Button onClick={this.creaQuestionarioASSO} bsStyle="primary">Crea ASSO</Button>&nbsp;&nbsp;
            {this.disegnaButtomQuestionarioISA()}
          </Row>
          {this.state.risultatiRicercaAsso !== null ?
            <Fragment>
              <Row><br></br><h3>ASSO - Compilazioni Precedenti</h3>
                <Table striped bordered condensed hover responsive>
                  <thead>
                    <tr>
                      <th className="text-center">Visualizza ASSO</th>
                      <th>Codice</th>
                      <th className="text-right">Data compilazione</th>
                      <th className="text-right">Valore Q</th>
                      <th className="text-right">Range Q</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderRisultatiRicercaAsso()}
                  </tbody>
                </Table>  </Row>
              <Row className="text-center">
                <Paginazione
                  paginaClicked={this.cambiaPaginaHandlerAsso}
                  paginaCorrente={this.state.paginaCorrenteAsso}
                  risultati={this.state.risultatiRicercaAsso.length}
                  elementiPerPagina={this.state.risultatiPerPaginaAsso}>
                </Paginazione>
              </Row> </Fragment>
            : null}

          {this.state.risultatiRicercaIsa !== null ?
            <Fragment>
              <Row><br></br><h3>ISA - Compilazioni Precedenti</h3>
                <Table striped bordered condensed hover responsive>
                  <thead>
                    <tr>
                      <th className="text-center">Visualizza ISA</th>
                      <th>Codice</th>
                      <th className="text-right">Data compilazione</th>
                      <th className="text-right">Punteggio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderRisultatiRicercaIsa()}
                  </tbody>
                </Table>  </Row>
              <Row className="text-center">
                <Paginazione
                  paginaClicked={this.cambiaPaginaHandlerIsa} 
                  paginaCorrente={this.state.paginaCorrenteIsa}
                  risultati={this.state.risultatiRicercaIsa.length}
                  elementiPerPagina={this.state.risultatiPerPaginaIsa}>
                </Paginazione>
              </Row> </Fragment>
            : null}
          {modale}
        </Grid>
      </Fragment>);
  }


  mostraQuestionarioAssoCreato = (questionarioCreato) => {
    this.setState({
      questionarioASSOTrovatoCreato: questionarioCreato
    });

  }

  // trova l'ASSO con tutte le risposte o lo crea , senza, nel caso non esiste, 
  creaQuestionarioASSO = () => {

    const successFn = this.mostraQuestionarioAssoCreato;
    const errorFn = this.mostraModaleErroreCreazioneQuestionario;
    const idAnagraficaRichiedente = this.props.location.state.utente.id;


    const data = {
      token: this.props.loginToken,
      method: "InsertQuestionarioASSO",
      param: {
        anagrafica_richiedenti: idAnagraficaRichiedente,
      }
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const nuovoRecord = JSON.parse(response.data.data);
          successFn(nuovoRecord);

        } else {
          if (globals.debug < 3) console.log(response.data.result.message);
          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        if (globals.debug < 3) console.log("chiamata creazione questionario error: " + error);
        errorFn(error.message);
      });

  };


  creaQuestionarioISA = () => {
    this.performCreazioneQuestionarioISA();
  }


  mostraQuestionarioISACreato = (questionarioCreato) => {
    this.setState({
      questionarioISATrovatoCreato: questionarioCreato
    });

  }

  mostraModaleErroreCreazioneQuestionario = (messaggioErrore) => {
    this.setState({
      visualizzaModaleErroreCreazioneQuestionario: true,
      messaggioErroreCreazioneQuestionario: messaggioErrore
    });

  }

  performCreazioneQuestionarioISA = () => {

    const successFn = this.mostraQuestionarioISACreato;
    const errorFn = this.mostraModaleErroreCreazioneQuestionario;
    const idAnagraficaRichiedente = this.props.location.state.utente.id;

    const data = {
      token: this.props.loginToken,
      method: "InsertQuestionarioISA",
      param: {
        anagrafica_richiedenti: idAnagraficaRichiedente,
      }
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const nuovoRecord = JSON.parse(response.data.data);
          successFn(nuovoRecord);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata creazione questionario error: " + error);
        errorFn(error.message);
      });

  };

  performSearchAsso = (successFn, errorFn) => {

    const IdRichiedente = this.props.location.state.utente.id;
    const dataDa = null;
    const dataA = null;
    const codicePseudonomizzato = null;

    const data = {
      token: this.props.loginToken,
      method: "getquestionariassorichiedente",
      param: {
        anagrafica_richiedenti: IdRichiedente,
        dataDa: dataDa,
        dataA: dataA,
        codicePseudonomizzato: codicePseudonomizzato
      }
    };

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const questionariAsso = JSON.parse(response.data.data);
          if (globals.debug < 3) console.log(questionariAsso);
          successFn(questionariAsso);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          errorFn(response.data.result.message);
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata compilazione dell'utente error: " + error);
        errorFn(error.message);
      });

    return;
  };

  renderRisultatiRicercaAsso = () => {

    const paginaAttualeAsso = this.state.risultatiRicercaAsso.filter((obj, i) => {
      return (i >= (this.state.paginaCorrenteAsso - 1) * this.state.risultatiPerPaginaAsso &&
        i < this.state.paginaCorrenteAsso * this.state.risultatiPerPaginaAsso);
    });

    const risultatiAsso = paginaAttualeAsso.map((obj, i) => {
      let dataCompilazione = (obj.dataCompilazione === null ? "" : moment(obj.dataCompilazione, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY"));

      const handlerClickVaiAQuestionarioAsso = () => {
        this.vaiAQuestionarioClickedHandlerAsso(obj);
      };


      if (obj.dataValidazione) {
        return (
          <tr key={obj.id}>

            <td className="text-center"><i style={{ cursor: "pointer", color: "black", fontSize: "xx-large" }} onClick={handlerClickVaiAQuestionarioAsso} className="fa fa-eye"></i></td>
            <td>{obj.codicePseudonomizzato}</td>
            <td className="text-right">{dataCompilazione}</td>
            <td className="text-right">{obj.valoreQ}</td>
            <td className="text-right">{obj.rangeQ}</td>
          </tr>
        );
      } else {
        return (
          <tr key={obj.id}>
            <td className="text-center">{obj.id}</td>
            <td>{obj.codicePseudonomizzato}</td>
            <td className="text-right">{dataCompilazione}</td>
            <td className="text-right">{obj.valoreQ}</td>
            <td className="text-right">{obj.rangeQ}</td>
          </tr>
        );
      }
    });

    return risultatiAsso;

  }

  vaiAQuestionarioClickedHandlerAsso = (questionarioAsso) => {
    this.setState({
      redirectToQuestionarioAsso: true,
      questionarioSelezionatoAsso: questionarioAsso
    });
  }

  performSearchIsa = (successFn, errorFn) => {

    const IdRichiedente = this.props.location.state.utente.id;
    const dataDa = null;
    const dataA = null;
    const codicePseudonomizzato = null;

    const data = {
      token: this.props.loginToken,
      method: "getquestionariisarichiedente",
      param: {
        anagrafica_richiedenti: IdRichiedente,
        dataDa: dataDa,
        dataA: dataA,
        codicePseudonomizzato: codicePseudonomizzato
      }
    };

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const questionariIsa = JSON.parse(response.data.data);
          if (globals.debug < 3) console.log(questionariIsa);
          successFn(questionariIsa);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          errorFn(response.data.result.message);
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata questionario dell'utente error: " + error);
        errorFn(error.message);
      });

    return;
  }

  renderRisultatiRicercaIsa = () => {

    const paginaAttualeIsa = this.state.risultatiRicercaIsa.filter((objIsa, iIsa) => {
      return (iIsa >= (this.state.paginaCorrenteIsa - 1) * this.state.risultatiPerPaginaIsa &&
        iIsa < this.state.paginaCorrenteIsa * this.state.risultatiPerPaginaIsa);
    });

    const risultatiIsa = paginaAttualeIsa.map((objIsa, iIsa) => {
      let dataCompilazioneIsa = (objIsa.dataCompilazione === null ? "" : moment(objIsa.dataCompilazione, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY"));

      const handlerClickVaiAQuestionarioIsa = () => {
        this.vaiAQuestionarioClickedHandlerIsa(objIsa);
      };
      if (objIsa.dataValidazione) {
        return (
          <tr key={objIsa.id}>
            <td className="text-center"><i style={{ cursor: "pointer", color: "black", fontSize: "xx-large" }} onClick={handlerClickVaiAQuestionarioIsa} className="fa fa-eye"></i></td>
            <td>{objIsa.codicePseudonomizzato}</td>
            <td className="text-right">{dataCompilazioneIsa}</td>
            <td className="text-right">{objIsa.punteggio}</td>
          </tr>
        );
      } else {
        return (
          <tr key={objIsa.id}>
            <td>{objIsa.id}</td>
            <td>{objIsa.codicePseudonomizzato}</td>
            <td className="text-right">{dataCompilazioneIsa}</td>
            <td className="text-right">{objIsa.punteggio}</td>
          </tr>
        );
      }
    });

    return risultatiIsa;
  }

  vaiAQuestionarioClickedHandlerIsa = (questionarioIsa) => {
    this.setState({
      redirectToQuestionarioIsa: true,
      questionarioSelezionatoIsa: questionarioIsa
    });
  }

  componentDidMount = () => {
    this.performSearchAsso(this.setRichiedentiResultsAsso, this.setErrorMessageAsso);
    this.performSearchIsa(this.setRichiedentiResultsIsa, this.setErrorMessageIsa);

  }

  setRichiedentiResultsAsso = (questionariAsso) => {
    this.setState({
      risultatiRicercaAsso: questionariAsso.QuestionariRichiedente,
      paginaCorrenteAsso: 1,
    });
  }

  setErrorMessageAsso = (errorMessageAsso) => {
    this.setState({
      messaggioErroreAsso: errorMessageAsso
    });
  }

  setRichiedentiResultsIsa = (questionariIsa) => {
    this.setState({
      risultatiRicercaIsa: questionariIsa.QuestionariRichiedente,
      paginaCorrenteIsa: 1,
    });
  }

  setErrorMessageAsso = (errorMessageIsa) => {
    this.setState({
      messaggioErroreIsa: errorMessageIsa
    });
  }

  componentDidUpdate = () => {
    if (globals.debug < 3) console.log("componentDidUpdate");

    if (this.state.domandaCorrente === 1 && this.state.risposte[0] === 7) {
      this.rispostaClickedHandler(this.props.questionarioASSO.domande[1].risposte[0]);
    }

  }

}


// #region Redux
const mapStateToProps = state => {
  return {
    loginToken: state.loginToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    effettuaLogout: () => dispatch(actions.effettuaLogout()),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(CreazioneQuestionari);
