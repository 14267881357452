import React, { Component, Fragment } from 'react';
import { FormGroup, FormControl, ControlLabel, Panel, Button, Grid, Row, Col, Well } from 'react-bootstrap';
import ModaleSalvaQuestionario from '../components/ModaleSalvaQuestionario';
import { Redirect, Link } from 'react-router-dom';
import ModaleErroreValidazioneQuestionarioASSO from '../components/ModaleErroreValidazioneQuestionarioASSO';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import ModaleErroreValidazione from '../components/ModaleErroreValidazione';
import * as globals from '../comuni/constants';

class RisultatoValidazione extends Component {

    state = {
        chiudiModaleSalvaQuestionario: true,
        redirectToCercaUtenti: false,
        messaggioErrore: null,
        validazioneQuestionario: false,
        redirectToHome: false,
        noteAdvise: null,
        visualizzaModaleErroreNoteAdvise: false,
        tornaIndietroAIndice:false,
        //richiesta di Valle Recchi di maggio 2019
        redirectToVisualizzaQuestionario:false
    };


    // #region Modali
    popolaModale = () => {
        let modale = null;

        if (this.state.chiudiModaleSalvaQuestionario === false) {
            modale = <ModaleSalvaQuestionario
                procediModaleClicked={this.procediModaleClicked}
            />;
        }

        if (this.state.messaggioErrore !== null) {
            return <ModaleErroreValidazioneQuestionarioASSO
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        if (this.state.visualizzaModaleErroreNoteAdvise === true) {
            modale = <ModaleErroreValidazione
                chiudiModaleClicked={this.chiudiModaleErroreNoteAdviseCliccato}
            />;
        }


        return modale;
    }

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }

    procediModaleClicked = () => {
        this.setState({
            chiudiModaleSalvaQuestionario: true,
            redirectToVisualizzaQuestionario:true

        });
    }

    cercaUtentiCliccato = () => {
        this.setState({
            chiudiModaleSalvaQuestionario: true,
            redirectToCercaUtenti: true
        });
    }

    chiudiModaleErroreNoteAdviseCliccato = () => {
        this.setState({ visualizzaModaleErroreNoteAdvise: false });
    }
    // #endregion


    // #region Eventi  

    noteAdviseCambiatoHandler = (event) => {
        this.setState({
            noteAdvise: event.target.value
        });
    }


    validaClickedHandler = () => 
    {
        // se ci sono advise e non si compilano le note si deve mettere blocco.
        if (this.props.location.state.advise.length > 0 && !this.state.noteAdvise) {
            this.setState({ visualizzaModaleErroreNoteAdvise: true });
        }
        else {
            this.performValida(this.setResults, this.setErrorMessage);
        }
    }

    tornaIndietroAIndiceClickedHandler = () => 
    {
        this.setState({ tornaIndietroAIndice: true });
    }

    // #endregion
    // #region  Rendering
    getAlertBloccanti = () => {
        const alert = this.props.location.state.alert; //  this.state.alertBloccanti;

        let alerts = null;
        alerts = alert.map((obj, i) => {

            let righe = null;
            righe = obj.dati_domande_risposte.map((obj, i) => {
                return (
                    <Row key={i}>
                        <Col xs={12}>
                            <Grid fluid={true}>
                                <Row><Col xs={12}>Item {obj.numDomanda} - {obj.descrizioneDomanda}&nbsp;&nbsp;<Link to={{ pathname: "/QuestionarioAsso", state: { utente: this.props.location.state.utente, item: obj, questionarioCreato: { id: this.props.location.state.IdQuestionario, risposte: this.props.location.state.risposte, codicePseudonomizzatoQuestionario: this.props.location.state.codicePseudonomizzatoQuestionario } } }}>&nbsp;<i style={{ cursor: "pointer" }} className="fa fa-chevron-circle-right"></i></Link></Col></Row>
                                <Row><Col xs={12}><h6>{obj.descrizioneRisposta}</h6></Col></Row>
                            </Grid>
                        </Col>
                    </Row>
                );

            });  //map domande risposte

            return (
                <React.Fragment>
                    <Row key="ali">
                        <Col xs={12}><h4>{obj.testo}</h4></Col>
                    </Row>
                    {righe}
                    <Row key="alf">
                        &nbsp;
                    </Row>
                </React.Fragment>
            );

        }); //map alert

        return (
            <Grid fluid={true}>
                {alerts}
            </Grid>
        );

    }   //getAlert

    getAdviseNonBloccanti = () => {
        const advise = this.props.location.state.advise; //this.state.adviseNonBloccanti;
        let advises = advise.map((obj, i) => {
            let righe = obj.dati_domande_risposte.map((obj, i) => {
                return (
                    <Row key={i}>
                        <Col xs={12}>
                            <Grid fluid={true}>
                                <Row><Col xs={12}>Item {obj.numDomanda} - {obj.descrizioneDomanda}&nbsp;&nbsp;<Link to={{ pathname: "/QuestionarioAsso", state: { utente: this.props.location.state.utente, item: obj, questionarioCreato: { id: this.props.location.state.IdQuestionario, risposte: this.props.location.state.risposte, codicePseudonomizzatoQuestionario: this.props.location.state.codicePseudonomizzatoQuestionario } } }}>&nbsp;<i style={{ cursor: "pointer" }} className="fa fa-chevron-circle-right"></i></Link></Col></Row>
                                <Row><Col xs={12}><h6>{obj.descrizioneRisposta}</h6></Col></Row>
                            </Grid>
                        </Col>
                    </Row>
                );

            });  //map domande risposte

            return (
                <React.Fragment>
                    <Row key="ai">
                        <Col xs={12}><h4>{obj.testo}</h4></Col>
                    </Row>
                    {righe}
                    <Row key="af">
                        &nbsp;
                    </Row>
                </React.Fragment>);

        }); //map advise

        return (
            <Grid fluid={true}>
                {advises}
            </Grid>
        );

    }   //getAdvise

    render() {

        if (this.state.redirectToCercaUtenti === true) {
            return <Redirect to={{ pathname: "/CreaQuestionariASSO" }} />
        }

        if (this.state.redirectToHome === true) {
            return <Redirect to={{ pathname: "/" }} />
        }


        if (this.state.redirectToVisualizzaQuestionario === true) {
            return <Redirect to={{ pathname: "/VisualizzaQuestionarioASSO", state: { 
                utente: this.props.location.state.utente,                
                questionario: this.props.location.state.questionarioCreato,
              } }} />
        }

        if (this.state.tornaIndietroAIndice === true) {
            return <Redirect to={{ pathname: "/IndiceQuestionariASSO", state: { 
                      risposte: this.props.location.state.risposte, 
                      IdQuestionario: this.props.location.state.IdQuestionario, 
                      codicePseudonomizzatoQuestionario: this.props.location.state.codicePseudonomizzatoQuestionario,
                      utente: this.props.location.state.utente,                
                      questionarioCreato: this.props.location.state.questionarioCreato,
                    } }} />
          }

        const modale = this.popolaModale();

        const noteAdviseClass = this.state.noteAdvise ? null : "error";

        let alert = null;
        if (this.props.location.state.alert.length > 0) {
            alert = (
                <React.Fragment>
                    <Row>
                        <h4 style={{ color: "red" }}>Alert</h4>
                    </Row>
                    <Row>
                        <h5>E' necessario correggere le risposte per procedere alla validazione</h5>
                    </Row>
                    <Row>
                        <Panel >
                            <Panel.Body>
                                {this.getAlertBloccanti()}
                            </Panel.Body>
                        </Panel>
                    </Row>
                    <Row>
                        &nbsp;
                    </Row>
                </React.Fragment>);
        }

        let risultatiAlert = null;
        let pulsanteValida = null;
        if (this.props.location.state.alert.length > 0) {
            risultatiAlert = (
                <React.Fragment>
                    <Row key="o2">
                        <h5>ASSO è stato completamente compilato, ma sono presenti Alert ed è necessario correggere le risposte in conflitto prima di validare.</h5>
                    </Row>
                    <Row key="o3">
                        &nbsp;
                    </Row>
                    {alert}
                </React.Fragment>);
        } else {
            risultatiAlert = (
                <React.Fragment>
                    <Row key="o2">
                        <h5>ASSO è formalmente valido. Premi il pulsante VALIDA per validarlo. </h5>
                        <h5>ATTENZIONE: dopo la validazione non sarà più possibile modificarlo.</h5>
                    </Row>
                    <Row key="o3">&nbsp;</Row>
                </React.Fragment>
            );
            pulsanteValida = (
                <React.Fragment>
                    <Row key="o4">
                       <Col xs={6} md={2}>
                        <Button onClick={this.validaClickedHandler} bsStyle="success">Valida</Button>
                        </Col>
                         <Col className="text-right" xs={6} md={2}>
                        <Button onClick={this.tornaIndietroAIndiceClickedHandler} bsStyle="success">Indietro</Button>
                        </Col>
                    </Row>
                    <Row key="o5">&nbsp;</Row>
                </React.Fragment>);

        }

        let risultatiAdvise = null;
        if (this.props.location.state.advise.length > 0) {
            risultatiAdvise = (
                <React.Fragment>
                    <Row key="o10">
                        <h4 style={{ color: "orange" }}>Advise</h4>
                    </Row>
                    <Row key="o11">
                        <h5>È possibile modificare le risposte prima del salvataggio. </h5>
                        <h5>Diversamente, la conferma di tali risposte verrà annotata in calce al report, quale promemoria per eventuali chiarimenti in sede di valutazione.</h5>
                    </Row>
                    <Row key="o12">&nbsp;</Row>
                    <Row key="o13">
                        <Panel >
                            <Panel.Body>
                                {this.getAdviseNonBloccanti()}
                            </Panel.Body>
                        </Panel>
                    </Row>
                    <Row key="o14">
                        <FormGroup controlId="NomeFG">
                            <ControlLabel>Note relative agli advise.</ControlLabel>
                            <FormControl className={noteAdviseClass} onKeyUp={(event) => { this.noteAdviseCambiatoHandler(event); }} componentClass="textarea" rows={10} />
                        </FormGroup>
                    </Row>
                </React.Fragment>);
        }

        return (
            <Fragment>
                <Well>
                    <Link to="/">Home</Link> / <Link to="/CreaQuestionariASSO">Crea questionario</Link> / <Link to={{ pathname: "/CreazioneQuestionari", state: { utente: this.props.location.state.utente } }}>Scegli ASSO o ISA</Link> / Compilazione ASSO
                </Well>
                <Grid fluid={true}>
                    <Row key="o1"><h4>ASSO {this.props.location.state.codicePseudonomizzatoQuestionario}</h4></Row>
                    {risultatiAlert}
                    {risultatiAdvise}
                    {pulsanteValida}
                    {modale}
                </Grid>
            </Fragment>
        );
    }
    // #endregion


    //#region Logica applicativa )con Rendering 

    setResults = (questionarioValidato) => {
        this.setState({
            chiudiModaleSalvaQuestionario: false,
            validazioneQuestionario: true
        });
    }

    setErrorMessage = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage
        });
    }

    performValida = (successFn, errorFn) => {

        const IdQuestionario = this.props.location.state.IdQuestionario;
        const noteAdvise = this.state.noteAdvise;

        const data = {
            token: this.props.loginToken,
            method: "validazionequestionarioasso",
            param: {
                id: IdQuestionario,
                noteAdvise: noteAdvise,
            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const questionarioValidato = JSON.parse(response.data.data);
                    if (globals.debug < 3) console.log(questionarioValidato);
                    successFn(questionarioValidato);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata salva validazione ASSO error: " + error);
                errorFn(error.message);
            });

        return;
    }

    // #endregion

}

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        scaricaQuestionarioASSO: (token) => dispatch(actions.scaricaQuestionarioASSO(token)),
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(RisultatoValidazione);
