import React, { Component, Fragment } from 'react';
import { Button, Grid, Row, Well } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { Link, Redirect } from "react-router-dom";
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoDefinizioneQuestionarioASSO from '../components/ModaleErroreScaricamentoDefinizioneQuestionarioASSO';
import axios from 'axios';
import ModaleErroreValidazioneQuestionarioASSO from '../components/ModaleErroreValidazioneQuestionarioASSO';
import * as globals from '../comuni/constants';


class IndiceQuestionario extends Component {

  state = {
    domandaCorrente: 0,
    redirectToHome: false,
    alert_Advise_QuestionarioValidato: null,
    validazioneQuestionario: false,
    messaggioErroreValidazioneQuestionario: null,
    visualizzaModaleErroreValidazioneQuestionario: false,
    rangeQ: null,
    valoreQ: 0
  };

  // #region modali    
  popolaModale = () => {
    let modale = null;

    if (this.state.visualizzaModaleErroreValidazioneQuestionario === true) {
      modale = <ModaleErroreValidazioneQuestionarioASSO
        chiudiModaleClicked={this.chiudiModaleErroreValidazioneQuestionarioCliccato}
        messaggioErrore={this.state.messaggioErroreValidazioneQuestionario}
      />;
    }

    return modale;
  }

  chiudiModaleErroreValidazioneQuestionarioCliccato = () => {
    this.setState({
      visualizzaModaleErroreValidazioneQuestionario: false,
    });
  }

  // #endregion


  // #region render

  getIndiceQuestionario = (questionario) => {

    let domande = null;
    let risposte = null;

    domande = questionario.domande.map((obj, i) => {

      const risposta = obj.risposte.find((valore, indice) => {
        return valore.numero === this.props.location.state.risposte[i]; //
      });

      let rispostaDescrizione = "";

      if (risposta === null || !(typeof risposta != 'undefined')) {
        rispostaDescrizione = "Nessuna risposta";
      }
      else {
        rispostaDescrizione = risposta.descrizione;
      }

      risposte = this.props.location.state.risposte;
      let IdQuestionario = this.props.location.state.IdQuestionario;

      if (obj.numDomanda === "1.2" && risposte[0] === 7) {
        return null;
      }

      var domanda = null;
      if (!(typeof risposte[obj.ordinale_domanda - 1] === 'undefined') && (risposte[obj.ordinale_domanda - 1] !== null && risposte[obj.ordinale_domanda - 1] !== "null")) {
        domanda = (<Link to={{
              pathname: "/QuestionarioAsso", state: {
                item: obj,
                questionarioCreato: {
                  risposte: risposte,
                  id: IdQuestionario,
                  codicePseudonomizzatoQuestionario: this.props.location.state.codicePseudonomizzatoQuestionario,
                },
                utente: this.props.location.state.utente,
              }
            }}><Row><h5>Item {obj.numDomanda}  {obj.descrizione}</h5></Row></Link>);
      } else {
        if ((!(typeof risposte[obj.ordinale_domanda - 2] === 'undefined') && 
            (risposte[obj.ordinale_domanda - 2] !== null && risposte[obj.ordinale_domanda - 2] !== "null")) ||
            ((obj.ordinale_domanda === 1) && (risposte.length === 0))) {
              domanda = (<Link to={{
                pathname: "/QuestionarioAsso", state: {
                  item: obj,
                  questionarioCreato: {
                    risposte: risposte,
                    id: IdQuestionario,
                    codicePseudonomizzatoQuestionario: this.props.location.state.codicePseudonomizzatoQuestionario,
                  },
                  utente: this.props.location.state.utente,
                }
              }}><Row><h5>Item {obj.numDomanda}  {obj.descrizione}</h5></Row></Link>);
  
            } else {
              domanda = <Row><h5 style={{ color: "grey" }}   > Item {obj.numDomanda}  {obj.descrizione}  </h5></Row>;
            }
      }

      return (<React.Fragment key={obj.id}>
                <Well>
                  { domanda }
                  <Row><h4>{rispostaDescrizione}</h4></Row>
                </Well>
              </React.Fragment>);
    });

    return domande;
  }

  chiudiModaleErroreClicked = () => {
    this.setState({ redirectToHome: true });
  }


  render() {

    if (this.state.redirectToHome === true) {
      return <Redirect to={{ pathname: "/" }} />
    }

    if (this.props.messaggioErroreScaricamentoQuestionarioASSO !== null) {
      return <ModaleErroreScaricamentoDefinizioneQuestionarioASSO
        messaggioErrore={this.props.messaggioErroreScaricamentoQuestionarioASSO}
        chiudiModaleClicked={this.chiudiModaleErroreClicked} />
    }

    if (this.state.validazioneQuestionario === true) {
      const alertQuestionarioValidato = this.state.alert_Advise_QuestionarioValidato.filter((obj, i) => {
        return (obj.contesto === "Alert");
      });

      const adviseQuestionarioValidato = this.state.alert_Advise_QuestionarioValidato.filter((obj, i) => {
        return (obj.contesto === "Advise");
      });

      return <Redirect to={{ pathname: "/RisultatoValidazione", state: {
                IdQuestionario: this.props.location.state.IdQuestionario, 
                questionario: this.props.questionarioASSO, 
                alert: alertQuestionarioValidato, 
                advise: adviseQuestionarioValidato, 
                risposte: this.props.location.state.risposte, 
                codicePseudonomizzatoQuestionario: this.props.location.state.codicePseudonomizzatoQuestionario,
                utente: this.props.location.state.utente,
                questionarioCreato: this.props.location.state.questionarioCreato
              } }} />
    }

    const modale = this.popolaModale();
    if (this.props.questionarioASSO === null) {
      return <Spinner />;
    }

    const indiceQuestionario = this.getIndiceQuestionario(this.props.questionarioASSO);

    let tastoValidaQuestionario = null;
    if (this.props.questionarioASSO.domande.length === this.props.location.state.risposte.length) {
      tastoValidaQuestionario = (<Row>
        <Button onClick={this.validaQuestionarioASSO} className="text-right" bsStyle="success">Valida ASSO</Button>
      </Row>);
    }

    return (
      <Fragment>

        <Well>
          <Link to="/">Home</Link> / <Link to="/CreaQuestionariASSO">Crea questionario</Link> / <Link to={{ pathname: "/CreazioneQuestionari", state: { utente: this.props.location.state.utente } }}>Scegli ASSO o ISA</Link> / Compilazione ASSO
        </Well>

        <Grid fluid={true}>
          <Row>
            <h3>Riepilogo {this.props.location.state.codicePseudonomizzatoQuestionario}</h3>
          </Row>
          <Row>&nbsp;</Row>
          <Row>
            {indiceQuestionario}
          </Row>
          {tastoValidaQuestionario}
          {modale}
        </Grid>
      </Fragment>);
  }


  // #endregion
  // #region Ciclo di vita del container
  componentDidMount = () => {

    if (this.props.questionarioASSO === null) {
      this.props.scaricaQuestionarioASSO(this.props.loginToken);
    }

  }


  mostraQuestionarioValidato = (alert_advise) => {

    let rangeQ = null
    let valoreQ = null
    if (alert_advise.length > 0) {
      rangeQ = alert_advise.rangeQ;
      valoreQ = alert_advise.valoreQ;
    }

    this.setState({
      alert_Advise_QuestionarioValidato: alert_advise,
      validazioneQuestionario: true,
      rangeQ: rangeQ,
      valoreQ: valoreQ,
    });

  }


  mostraModaleErroreCreazioneQuestionario = (messaggioErrore) => {
    this.setState({
      visualizzaModaleErroreValidazioneQuestionario: true,
      messaggioErroreValidazioneQuestionario: messaggioErrore,
    });

  }
  validaQuestionarioASSO = () => {

    const successFn = this.mostraQuestionarioValidato;
    const errorFn = this.mostraModaleErroreCreazioneQuestionario;
    const idQuestionario = this.props.location.state.IdQuestionario;

    const data = {
      token: this.props.loginToken,
      method: "getvalidazionequestionarioasso",
      param: {
        id: idQuestionario
      }
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {
        if (response.data.result.success === true) {
          // handle success
          const alert_advise = JSON.parse(response.data.data);
          successFn(alert_advise);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata validazione questionario error: " + error);
        errorFn(error.message);
      });

  };



}
// #endregion

// #region Redux
const mapStateToProps = state => {
  return {
    questionarioASSO: state.questionarioASSO,
    messaggioErroreScaricamentoQuestionarioASSO: state.messaggioErroreASSO,
    loginToken: state.loginToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    scaricaQuestionarioASSO: (token) => dispatch(actions.scaricaQuestionarioASSO(token)),
    effettuaLogout: () => dispatch(actions.effettuaLogout()),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(IndiceQuestionario);
