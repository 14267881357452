import React, { Component, Fragment } from 'react';
import { Table, Button, Grid, Row, Col, FormGroup, FormControl, ControlLabel, Well } from 'react-bootstrap';
import Paginazione from '../components/Paginazione';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/datepicker.css";
import it from 'date-fns/locale/it';
import { Redirect, Link } from "react-router-dom";
import Spinner from '../components/Spinner';
import moment from 'moment-timezone';
import ModaleErroreScaricamentoQuestionariRichiedenti from '../components/ModaleErroreScaricamentoQuestionariRichiedenti';
import axios from 'axios';
import { connect } from 'react-redux';
import * as globals from '../comuni/constants';

class RicercaQuestionariISA extends Component {

    state = {
        dallaData: null,
        allaData: null,
        codiceQuestionario: null,
        risultatiRicerca: null,
        risultatiPerPagina: 5,
        paginaCorrente: 1,
        redirectToHome: false,
        messaggioErrore: null,
        redirectToQuestionario: false,
    };


    // #region Eventi  
    codiceQuestionarioCambiatoHandler = (event) => {
        this.setState({
            codiceQuestionario: event.target.value
        });
    }

    cercaClickedHandler = () => {
        this.performSearch(this.setRichiedentiResults, this.setErrorMessage);
    }

    cambiaPaginaHandler = (nuovaPagina) => {
        this.setState({
            paginaCorrente: nuovaPagina,
        });
    }

    dallaDataChange = (data) => {
        if (typeof data === "undefined" || data === null) {
            this.setState({ dallaData: null });
            return;
        }
        let m = moment.tz(moment(data).format('YYYY-MM-DD HH:mm:ss'), "Etc/GMT");
        this.setState({ dallaData: m.toDate() });
    }

    allaDataChange = (data) => {
        if (typeof data === "undefined" || data === null) {
            this.setState({ allaData: null });
            return;
        }
        let m = moment.tz(moment(data).format('YYYY-MM-DD HH:mm:ss'), "Etc/GMT");
        this.setState({ allaData: m.toDate() });
    }

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }


    vaiAQuestionarioClickedHandler = (questionario) => {
        this.setState({
            redirectToQuestionario: true,
            questionarioSelezionato: questionario
        });
    }
    // #endregion    

    // #region Render  
    render() {
        registerLocale('it', it);

        if (this.state.redirectToHome === true) {
            return <Redirect to={{ pathname: "/" }} />
        }

        if (this.state.redirectToQuestionario === true) {
            return <Redirect to={{ pathname: "/VisualizzaQuestionarioISA", state: { questionario: this.state.questionarioSelezionato, utente: this.props.location.state.utente } }} />
        }


        if (this.state.messaggioErrore !== null) {
            return <ModaleErroreScaricamentoQuestionariRichiedenti
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        if (this.state.risultatiRicerca === null) {
            return <Spinner />;
        }

        return (
            <Fragment>
                <Well>
                    <Link to="/">Home</Link> / <Link to="/RicercaUtentiPerISA">Ricerca ISA</Link> / Seleziona ISA
                </Well>
                <Grid fluid={true}>
                    <Row>
                        <h3>Seleziona ISA per utente</h3>
                    </Row>
                    <Row>
                        {this.props.location.state.utente.nome}  {this.props.location.state.utente.cognome}   {this.props.location.state.utente.codiceFiscale}
                    </Row>
                    <div className="MascheraRicerca">
                    <Row>
                        <FormGroup controlId="DallaDataFG">
                            <ControlLabel>Dalla data</ControlLabel>
                            <DatePicker showYearDropdown={true} className="form-control" locale='it' dateFormat="dd/MM/yyyy" id="DallaDataFG" selected={this.state.dallaData} onChange={(data) => { this.dallaDataChange(data); }} />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup controlId="AllaDataFG">
                            <ControlLabel>Alla data</ControlLabel>
                            <DatePicker showYearDropdown={true} className="form-control" locale='it' dateFormat="dd/MM/yyyy" id="AllaDataFG" selected={this.state.allaData} onChange={(data) => { this.allaDataChange(data); }} />
                        </FormGroup>
                    </Row>
                    <Row>
                        <FormGroup controlId="CodiceQuestionarioFG">
                            <ControlLabel>Codice ISA</ControlLabel>
                            <FormControl onKeyUp={(event) => { this.codiceQuestionarioCambiatoHandler(event); }} type="text" />
                        </FormGroup>
                    </Row>
                    <Row>
                        <Col xs={2} md={1}>
                            <Button onClick={this.cercaClickedHandler} bsStyle="danger">Cerca</Button>
                        </Col>
                    </Row>
                    </div>
                    <Row>
                        &nbsp;
                    </Row>
                    <Row>
                        {this.state.risultatiRicerca === null
                            ?
                            <Spinner />
                            :
                            <Table striped bordered condensed hover responsive>
                                <thead>
                                    <tr>
                                        <th className="text-center">Visualizza ISA</th>
                                        <th>Codice</th>
                                        <th className="text-right">Data compilazione</th>
                                        <th className="text-right">Punteggio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderRisultatiRicerca()}
                                </tbody>
                            </Table>
                        }
                    </Row>
                    <Row className="text-center">
                        {this.state.risultatiRicerca !== null ?
                            <Paginazione
                                paginaClicked={this.cambiaPaginaHandler}
                                paginaCorrente={this.state.paginaCorrente}
                                risultati={this.state.risultatiRicerca.length}
                                elementiPerPagina={this.state.risultatiPerPagina}>
                            </Paginazione> : null}
                    </Row>

                </Grid>
            </Fragment>
        );
    }

    // #endregion    

    // #region Ciclo di vita del container
    componentDidMount = () => {
        this.performSearch(this.setRichiedentiResults, this.setErrorMessage);
    }
    // #endregion  

    //#region Logica applicativa )con Rendering 
    setRichiedentiResults = (questionari) => {
        this.setState({
            risultatiRicerca: questionari.QuestionariRichiedente,
            paginaCorrente: 1,
            creazioneUtente: false
        });
    }

    setErrorMessage = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage
        });
    }

    performSearch = (successFn, errorFn) => {

        const IdRichiedente = this.props.location.state.utente.id;
        const dataDa = this.state.dallaData;
        const dataA = this.state.allaData;
        const codicePseudonomizzato = this.state.codiceQuestionario;

        const data = {
            token: this.props.loginToken,
            method: "getquestionariisarichiedente",
            param: {
                anagrafica_richiedenti: IdRichiedente,
                dataDa: dataDa,
                dataA: dataA,
                codicePseudonomizzato: codicePseudonomizzato
            }
        };

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const questionari = JSON.parse(response.data.data);
                    if (globals.debug < 3) console.log(questionari);
                    successFn(questionari);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    errorFn(response.data.result.message);
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata questionario dell'utente error: " + error);
                errorFn(error.message);
            });

        return;
    }


    renderRisultatiRicerca = () => {

        const paginaAttuale = this.state.risultatiRicerca.filter((obj, i) => {
            return (i >= (this.state.paginaCorrente - 1) * this.state.risultatiPerPagina &&
                i < this.state.paginaCorrente * this.state.risultatiPerPagina);
        });

        const risultati = paginaAttuale.map((obj, i) => {
            let dataCompilazione = (obj.dataCompilazione === null ? "" : moment(obj.dataCompilazione, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY"));

            const handlerClickVaiAQuestionario = () => {
                this.vaiAQuestionarioClickedHandler(obj);
            };
            if (obj.dataValidazione) {
                return (
                    <tr key={obj.id}>
                        <td className="text-center"><i style={{ cursor: "pointer", color: "black", fontSize: "xx-large" }} onClick={handlerClickVaiAQuestionario} className="fa fa-eye"></i></td>
                        <td>{obj.codicePseudonomizzato}</td>
                        <td className="text-right">{dataCompilazione}</td>
                        <td className="text-right">{obj.punteggio}</td>
                    </tr>
                );
            } else {
                return (
                    <tr key={obj.id}>
                        <td>{obj.id}</td>
                        <td>{obj.codicePseudonomizzato}</td>
                        <td className="text-right">{dataCompilazione}</td>
                        <td className="text-right">{obj.punteggio}</td>
                    </tr>
                );
            }
        });

        return risultati;
    }
}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};
// #endregion

export default connect(mapStateToProps)(RicercaQuestionariISA);