import React, { Component } from 'react';
import axios from 'axios';
import * as globals from '../comuni/constants';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import Spinner from '../components/Spinner';

//import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

// Props:
// - livello: int oppure non definito
// - idMenu: int
// - voci null/non definito se sono la radice oppure []
// - tipoPadre null/non definito per il default (sezione) oppure sezione,area,categoria,pagina,sottopagina
// - menuClickedHandler è la funziona da chiamare quando qualcuno clicca una voce di menu

/*
  definizione oggetto voci
  [{
      id: int
      descrizione: stringa
      ordinale: int
      idpadre: int o null
      tooltip: stringa
      badge: stringa
      icon: stringa (url se contiene :// oppure font awesome se non contiene ://)
      target: stringa
      link: stringa
      voci: null se sono il primo, altrimenti []
  }]
*/

class MenuItem extends Component {

    state = {
        voci: null, // voci di menu, null oppure oggetto js
    };

    render() {

        if (this.state.voci === null) {
            return <Spinner />;
        }

        const currentMenu = this.state.voci.find((obj) => {
            return obj.id === this.props.idMenu;
        });

        let isSelected = false;
        if (currentMenu) {
            let selMenu = this.state.voci.find((obj) => {
                if (obj.id === currentMenu.id && document.location.href.endsWith(obj.link)) {
                    isSelected = true;
                }
                return document.location.href.endsWith(obj.link);
            });
            while (selMenu && isSelected === false) {
                // eslint-disable-next-line no-loop-func
                selMenu = this.state.voci.find((obj) => {
                    if (obj.id === currentMenu.id && obj.id === selMenu.idpadre) {
                        isSelected = true;
                    }
                    return obj.id === selMenu.idpadre;
                });
            }
        }

        const sottoMenu = this.state.voci.filter((obj) => {
            return obj.idpadre === this.props.idMenu;
        }).sort((a, b) => {
            return a.ordinale - b.ordinale;
        }).map((obj) => {
            return <MenuItem voci={this.state.voci} menuClickedHandler={this.props.menuClickedHandler} key={"menu_" + obj.id.toString()} idMenu={obj.id}></MenuItem>
        });

        const currentMenuEmpty = typeof currentMenu === "undefined" || currentMenu === null;

        if (currentMenuEmpty === true && sottoMenu.length === 0) {
            // Sono un menu invisibile e non ho sotto menu
            return null;
        } else if (currentMenuEmpty === true && sottoMenu.length > 0) {
            // Sono un menu invisibile con sotto menu
            return <div>{sottoMenu}</div>;
        } else {
            // Sono un menu VISIBILE con o senza sotto menu
            let link = <NavLink to={currentMenu.link}>{currentMenu.descrizione}</NavLink>
            if (!currentMenu.link.startsWith("/")) {
                link = <a href={currentMenu.link}>{currentMenu.descrizione}</a>
            }

            const selectedMenu = (document.location.href.endsWith(currentMenu.link));
            const menuBackground = (selectedMenu ? "lightblue" : (isSelected ? "azure" : "transparent"));

            return (
                <ul id={"nav-main" + currentMenu.id} className="nav navbar-nav navmenu">
                    <li className="area" id={"Area_" + currentMenu.id}>
                        <span style={{ backgroundColor: menuBackground, cursor: "pointer" }} className="spanArea" onClick={this.props.menuClickedHandler}>{link}</span>
                        {sottoMenu}
                    </li>
                </ul>
            );
        }
    }

    componentDidMount = () => {
        if (this.state.voci === null) {
            if (typeof this.props.voci === "undefined" || this.props.voci === null) {
                this.performSearchMenu();
            } else {
                this.setState({
                    voci: this.props.voci,
                });
            }
        }
    }


    performSearchMenu = () => {

        const data = {
            token: this.props.loginToken,
            method: "getvocimenu",
            param: {
                idpadre: this.props.idMenu,
                tipopadre: (typeof this.props.tipoPadre === "undefined" || this.props.tipoPadre === null) ? "sezione" : this.props.tipoPadre,
            }
        };

        const successFn = (vocimenu) => {
            this.setState({
                voci: vocimenu
            });
        }

        const errorFn = (errorMessage) => {
            this.setState({
                messaggioErrore: errorMessage
            });
        }

        axios.post(globals.wsURLPDIBase, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const vociMenu = JSON.parse(response.data.data);
                    if (globals.debug < 3) console.log(vociMenu);
                    successFn(vociMenu);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    errorFn(response.data.result.message);
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata voci di menu: " + error);
                errorFn(error.message);
            });

        return;
    }


}

/// Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

// #endregion
export default withRouter(connect(mapStateToProps)(MenuItem));
