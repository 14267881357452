import React from 'react';

// getRisposteDomandaCorrente = (domandaCorrente) => {

// }

const ModaleStampaQuestionario = (props) => {
    // const dataHef ="data:application/pdf;base64,";
    
   
    let dataHef = null;
    dataHef = "data:application/pdf;base64,";
    dataHef = dataHef + props.filePdf ;

    let title="Download pdf " + props.tipoQuestionario;

    return (
    //   
    
    <div id="messaggioVisibileModal" className="modal fade in" style={{display: "block"}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                         
                            <h4 className="modal-title">Creazione del file pdf visto a schermo avvenuta con successo.</h4>
                            {/*    <h4 className="modal-title"></h4> */}
                            {/* <h5>  <span  className="clickable" onClick={this.mostraDiMeno} >{props.pathfilesPdf}</span> &nbsp;</h5> */}  
                            <h5 className="modal-title"> &nbsp;</h5>
                          {/*   <span className="spanArea"><a download={props.nomeFilesPdf}  href={dataHef} title="Download pdf della compilazione">Download pdf della compilazione</a></span>
                            
  
  <h4 className="modal-title">Il file si trova nel seguente percorso </h4>
                            <h5 className="modal-title">'{props.pathfilesPdf}'.</h5> */}
                       
                        </div>
                      {/*   <div className="modal-body row">
                            {props.codiceUtente}
                        </div> */}
                        <div className="modal-footer row">
                        <a className="btn btn-default" download={props.nomeFilesPdf}  href={dataHef} title={title}>Download pdf {props.tipoQuestionario}</a>

                        
                           <button onClick={props.chiudiModaleClicked} type="button" className="btn btn-default" data-dismiss="modal">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>);
}

export default ModaleStampaQuestionario;