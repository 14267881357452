
import React, { Component, Fragment } from 'react';
import { Table, Panel, Button, Grid, Row, Col, FormGroup, Well } from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import ModaleStampaQuestionario from '../components/ModaleStampaQuestionario';
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoDefinizioneQuestionarioASSO from '../components/ModaleErroreScaricamentoDefinizioneQuestionarioASSO';
import axios from 'axios';
import moment from 'moment-timezone';
import '../css/tooltip.css';
import './VisualizzaQuestionarioAsso.css';
import ModaleErroreScaricamentoInformazioniQuestionarioISA from '../components/ModaleErroreScaricamentoInformazioniQuestionarioISA';
import ModaleErroreStampaPdf from '../components/ModaleErroreStampaPdf';
import ModaleErroreCreazioneRecuperoQuestionario from '../components/ModaleErroreCreazioneRecuperoQuestionario';
import * as globals from '../comuni/constants';


class VisualizzaQuestionarioASSO extends Component {

    state = {
        apriModaleStampa: false,
        domandaCorrente: 0,
        questionario: null,
        redirectToHome: false,
        questionarioISATrovato: null,
        messaggioErroreQuestionarioIsaCercato: null,
        messaggioErroreStampaPdf: null,
        visualizzaModaleErroreStampaPdf: false,
        base64PdfQuestionario: null,
        richiestaCreazionePdf: false,
        nomeFilesStampaPdf: null,
        questionarioISATrovatoCreato: null,
        messaggioErroreCreazioneQuestionario: null,
        visualizzaModaleErroreCreazioneQuestionario: false,
    };

    // #region Eventi
    chiudiModaleStampaCliccato = () => {
        this.setState({
            apriModaleStampa: false,
            redirectToHome: false,
        });
    }

    chiudiModaleErroreCreazioneQuestionarioCliccato = () => {
        this.setState({
            visualizzaModaleErroreCreazioneQuestionario: false,
        });
    }

    stampaCliccato = () => {

        let cssHtml = "<!DOCTYPE html>  " +
            " <html lang=\"en\" style=\"font-size: 1.2em\"> " +
            " <head> " +
            "    <meta charset=\"utf-8\">" +
            "   <meta name=\"viewport\" content=\"width=device-width, initial-scale=1, shrink-to-fit=no\"> " +
            " <meta name=\"theme-color\" content=\"#000000\">" +
            "<link" +
            "   href=\"https://fonts.googleapis.com/css?family=Titillium+Web:400,200,200italic,300italic,300,400italic,600,600italic,700,700italic,900\" " +
            "  rel=\"stylesheet\" type=\"text/css\">" +
            "<link rel=\"stylesheet\" title=\"Carattere normale\" type=\"text/css\" media=\"screen\"" +
            "    href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/struttura.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/bootstrap-3.3.7.min.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/bootstrap-3.3.7-theme.min.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/all.css\">" +
            "<link rel=\"stylesheet\" type=\"text/css\" media=\"screen\"" +
            "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/ita-bootstrap.css\">" +
            "<link rel=\"stylesheet\" title=\"Carattere normale\" type=\"text/css\" media=\"screen\"" +
            "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/0-template-agid_modello_11947_16589.css\">" +
            "<link rel=\"alternate stylesheet\" title=\"Carattere grande\" type=\"text/css\" media=\"screen\"" +
            "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/caratteregrande.css\" disabled=\"\">" +
            "<link rel=\"alternate stylesheet\" title=\"Contrasto elevato\" type=\"text/css\" media=\"screen\"" +
            "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/fortecontrasto.css\" disabled=\"\">" +
            "<link rel=\"alternate stylesheet\" title=\"Senza grafica\" type=\"text/css\" media=\"screen\"" +
            "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/senzagrafica.css\" disabled=\"\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/nyroModal.css\" type=\"text/css\"" +
            "   media=\"screen\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/tipsy.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/jquery.tagit.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/tagit.ui-zendesk.css\">" +
            "<link rel=\"stylesheet\" type=\"text/css\" media=\"print\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/stampa.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale +  "/css/VisualizzaQuestionarioAsso.css\">" +

            "<title>Demo ASSO-ISA</title>" +
            "</head>" +
            "<body><div class=\"container-fluid\" style=\"font-size:16px!important\">";

        let pdfInHtmp = document.getElementById("toPdf").innerHTML;

        let fineHtml = "</div></body> </html>"

        this.setState({
            richiestaCreazionePdf: true,
            base64PdfQuestionario: null,
            messaggioErroreStampaPdf: null,
            visualizzaModaleErroreStampaPdf: false,
            nomeFilesStampaPdf: null
        });

        this.creaStampaPdf(cssHtml + pdfInHtmp + fineHtml, this.props.location.state.questionario.codicePseudonomizzato);
    }

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }
    // #endregion

    // #region funzioni helper per il render
    getIndiceQuestionario = () => {
        let tabella = null;

        tabella = this.props.questionarioASSO.domande.map((obj, i) => {

            const risposta = obj.risposte.find((valore, indice) => {
                return valore.numero === this.state.questionario.risposte[i];
            });

            if (obj.numDomanda === "1.2" && this.state.questionario.risposte[0] === 7) {
                return null;
            }

            const descrizioneRisposta = risposta ? risposta.descrizione : "domanda non ancora risposta";
            const numDomanda = obj.numDomanda;
            const descrizioneDomanda = obj.descrizione;

            return (
                <tr key={i}>
                    <td>
                        <div>
                            <h5>{numDomanda}.  {descrizioneDomanda} - <strong>{descrizioneRisposta}</strong></h5>
                        </div>
                    </td>
                </tr>
            );

        });

        return (
            <Table striped bordered condensed hover>
                <tbody>
                    {tabella}
                </tbody>
            </Table>
        );
    }


    getNoteAdvice = () => {
        let note = null;

        note = this.props.location.state.questionario.noteAdvise

        if (note === null ) {
            return null;
        }

        if (note.trim() === "" ) {
            return null;
        }
      

        return (
            <Table striped bordered condensed hover>
                <tbody>
                <tr key={0}>
                    <td>
                        <div>
                            <h5>  <strong>Note relative agli advise</strong></h5>
                        </div>
                    </td>
                </tr>
                
                <tr key={1}>
                    <td>
                        <div>
                            <h5> {note} </h5>
                        </div>
                    </td>
                </tr>
                    
                </tbody>
            </Table>
        );
    }


    getHeaderSezione(nomeSezione) {
        switch (nomeSezione.toLowerCase()) {
            case "c":
                return "Quadro per l'inclusione sociale";

            case "d1":
                return "Saperi e capacità comunicative";

            case "d2":
                return "Note: istruzione";

            case "e":
                return "Capacità informatiche";

            case "f":
                return "Seconda lingua";

            case "g":
                return "Autonomie";

            case "h":
                return "Adattabilità al cambiamento";

            case "i1":
                return "Risorse materiali e sociali";

            case "i2":
                return "Note: situazione economica";

            case "j":
                return "Ambiente famigliare e svantaggio";

            case "k":
                return "Attività di volontariato";

            case "l":
                return "Vincoli famigliari e compatibilità oraria";

            case "n":
                return "Vincoli di salute";

            case "m":
                return "Cura di sé";

            default:
                return "Intestazione non trovata"
        }
    }

    getMessaggi = (elencoMessaggi) => {

        let messaggi = null;

        messaggi = elencoMessaggi.map((obj, i) => {
            return (<React.Fragment key={obj.id}>
                {obj.testo}
            </React.Fragment>);
        });

        if (elencoMessaggi.length === 0)
            return null
        else
            return messaggi;
    }


    getSezione = (nomeSezione) => {
        const idControllo = "Sezione" + nomeSezione.toUpperCase();
        const headerSezione = this.getHeaderSezione(nomeSezione);

        if (this.state.questionario === null) {
            return (<FormGroup controlId={idControllo}>
                <Row className="container-fluid" style={{ textAlign: "left" }} >{headerSezione}</Row>
                <Panel><Panel.Body><Spinner /></Panel.Body></Panel>
            </FormGroup>);
        }

        const messaggioSezione = this.state.questionario.testi.filter((obj, i) => {
            return obj.sezione === nomeSezione;
        });


        if (!messaggioSezione) {
            return null;
        }

        const messaggioSezioneRenderizzato = this.getMessaggi(messaggioSezione);

        if (messaggioSezioneRenderizzato === null) {
            return null;
        }

        return (<FormGroup controlId={idControllo}>
             <h3 style={{ textAlign: "left" }} >
            {headerSezione}</h3>
            <Panel><Panel.Body>
                {messaggioSezioneRenderizzato}
            </Panel.Body></Panel>
        </FormGroup>);
    }

    getSezioneD1D2 = () => {

        const nomeSezione = "d1";
        const idControllo = "Sezione" + nomeSezione.toUpperCase();
        const headerSezione = this.getHeaderSezione(nomeSezione);

        if (this.state.questionario === null) {
            return (<FormGroup controlId={idControllo}>
                 <h3 style={{ textAlign: "left" }} >{headerSezione}</h3>
                <Panel><Panel.Body><Spinner /></Panel.Body></Panel>
            </FormGroup>);
        }

        const messaggioSezione = this.state.questionario.testi.filter((obj, i) => {
            return obj.sezione === nomeSezione;
        });
        //new
        const messaggioSezioneRenderizzato = this.getMessaggi(messaggioSezione);

        const noteMessaggioSezione = this.state.questionario.testi.filter((obj, i) => {
            return obj.sezione === "d2";
        });

        //new
        const noteMessaggioSezioneRenderizzato = this.getMessaggi(noteMessaggioSezione, true);

        if ((typeof messaggioSezione === "undefined" || messaggioSezione === null) &&
            (typeof noteMessaggioSezione === "undefined" || noteMessaggioSezione === null)) {
            return null;
        }

        return (

            <FormGroup controlId={idControllo}>
                <h3 style={{ textAlign: "left" }} >{headerSezione}</h3>
                <Panel><Panel.Body>
                    {messaggioSezioneRenderizzato}
                {noteMessaggioSezioneRenderizzato === null ? null :
                 <React.Fragment><br></br><br></br>
                        {noteMessaggioSezioneRenderizzato}</React.Fragment>
                }
                </Panel.Body></Panel>
            </FormGroup>

        );
    }

    getSezioneLM = () => {
        const nomeSezione = "l";
        const idControllo = "Sezione" + nomeSezione.toUpperCase();
        const headerSezione = this.getHeaderSezione(nomeSezione);

        if (this.state.questionario === null) {
            return (<FormGroup controlId={idControllo}>
                <h3 style={{ textAlign: "left" }} >{headerSezione}</h3>
                <Panel><Panel.Body><Spinner /></Panel.Body></Panel>
            </FormGroup>);
        }

        const messaggioSezione = this.state.questionario.testi.filter((obj, i) => {
            return obj.sezione === nomeSezione;
        });


        const noteMessaggioSezione = this.state.questionario.testi.filter((obj, i) => {
            return obj.sezione === "m";
        });

        if ((typeof messaggioSezione === "undefined" || messaggioSezione === null) &&
            (typeof noteMessaggioSezione === "undefined" || noteMessaggioSezione === null)) {
            return null;
        }

        const messaggioSezioneRenderizzato = this.getMessaggi(messaggioSezione);

        const noteMessaggioSezioneRenderizzato = this.getMessaggi(noteMessaggioSezione);

        let intestazioneMessaggioSezioneRenderizzato = null;
        if (noteMessaggioSezioneRenderizzato) {
            intestazioneMessaggioSezioneRenderizzato = <React.Fragment><br /><br />Eccezione:<br /><br /></React.Fragment>;
        }

        return (
            <FormGroup controlId={idControllo}>
                <h3 style={{ textAlign: "left" }} >{headerSezione}</h3>
                <Panel><Panel.Body>
                    {messaggioSezioneRenderizzato}
                
                {intestazioneMessaggioSezioneRenderizzato === null ? null :
                    <React.Fragment><br />
                        {intestazioneMessaggioSezioneRenderizzato}
                        {noteMessaggioSezioneRenderizzato}</React.Fragment>
                }
                    </Panel.Body></Panel>
            </FormGroup>
        );
    }

    getSezioneGH = () => {

        const nomeSezione = "g";
        const idControllo = "Sezione" + nomeSezione.toUpperCase();
        const headerSezione = "Competenze per il lavoro";

        if (this.state.questionario === null) {
            return (<FormGroup controlId={idControllo}>
                <h3 style={{ textAlign: "left" }} >{headerSezione}</h3>
                <Panel><Panel.Body><Spinner /></Panel.Body></Panel>
            </FormGroup>);
        }

        const messaggioSezione = this.state.questionario.testi.filter((obj, i) => {
            return obj.sezione === nomeSezione;
        });

        let messaggioSezioneRenderizzato = this.getMessaggi(messaggioSezione);

        const noteMessaggioSezione = this.state.questionario.testi.filter((obj, i) => {
            return obj.sezione === "h";
        });

        let noteMessaggioSezioneRenderizzato = this.getMessaggi(noteMessaggioSezione);


        const note2MessaggioSezione = this.state.questionario.testi.filter((obj, i) => {
            return obj.sezione === "g1";
        });

        let note2MessaggioSezioneRenderizzato = this.getMessaggi(note2MessaggioSezione);


        if ((typeof messaggioSezione === "undefined" || messaggioSezione === null) &&
            (typeof noteMessaggioSezione === "undefined" || noteMessaggioSezione === null) &&
            (typeof note2MessaggioSezione === "undefined" || note2MessaggioSezione === null)) {
            return null;
        }

        // Nota di Max: Le sezioni "h" e "g" sono come richieste dal cliente.
        if (typeof messaggioSezione !== "undefined"
            && messaggioSezione !== null
            && messaggioSezioneRenderizzato) {
            let messaggioNew = <Fragment><br /><br />{this.getHeaderSezione("h")}<br /><br />{messaggioSezioneRenderizzato}</Fragment>;
            messaggioSezioneRenderizzato = messaggioNew
        }

        if (typeof noteMessaggioSezione !== "undefined" && noteMessaggioSezione !== null
            && noteMessaggioSezioneRenderizzato
        ) {
            let messaggioNew = <Fragment>{this.getHeaderSezione("g")}<br /><br />{noteMessaggioSezioneRenderizzato}</Fragment>;
            noteMessaggioSezioneRenderizzato = messaggioNew
        }

        if (typeof note2MessaggioSezione !== "undefined"
            && note2MessaggioSezione !== null
            && note2MessaggioSezioneRenderizzato) {
            let messaggioNew = <Fragment><br /><br />Nota:<br /><br />{note2MessaggioSezioneRenderizzato}</Fragment>;
            note2MessaggioSezioneRenderizzato = messaggioNew
        }

        return (
            <FormGroup controlId={idControllo}>
                <h3 style={{ textAlign: "left" }} >{headerSezione}</h3>
                <Panel><Panel.Body>
                    {noteMessaggioSezioneRenderizzato}
                    {messaggioSezioneRenderizzato}
                    {note2MessaggioSezioneRenderizzato}
                </Panel.Body></Panel>
            </FormGroup>
        );
    }

    getSezioneI1I2 = () => {

        const nomeSezione = "i1";
        const idControllo = "Sezione" + nomeSezione.toUpperCase();
        const headerSezione = this.getHeaderSezione(nomeSezione);

        if (this.state.questionario === null) {
            return (<FormGroup controlId={idControllo}>
                <h3 style={{ textAlign: "left" }} >{headerSezione}</h3>
                <Panel><Panel.Body><Spinner /></Panel.Body></Panel>
            </FormGroup>);
        }

        const messaggioSezione = this.state.questionario.testi.filter((obj, i) => {
            return obj.sezione === nomeSezione;
        });

        const noteMessaggioSezione = this.state.questionario.testi.filter((obj, i) => {
            return obj.sezione === "i2";
        });

        if ((typeof messaggioSezione === "undefined" || messaggioSezione === null) &&
            (typeof noteMessaggioSezione === "undefined" || noteMessaggioSezione === null)) {
            return null;
        }

        const messaggioSezioneRenderizzato = this.getMessaggi(messaggioSezione);

        const noteMessaggioSezioneRenderizzato = this.getMessaggi(noteMessaggioSezione);
        let intestazioneNoteMessaggioSezioneRenderizzato;
        let eccezioneJRenderizzato = "";
        let eccezioneKRenderizzato = "";

        let messaggioNew = "";
        if (typeof messaggioSezione !== "undefined" && messaggioSezione !== null
            && messaggioSezioneRenderizzato) {
            const eccezioneJ = this.state.questionario.testi.filter((obj, i) => {
                return obj.sezione === "j";
            });

            const eccezioneJRenderizzato2 = this.getMessaggi(eccezioneJ);

            if (typeof eccezioneJ !== "undefined" && eccezioneJ !== null && eccezioneJRenderizzato2) {
                messaggioNew = <Fragment><br /><br />Eccezione:<br /><br />{eccezioneJRenderizzato2}</Fragment>;
                eccezioneJRenderizzato = messaggioNew;
            }

            const eccezioneK = this.state.questionario.testi.filter((obj, i) => {
                return obj.sezione === "k";
            });

            const eccezioneKRenderizzato2 = this.getMessaggi(eccezioneK);

            if (typeof eccezioneK !== "undefined" && eccezioneK !== null && eccezioneKRenderizzato2) {
                messaggioNew = <Fragment><br /><br />Eccezione:<br /><br />{eccezioneKRenderizzato2}</Fragment>;
                eccezioneKRenderizzato = messaggioNew;

            }
        }

        if (typeof noteMessaggioSezione !== "undefined" &&
            noteMessaggioSezione !== null &&
            noteMessaggioSezioneRenderizzato) {

            intestazioneNoteMessaggioSezioneRenderizzato = <Fragment><br /><br />Nota:<br /><br /></Fragment>;
        }
        else {
            intestazioneNoteMessaggioSezioneRenderizzato = null;
        }

        return (
            <FormGroup controlId={idControllo}>
                <h3 style={{ textAlign: "left" }} >{headerSezione}</h3>
                <Panel><Panel.Body>
                    {messaggioSezioneRenderizzato}
                    {eccezioneJRenderizzato}
                    {eccezioneKRenderizzato}
                    {intestazioneNoteMessaggioSezioneRenderizzato}
                    {noteMessaggioSezioneRenderizzato}
                </Panel.Body></Panel>
            </FormGroup>
        );
    }

    popolaModale = () => {
        let modale = null;

        if (this.state.visualizzaModaleErroreCreazioneQuestionario === true) {
            return <ModaleErroreCreazioneRecuperoQuestionario
                chiudiModaleClicked={this.chiudiModaleCliccato}
                messaggioErrore={this.state.messaggioErroreCreazioneQuestionario}
            />;
        }

        if (this.state.apriModaleStampa === true) {
            modale = <ModaleStampaQuestionario
                chiudiModaleClicked={this.chiudiModaleStampaCliccato}
                nomeFilesPdf={this.state.nomeFilesStampaPdf}
                filePdf={this.state.base64PdfQuestionario}
                tipoQuestionario="ASSO"
            />;
        }

        if (this.props.messaggioErroreScaricamentoQuestionarioASSO !== null) {
            return <ModaleErroreScaricamentoDefinizioneQuestionarioASSO
                messaggioErrore={this.props.messaggioErroreScaricamentoQuestionarioASSO}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />;
        }

        if (this.props.visualizzaModaleErroreStampaPdf === true) {
            return <ModaleErroreStampaPdf
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />;
        }

        if (this.props.visualizzaModaleErroreQuestionarioIsaCercato === true) {
            modale = <ModaleErroreScaricamentoInformazioniQuestionarioISA
                messaggioErrore={this.props.messaggioErroreQuestionarioIsaCercato}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />;
        }

        return modale;
    }

    getAnagraficaCompilatore = () => {

        return (
            <React.Fragment>
                <Col xs={12} sm={6}>Compilatore: {this.state.questionario.compilatore.nomecompilatore} {this.state.questionario.compilatore.cognomecompilatore} </Col>
                <Col xs={12} sm={6}>Gruppo:  {this.state.questionario.compilatore.nomeEnte}</Col>
            </React.Fragment>
        );
    }

    getAnagraficaUtente = () => {

        const dataNascitaCorta = moment(this.props.location.state.utente.dataNascita, "YYYY-MM-DDT");
        const NascitaCorta = dataNascitaCorta.format('LL');

        return (
            <React.Fragment>
                <Col xs={12} sm={6}>Nome Cognome Utente: {this.props.location.state.utente.nome} {this.props.location.state.utente.cognome}  </Col>
                <Col xs={12} sm={6}>Comune di Residenza: {this.props.location.state.utente.comuneResidenza}</Col>
                <Col xs={12} sm={6}>Data di nascita: {NascitaCorta}</Col>
                <Col xs={12} sm={6}>Cittadinanza: {this.props.location.state.utente.cittadinanza}</Col>
                <Col xs={12} sm={6}>Genere: {this.props.location.state.utente.sesso === "F" ? "Femmina" : "Maschio"}</Col>
                <Col xs={12} sm={6}>Codice Fiscale: {this.props.location.state.utente.codiceFiscale}</Col>
            </React.Fragment>
        );
    }
    


    disegnaBottoneQuestionarioISA = () => {

        if (this.state.questionarioISATrovato !== null) {
            return (
                <React.Fragment>
                    <Link to={{
                        pathname: "/VisualizzaQuestionarioISA",
                        state: {
                            questionario: this.state.questionarioISATrovato,
                            utente: this.props.location.state.utente
                        }
                    }}>
                        <Button bsStyle="success">Vai a ISA</Button>
                    </Link>
                </React.Fragment>);
        }
        else {
            return (
                <React.Fragment>
                    <Button disabled bsStyle="default">ISA non presente</Button>
                </React.Fragment>);
        }
    }


    disegnaBottoneCreaISA = () => {

        let dataUltimoAsso = null;

        try {
            dataUltimoAsso = moment(this.state.questionario.dataValidazione, "YYYY-MM-DDTHH:mm:ss");
            const ggDaUltimoAsso = moment().diff(dataUltimoAsso, 'days');

            if (ggDaUltimoAsso < 180) {
                return (
                    <Button onClick={() => this.performCreazioneQuestionarioISA()} bsStyle="success">Crea nuovo ISA</Button>);
            } else {
                return (<React.Fragment> 
                    <Button disabled bsStyle="default">ISA non creabile</Button>
                </React.Fragment>);
            }
        } catch (e) {
            return (
                <React.Fragment>Prova
                    <Button disabled bsStyle="default">ISA non creabile</Button>
                </React.Fragment>);
        }
    }


    render() {

        if (this.state.redirectToHome === true) {
            return <Redirect to={{ pathname: "/" }} />
        }

        const modale = this.popolaModale();

        if (this.props.questionarioASSO === null ||
            this.state.questionario === null) {
            return <Spinner />;

        }

        if (modale === null && this.state.questionarioISATrovatoCreato !== null) {
            const questionarioCreato = this.state.questionarioISATrovatoCreato;
            return <Redirect to={{ pathname: "/QuestionarioISA", state: { questionarioCreato: questionarioCreato, utente: this.props.location.state.utente } }} />
        }

        if (this.state.base64PdfQuestionario === null && this.state.richiestaCreazionePdf === true) {
            return <Spinner />;
        }

        return (
            <Fragment>
                <Well>
                    <Link to="/">Home</Link> / <Link to="/RicercaUtentiPerASSO">Ricerca ASSO</Link> / <Link to={{ pathname: "/RicercaQuestionariASSO", state: { utente: this.props.location.state.utente } }}>Seleziona ASSO</Link> / Visualizza ASSO
                </Well>
                <Grid fluid={true}>
                    <Row>
                        <Col xs={4} md={2}>
                            <Button onClick={this.stampaCliccato} bsStyle="success">Salva copia</Button>
                        </Col>
                        <Col className="text-right" xs={4} md={4}>
                            {this.disegnaBottoneQuestionarioISA()}
                        </Col>
                        <Col className="text-right" xs={4} md={3}>
                            {this.disegnaBottoneCreaISA()}
                        </Col>
                    </Row>
                    <Row>
                         <Col xs={12}>
                         <h4 style={{ textAlign: "left" }} >Visualizza ASSO per utente <span style={{ fontWeight: "normal" }}> &nbsp;-&nbsp;
                                {this.props.location.state.utente.nome} {this.props.location.state.utente.cognome}, C.F.:&nbsp;{this.props.location.state.utente.codiceFiscale}
                            </span></h4>
                        </Col>
                    </Row>
                    <div id="toPdf">
                        <Row>
                            <h3>Dati di compilazione </h3>
                        </Row>
                        <Row>
                            <Panel>
                                <Panel.Body>
                                    {this.getAnagraficaUtente()}
                                </Panel.Body>
                            </Panel>
                        </Row>
                        <Row>
                            <Panel>
                                <Panel.Body>
                                    {this.getAnagraficaCompilatore()}
                                </Panel.Body>
                            </Panel>
                        </Row>
                        <div className="page_break">  &nbsp; </div>
                        <div className="page_break">  &nbsp; </div>
                        <Row>
                            <h5 style={{ textAlign: "center" }} >Codice {this.props.location.state.questionario.codicePseudonomizzato} </h5>
                        </Row>
                        <Row>
                            <h3 style={{ textAlign: "left" }} >A.S.S.O.  "Attivazione Sociale Sostenibile Occupabilità" </h3>
                        </Row>
                        <Row>

                            {this.getIndiceQuestionario()}
                        </Row>
                        <Row>
                            {this.getNoteAdvice()}
                        </Row>
                        <Row>
                            {this.getSezione("c")}
                        </Row>
                        <Row>
                            {this.getSezioneD1D2()}
                        </Row>
                        <Row>
                            {this.getSezione("e")}
                        </Row>
                        <Row>
                            {this.getSezione("f")}
                        </Row>
                        <Row>
                            {this.getSezioneGH()}
                        </Row>
                        <Row>
                            {this.getSezioneI1I2()}
                        </Row>
                        <Row>
                            {this.getSezioneLM()}
                        </Row>
                        <Row>
                            {this.getSezione("n")}
                        </Row>
                    </div>
                    {modale}
                </Grid>
            </Fragment>
        );
    }

    // #region ciclo di vita del container
    componentDidMount = () => {

        if (this.props.questionarioASSO === null) {
            this.props.scaricaQuestionarioASSO(this.props.loginToken);
        }

        this.scaricaQuestionario();

        this.scaricaQuestionarioISAByIdRichiedente();
    }

    scaricaQuestionario = () => {

        const data = {
            token: this.props.loginToken,
            method: "getquestionariobyid",
            param: this.props.location.state.questionario.id
        };

        const setData = (questionario) => {
            this.setState({
                questionario: questionario,
            });
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    if (globals.debug < 3) console.log(JSON.parse(response.data.data));
                    setData(JSON.parse(response.data.data));

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata salva validazione ASSO error: " + error);
            });

    }
    // #endregion


    mostraQuestionarioISACercato = (questionarioTrovato) => {
        this.setState({
            questionarioISATrovato: questionarioTrovato,
            messaggioErroreQuestionarioIsaCercato: null,
            visualizzaModaleErroreQuestionarioIsaCercato: false
        });
    }

    mostraModaleErroreQuestionarioIsaCercato = (messaggioErrore) => {
        this.setState({
            visualizzaModaleErroreQuestionarioIsaCercato: true,
            messaggioErroreQuestionarioIsaCercato: messaggioErrore
        });
    }

    scaricaQuestionarioISAByIdRichiedente = () => {
        const successFn = this.mostraQuestionarioISACercato;
        const errorFn = this.mostraModaleErroreQuestionarioIsaCercato;

        const idAnagrafica = this.props.location.state.questionario.anagrafica_richiedenti;

        const data = {
            token: this.props.loginToken,
            method: "getquestionarioISAbyIdAnagrafica",
            // param: this.props.location.state.questionario.id
            param: {
                anagrafica_richiedenti: idAnagrafica,

            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    if (globals.debug < 3) console.log(JSON.parse(response.data.data));
                    const recordTrovato = JSON.parse(response.data.data);
                    successFn(recordTrovato);

                } else {
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                if (globals.debug < 3) console.log("chiamata ricerca ISA questionario error: " + error);
                errorFn(error.message);
            });
    }

    mostraStampaPdf = (datiPdfCreato) => {


        this.setState({
            apriModaleStampa: true,
            messaggioErroreStampaPdf: null,
            visualizzaModaleErroreStampaPdf: false,
            base64PdfQuestionario: datiPdfCreato.base64Pdf,
            richiestaCreazionePdf: false,
            nomeFilesStampaPdf: datiPdfCreato.nomeFilesPdf,
        });

    }

    mostraModaleErroreStampaPdf = (messaggioErrore) => {
        this.setState({
            visualizzaModaleErroreStampaPdf: true,
            messaggioErroreStampaPdf: messaggioErrore,
            apriModaleStampa: false,
            base64PdfQuestionario: null,
            richiestaCreazionePdf: false,
            nomeFilesStampaPdf: null,
        });

    }


    creaStampaPdf = (pdfDaHtmpl, codicePseudonomizzato) => {
        const successFn = this.mostraStampaPdf;
        const errorFn = this.mostraModaleErroreStampaPdf;

        const data = {
            token: this.props.loginToken,
            method: "getpdfasso",
            param: {
                stringaHtml: pdfDaHtmpl,
                codicePseudonomizzato: codicePseudonomizzato,
                tipoQuestionarioAsso: true

            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    if (globals.debug < 3) console.log(JSON.parse(response.data.data));
                    const datiPdfCreato = JSON.parse(response.data.data);
                    successFn(datiPdfCreato);

                } else {
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                if (globals.debug < 3) console.log("chiamata creazione pdf Asso compilazione error: " + error);
                errorFn(error.message);
            });
    }


    // #region Creazione ISA
    mostraQuestionarioISACreato = (questionarioCreato) => {
        this.setState({
            questionarioISATrovatoCreato: questionarioCreato,
            visualizzaModaleErroreCreazioneQuestionario: false,
            messaggioErroreCreazioneQuestionario: null,
        });

    }

    mostraModaleErroreCreazioneQuestionario = (messaggioErrore) => {
        this.setState({
            questionarioISATrovatoCreato: null,
            visualizzaModaleErroreCreazioneQuestionario: true,
            messaggioErroreCreazioneQuestionario: messaggioErrore
        });

    }

    performCreazioneQuestionarioISA = () => {

        const successFn = this.mostraQuestionarioISACreato;
        const errorFn = this.mostraModaleErroreCreazioneQuestionario;
        const idAnagraficaRichiedente = this.props.location.state.utente.id;

        const data = {
            token: this.props.loginToken,
            method: "InsertQuestionarioISA",
            param: {
                anagrafica_richiedenti: idAnagraficaRichiedente,
            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const nuovoRecord = JSON.parse(response.data.data);
                    successFn(nuovoRecord);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata creazione questionario error: " + error);
                errorFn(error.message);
            });

    };
    // #endregion Creazione ISA

}


// #region Redux
const mapStateToProps = state => {
    return {
        cacheMessaggi: state.cacheMessaggiASSO,
        questionarioASSO: state.questionarioASSO,
        messaggioErroreScaricamentoQuestionarioASSO: state.messaggioErroreASSO,
        loginToken: state.loginToken,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        scaricaQuestionarioASSO: (token) => dispatch(actions.scaricaQuestionarioASSO(token)),
        scaricaMessaggiSezione: (idMessaggi, cacheMessaggi) => dispatch(actions.scaricaMessaggiSezione(idMessaggi, cacheMessaggi)),
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};
// #endregion


export default connect(mapStateToProps, mapDispatchToProps)(VisualizzaQuestionarioASSO);
