import React from 'react';

function getTarget(url, target) {
    if (url == null) return;
    url.target = (target == null || target === '') ? '_self' : target;

}

const HeaderPortale = (props) => {
    const menuClicked = () => {
        Array.prototype.slice.call(document.getElementsByClassName("bm-burger-button")[0].children).filter((item) => { return item.localName === "button"})[0].click();
    }

    return (
    <header id="HeaderContainer" className="header_container">
        <div id="formid_intestazione1" className="header-top clearfix">
            <div className="container">
                <div className="row">

                    <div className="col-sm-7 col-xs-8 ente-appartenenza">
                        <a href="http://www.serviziosocialecomunipn.it/" title="Ambito Territoriale Noncello - Questo link si aprirà in una nuova finestra" onClick={getTarget(this, '_blank')}>
                            <img className="logo" src={process.env.PUBLIC_URL + "/img/logo/logo-ente-appartenenza.png"} alt="Ambito Territoriale Noncello" />
                            <span className="nome">Ambito Territoriale Noncello</span>
                        </a>
                    </div>

                    <div className="col-sm-5 col-xs-4 accesso-servizi">
                        <span style={{cursor: "pointer"}} title="Accedi" onClick={props.accediClicked}>
                            <span className="glyphicon glyphicon-user" aria-hidden="true"></span>
                            <span className="nome">{props.nomeUtente}</span>
                        </span>
                    </div>

                </div>
            </div>
        </div>

        <div id="formid_intestazione2" className="header-middle clearfix">
            <div className="container">
                <div className="row">

                    <div id="Burger1">
                        <div className="burger-wrapper col-xs-2 col-sm-1">
                            <div id="burger-wrapper" style={{cursor: "pointer"}}>
                                <span id="nav-icon" onClick={menuClicked}><span className="fa fa-bars"></span><span className="menu-icon">menu</span></span>
                                <span style={{cursor: "pointer", display: "none"}} id="nav-icon"><span className="fa fa-bars"></span><span className="menu-icon">menu</span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <nav id="menuEcm" className="navbar navbar-default menu-close">
                        <div className="side-menu-container">
                            <ul id="nav-main" className="nav navbar-nav navmenu">
                            </ul>
                        </div>
                    </nav>

                    <div id="Burger2">
                        <span style={{cursor: "pointer"}} id="Burger_icon"><span className="menu-icon" style={{ display: 'none' }}>menu</span>
                        </span>
                    </div>

                    <div className="logo-nome-ente col-md-6 col-sm-7 col-xs-7">
                        <a href="/index.asp" title="Pordenone - Torna alla homepage" onClick={getTarget(this, '_self')}>
                            <span className="logo-wrapper">
                                <img className="logo" src={process.env.PUBLIC_URL + "/img/logo/logo-ente.jpg"} alt="Pordenone" />
                            </span>
                            <span className="nome-wrapper">Orienta Lavoro</span>
                        </a>
                    </div>

                    <div className="header-middle-right col-md-5 col-sm-4 col-xs-3 text-right">
                        <div className="container">
                            <div className="row">
                                <div className="nav-social col-xs-12 hidden-sm hidden-xs">

                                    <ul className="list-inline text-right social">
                                        <li className="small">Seguici su:</li>
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" title="Seguici su Youtube - Questo link si aprirà in una nuova finestra" href="https://www.youtube.com/user/AmbitoPordenone/channels">
                                                <span className="fa fa-youtube" aria-hidden="true"></span>
                                                <span className="hidden">Youtube</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="BtnRicercaMobile hidden-ie6 hidden-ie7 hidden-sm hidden-md hidden-lg col-xs-6">
                                    <button className="pulsanteRicerca pull-right" type="button" data-toggle="collapse" data-target="#ricercaMobile" aria-expanded="false" >
                                        <span className="fa fa-search" aria-hidden="true"></span>
                                        <span className="hidden">Ricerca</span>
                                    </button>
                                </div>
                            </div>
                            {/* <div className="row hidden-xs">
                                <div id="boxRicercaNelSito" className="col-sm-12 text-right form-group">
                                    <form name="fmRicerca" onSubmit={console.log("event")} action="../../servizi/cercanelsito/cerca_fase01.aspx" method="get">
                                        <fieldset>
                                            <input title="Cerca per parola..." type="text" value="Cerca per parola..." readOnly={true} onFocus={console.log("event")} name="cercanelsitoFiltroContenuto" id="cercanelsitoFiltroContenuto_template" className="form-control" />
                                            <button type="submit" className="pulsanteRicerca">
                                                <span className="fa fa-search" aria-hidden="true"></span>
                                                <span className="hidden">Ricerca</span>
                                            </button>
                                        </fieldset>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* <div id="ricercaMobile" className="container hidden-ie6 hidden-ie7 hidden-sm hidden-md hidden-lg collapse">
                        <div className="row">
                            <div id="boxRicercaNelSitoMobile" className="col-xs-12 form-group">
                                <form name="fmRicerca" onSubmit={console.log("event")} action="../../servizi/cercanelsito/cerca_fase01.aspx" method="get">
                                    <fieldset>
                                        <input title="Cerca per parola..." type="text" value="Cerca per parola..." readOnly={true} onFocus={console.log("event")} onBlur={console.log("event")} name="cercanelsitoFiltroContenuto" id="cercanelsitoFiltroContenuto_template_mobile" className="form-control" />
                                        <button type="submit" className="pulsanteRicerca">
                                            <span className="fa fa-search" aria-hidden="true"></span>
                                            <span className="hidden">Ricerca</span>
                                        </button>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>

        <div id="formid_intestazione3" className="header-bottom clearfix">
            <div className="container">
                <div className="row">


                </div>
            </div>
        </div>

    </header>
    );
}

export default HeaderPortale;