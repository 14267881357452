import React from 'react';
import FieldGroup from './FieldGroup';

const ModaleLogin = (props) => {
    return (<div id="messaggioVisibileModal" className="modal fade in" style={{ display: "block" }}>
        <div className="modal-dialog">
            <div className="modal-content">
                <form>
                    <div className="modal-body row">
                        <FieldGroup
                            id="username"
                            type="text"
                            label="Nome utente"
                            placeholder="nome utente"
                        />
                        <FieldGroup
                            id="password"
                            type="password"
                            label="Password"
                            placeholder="password"
                        />
                    </div>
                </form>
                <div className="modal-footer row">
                    <button onClick={props.chiudiModaleClicked} type="button" className="btn btn-md btn-default" data-dismiss="modal">Annulla</button>
                    <button onClick={() => { props.loginClicked(document.getElementById("username").value, document.getElementById("password").value) }} type="button" className="btn btn-md btn-success">Login</button>
                </div>
            </div>
        </div>
    </div>);
}

export default ModaleLogin;