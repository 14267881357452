import React from 'react';

const FooterPortale = (props) => {
    return (                
    <footer className="footer_container" id="FooterContainer">
    {/* <div id="formid_fondo1">
        <div id="barra-feedback">
            <section>
                <h3 className="sr-only">Sezione Valutazione portale</h3>
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-xs-12 text-center font-larger">
                            <a href="/servizi/segnalazioni/segnalazioni_fase03.aspx" title="Valuta questo sito">
                                <span className="fa fa-pencil-square-o" aria-hidden="true"></span>&nbsp;Valuta questo sito
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div> */}
    <div id="formid_fondo2">
        <div className="container">
            <div className="row">
                <div className="logo-nome-ente col-xs-12">
                    <a href="/index.asp" title="Orienta Lavoro - Torna alla homepage">
                        <h3>
                            <span className="logo-wrapper">
                                <img className="logo" src={process.env.PUBLIC_URL + "/img/logo/logo-ente.jpg"} alt="Orienta Lavoro" />
                            </span>
                            <span className="nome-wrapper">Orienta Lavoro</span>
                        </h3>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div id="formid_fondo3">
        <div className="container">
            <div className="recapiti row col-xs-12 col-sm-8">
                <div className="col-xs-12">
                    <span className="h3">Contatti</span>
                    <p>Indirizzo: Corso Vittorio Emanuele II, 64 33170 Pordenone<br />
                    Telefono: 0434 392111<br />
                    P.I.: 00081570939<br />
                    C.F.: 80002150938<br />
                    Posta Elettronica Certificata: <a href="mailto:comune.pordenone@certgov.fvg.it" title="comune.pordenone@certgov.fvg.it - Questo link aprirà il software impostato sul tuo dispositivo per la scrittura delle email">comune.pordenone@certgov.fvg.it</a>
                    <br></br><a target="_blank" rel="noopener noreferrer" href="https://www.comune.pordenone.it/it/info/privacy" title="Privacy">
                    Privacy
                            </a></p>
                </div>
            </div>
            <div className="social row col-xs-12 col-sm-4">
                <div className="nav-social col-xs-12">
                    <span className="h3">Seguici su</span>
                    <ul className="list-inline social-list">
                        <li>
                            <a target="_blank" rel="noopener noreferrer" title="Seguici su Youtube - Questo link si aprirà in una nuova finestra" href="https://www.youtube.com/user/AmbitoPordenone/channels">
                                <span className="fa fa-youtube" aria-hidden="true"></span>
                                <span className="hidden">Youtube</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-xs-12">
                    <span className="h3">Credits</span>
                    <p>Sito web realizzato da <a href="http://www.progettidiimpresa.it" title="Progetti di Impresa S.r.l. - Il sito web si aprirà in una nuova finestra">Progetti di Impresa S.r.l.</a> © 2019</p>
                </div>
            </div>
            <div id="users-device-size">
                <div id="xs" className="visible-xs"></div>
                <div id="sm" className="visible-sm"></div>
                <div id="md" className="visible-md"></div>
                <div id="lg" className="visible-lg"></div>
            </div>
        </div>
    </div>
</footer>);
}

export default FooterPortale;
