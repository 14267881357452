var url = window.location.href;
//console.log(url);
var arr = url.split("/");


export const wsURLCliente = window.location.href.indexOf('localhost:3000') >=0  ? 'http://www.mo24.sassuolo.info/questionariassoisa.ashx' :  (arr[0] + '//' + arr[2] +  '/questionariassoisa.ashx') ;
export const wsURLPDIBase = window.location.href.indexOf('localhost:3000') >=0  ? 'http://www.mo24.sassuolo.info/pdibasehandler.ashx' :  (arr[0] + '//' + arr[2] +  '/pdibasehandler.ashx') ;
export const urlPortale = window.location.href.indexOf('localhost:3000') >=0  ? 'http://www.mo24.sassuolo.info' :  arr[0] + '//' + arr[2];
export const urlSottoPortale = 'asso-isa';  
export const debug = 4; // 4 è modalità silenziosa, 3 = errori, 2 = warning, 1 = info, 0 = verbose 
