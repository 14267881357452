import React, { Component, Fragment } from 'react';
import { ProgressBar, ListGroup, ListGroupItem, Panel, Grid, Row, Col, Well, Checkbox, Button, FormGroup } from 'react-bootstrap';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import Spinner from '../components/Spinner';
import '../css/general.css';
import ModaleErroreGenerica from '../components/ModaleErroreGenerica';
import ModaleErroreScaricamentoDefinizioneQuestionarioISA from '../components/ModaleErroreScaricamentoDefinizioneQuestionarioISA';
import axios from 'axios';
import * as globals from '../comuni/constants';


class QuestionarioISA extends Component {

  state = {

    domandaCorrente: 0,
    risposte: [],
    redirectToHome: false,
    messaggioErroreRecuperoRisposte: null,
    idQuestionarioRisposto: null,
    redirectToVisualizzazioneEValidazione: false,

    checkValutazioneImpatto: null,
    checkTrattamentoDati: null,
    startQuestionario: false,

    //nuove variabili di stato per nuova gestione a livello di dimensione (tutte le 10 domande assieme)
    //con queste  2 impostazioni si stabilisce la pagina  (dimensione) in cui posizionarsi
    //e l'ultima domanda della dimensione a cui si è data la risposta 
    // nrUltimaDomandaDimensioneCorrente serve per fare il controllo sulla compilazione in sequenza
    nrDimensioneCorrente: 0,
    nrUltimaDomandaDimensioneCorrente: 0,

    visualizzaModaleErroreSequenzaCompilazioneRisposte: false,

    //nrUltimaDomandaDimensioneCorrenteAgo: 0,

  };

  // #region modale

  popolaModale = () => {
    let modale = null;

    if (this.props.messaggioErroreScaricamentoQuestionarioISA !== null) {
      modale = <ModaleErroreScaricamentoDefinizioneQuestionarioISA
        messaggioErrore={this.props.messaggioErroreScaricamentoQuestionarioISA}
        chiudiModaleClicked={this.chiudiModaleErroreClicked} />
    }

    if (this.state.visualizzaModaleErroreSequenzaCompilazioneRisposte === true) {
      return <ModaleErroreGenerica
        chiudiModaleClicked={() => { this.setState({ visualizzaModaleErroreSequenzaCompilazioneRisposte: false }) }}
        informazioniErrore="Per poter procedere alla compilazione del questionario è necessario compilare le risposte in sequenza." />

        ;
    }

    if (this.state.messaggioErroreRecuperoRisposte !== null) {
      modale = <ModaleErroreGenerica
        messaggioErrore={this.state.messaggioErroreRecuperoRisposte}
        chiudiModaleClicked={() => { this.setState({ messaggioErroreRecuperoRisposte: null }) }} />
    }

    return modale;
  }

  chiudiModaleErroreClicked = () => {
    this.setState({ redirectToHome: true });
  }


  chiudiModaleErroreSequenzaCompilazioneRisposte = () => {
    this.setState({
      visualizzaModaleErroreSequenzaCompilazioneRisposte: false,
    });
  }


  // #endregion


  rispostaClickedHandler = (Domanda, risposta) => {

    let idQuestionario = this.state.idQuestionarioRisposto;
    let idDomanda = Domanda.id;
    let idRisposta = this.props.questionarioISA.domande[Domanda.ordinale_domanda - 1].risposte[risposta.numero - 1].id;

    //verifica compilazione in sequenza ; 
    //prima parte  test:  si clicca su una domanda successiva l'ultima compilata ma non quella seguente
    //seconda parte del test : si clicca sull'ultima domanda della dimensione ma non siamo sulla penultima(9) <- test adhoc
    // nel caso sia vera una delle 2 condizioni non si procede , si gestisce una modale in maniera standard
    if (
      (Domanda.ordinale_domanda % 10) > this.state.nrUltimaDomandaDimensioneCorrente + 1
      ||
      ((Domanda.ordinale_domanda % 10) === 0 && this.state.nrUltimaDomandaDimensioneCorrente !== 9)
    ) {
      this.setState({
        visualizzaModaleErroreSequenzaCompilazioneRisposte: true,
      });
      return;
    }

    this.updateQuestionarioISA(this.setupdateQuestionarioISAResults, this.setErrorMessageUpdateRisposte, idQuestionario, idDomanda, idRisposta);

    const risposte = [...this.state.risposte];

    risposte[Domanda.ordinale_domanda - 1] = risposta.numero;

    //aggiornamento risposte dello state solo se cambiate
    if (typeof this.props.location.state.questionarioCreato !== "undefined" && this.props.location.state.questionarioCreato !== null &&
      typeof this.props.location.state.questionarioCreato.risposte !== "undefined" && this.props.location.state.questionarioCreato.risposte !== null &&
      this.props.location.state.questionarioCreato.risposte.length >= Domanda.ordinale_domanda - 1) {
      this.props.location.state.questionarioCreato.risposte[Domanda.ordinale_domanda - 1] = risposta.numero;
    }

    //redirect automatico alla Validazione in caso di esaurimento delle risopste (50 esima)
    if ((Domanda.ordinale_domanda - 1 + 1) === this.props.questionarioISA.domande.length) {
      this.setState({
        risposte: [...risposte],
        redirectToVisualizzazioneEValidazione: true,

      });
      return;
    }

    //aggiornamento dei puntatori della dimensione e della domanda compilata per la corretta visualizzaione

    //aggiornamento puntatore  della domanda compilata
    let ultimaDomandaDimensioneCorrente = (
      (Domanda.ordinale_domanda % 10) > this.state.nrUltimaDomandaDimensioneCorrente ? (Domanda.ordinale_domanda % 10) : this.state.nrUltimaDomandaDimensioneCorrente
    );

    //aggiornamento puntatore  della domanda compilata nel caso di compilazione dell'ultima risposta della dimensione
    ultimaDomandaDimensioneCorrente = (
      (Domanda.ordinale_domanda % 10) === 0 ? 10 : ultimaDomandaDimensioneCorrente
    );

    //let prossimaDomanda = (this.state.domandaCorrente < this.props.questionarioISA.domande.length - 1 ? this.state.domandaCorrente + 1 : this.state.domandaCorrente);
    
    /* let UltimaDomandaDimensioneCorrenteAgo = prossimaDomanda%10;
    if (UltimaDomandaDimensioneCorrenteAgo===0)
    {
      UltimaDomandaDimensioneCorrenteAgo=10;
    } */

    //aggiornamento puntatore  della  dimensione
    let prossimaDimensione = (ultimaDomandaDimensioneCorrente >= 10 ? this.state.nrDimensioneCorrente + 1 : this.state.nrDimensioneCorrente);


    // si cambia dimensione avanzando , nel caso si è cliccato sull'ultima domanda della dimensione
    //dobbiamo distinguere i seguenti casi
    //  1. la prima compilazione della decima domanda-> si va sulla prima domanda della dimensione successiva
    //  2. abbiamo aggiornato la decima domanda e  NON esistono già delle compilate successive -> idem  caso 1
    //  3. abbiamo aggiornato  la decima domanda e ne esistono già delle compilate successive -> si recupera la posizione da this.state.risposte.length/10 

    if (prossimaDimensione !== this.state.nrDimensioneCorrente) {

      let esistonoAltreRisposteDopoQuestaDomandaNr10Compilata = Domanda.ordinale_domanda < this.state.risposte.length;

      if (esistonoAltreRisposteDopoQuestaDomandaNr10Compilata === true) {

        //se andiamo a finire sull'ultima dimensione compilata
        if (prossimaDimensione === Math.floor(this.state.risposte.length / 10) + 1) {

          ultimaDomandaDimensioneCorrente = Math.floor(this.state.risposte.length % 10);
        }
        else //andiamo a finire su una dimensione già completamente compilata , si mette nove per consentire la selezione dell'ultima
        {
          ultimaDomandaDimensioneCorrente = 9;
        }

      }
      else // se non esistono altre domande a cui è stata data risposta nella dimensione in cui andiamo , si punta alla prima domanda  nr 0
      {
        ultimaDomandaDimensioneCorrente = 0;
      }
    }

    if (Domanda.ordinale_domanda % 10 !== 0) {
      console.log("Vado alla " + (Domanda.ordinale_domanda + 1));
      this.scrollToElement('#domanda' + (Domanda.ordinale_domanda + 1), document.querySelector("#HeaderContainer").clientHeight, 1000);
      // document.querySelector('#domanda' + (Domanda.ordinale_domanda + 1)).scrollIntoView({ 
      //   behavior: 'smooth', block: 'end'
      // }, false);
    }

    let prossimaDomanda = Domanda.ordinale_domanda + 1;

    if (prossimaDomanda > this.props.questionarioISA.domande.length) {
      prossimaDomanda = this.props.questionarioISA.domande.length;
    }

    this.setState({
      domandaCorrente: prossimaDomanda,
      risposte: [...risposte],
      nrDimensioneCorrente: prossimaDimensione,
      nrUltimaDomandaDimensioneCorrente: ultimaDomandaDimensioneCorrente,
    });
  }


  scrollToElement = (element, offset, duration) => {
    var startingY = window.pageYOffset;
    var elementY = window.pageYOffset + document.querySelector(element).getBoundingClientRect().top;
    // Se l'element è vicino al bordo inferiore, scrolla leggermente sopra l'elemento.
    var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY;
    var diff = targetY - startingY - offset;
    var easing = function (t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 };
    var start;

    if (!diff) return;

    // Chiamato prima di ogni renderizzazione di frame del browser.
    // Scrolling più fluido rispetto ad una soluzione basata su setTimeout / setInterval
    window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        var time = timestamp - start
        var percent = Math.min(time / duration, 1)
        percent = easing(percent)
        window.scrollTo(0, startingY + diff * percent)
        if (time < duration) {
            window.requestAnimationFrame(step)
        }
    })
  }


  dimensionePrecedenteClickedHandler = (risposta) => {

    let prossimaDimensione = (this.state.nrDimensioneCorrente > 1 ? this.state.nrDimensioneCorrente - 1 : this.state.nrDimensioneCorrente);
    // si va sulla penultima per consentire il click sul'ultima
    let ultimaDomandaDimensioneCorrente = 9;


    this.setState({
      nrDimensioneCorrente: prossimaDimensione,
      nrUltimaDomandaDimensioneCorrente: ultimaDomandaDimensioneCorrente,
    });
  }

  dimensioneSuccessivaClickedHandler = (risposta) => {

    let prossimaDimensione = (this.state.nrDimensioneCorrente < 6 ? this.state.nrDimensioneCorrente + 1 : this.state.nrDimensioneCorrente);

    let ultimaDomandaDimensioneCorrente = Math.floor(this.state.risposte.length % 10);

    //pier : aggiunto questo test perchè saltava sempre alla Visualizzazione
    //basta toglierlo per capire...
    if (prossimaDimensione === 6) {
      this.setState({
        redirectToVisualizzazioneEValidazione: true,
      });
    }
    else {
      this.setState({
        nrDimensioneCorrente: prossimaDimensione,
        redirectToVisualizzazioneEValidazione: false,
        nrUltimaDomandaDimensioneCorrente: ultimaDomandaDimensioneCorrente,
      });
    }
  }


  //restitusce le risposte di una domanda passata come parametro
  // è molto simile alla versione precedente (tolta gestione doppia selezione e doppia conferma)
  getRisposteDomandaCorrente = (domandaCorrente) => {

    let risposte = null;

    risposte = domandaCorrente.risposte.map((obj, i) => {

      const handler = () => {
        this.rispostaClickedHandler(domandaCorrente, obj);
      };

      let classe = null;

      if (typeof this.props.location.state.questionarioCreato !== "undefined" && this.props.location.state.questionarioCreato !== null &&
        typeof this.props.location.state.questionarioCreato.risposte !== "undefined" && this.props.location.state.questionarioCreato.risposte !== null &&
        this.props.location.state.questionarioCreato.risposte.length >= domandaCorrente.ordinale_domanda) {

        if (i === this.props.location.state.questionarioCreato.risposte[domandaCorrente.ordinale_domanda - 1] - 1) {
          classe = "previouslyselected";
        }
      }

      return <ListGroupItem className={classe} onClick={handler} key={i}><Row><Col xs={12}>{obj.numero}. {obj.descrizione}</Col></Row></ListGroupItem>

    });

    return risposte;
  }


  toggleCheckValutazioneImpatto = () => {
    this.setState({ checkValutazioneImpatto: !this.state.checkValutazioneImpatto });
  }

  toggleCheckTrattamentoDati = () => {
    this.setState({ checkTrattamentoDati: !this.state.checkTrattamentoDati });
  }

  toggleStartQuestionario = () => {
    this.setState({ startQuestionario: !this.state.startQuestionario });
  }


  //restituisce in una riga le informazioni di una domanda identificata
  // dal nr dimensione e dal numero domanda, a destra mette le risposte
  getSezioneDomandaRisposteDimensione = (nrDimensione, nrDomanda) => {

    //gestione di un identificatore univoco da attribuire al controllo FormGroup di React
    const idControllo = ((nrDimensione - 1) * 10 + nrDomanda).toString();

    //recupero informazioni domande da visualizzare
    const domandaDaVisualizzare = this.props.questionarioISA.domande[((nrDimensione - 1) * 10) + nrDomanda];
    const descrizioneDomanda = domandaDaVisualizzare.descrizione;

    if (this.state.questionarioISA === null) {
      return (
        <FormGroup controlId={idControllo}>
          <Panel><Panel.Body><Spinner /></Panel.Body></Panel>
        </FormGroup>

      );
    }

    if (!descrizioneDomanda) {
      return null;
    }

    return (

      <Panel id={"domanda" + domandaDaVisualizzare.numDomanda}><Panel.Body>
        <Grid fluid={true}>
          <Col xs={4} >
            <Row>
              Item {domandaDaVisualizzare.numDomanda}<br />
              {domandaDaVisualizzare.descrizione}
              &nbsp;&nbsp;
            </Row>
            &nbsp;
                    <Row  >
              {domandaDaVisualizzare.help}
              &nbsp;&nbsp;
            </Row>
          </Col>
          <Col xs={2} >
          </Col>
          <Col xs={6} >
            <Row>
              <Col xs={12}><h4>Grado di accordo.</h4></Col>
            </Row>
            <ListGroup className="striped">
              {this.getRisposteDomandaCorrente(domandaDaVisualizzare)}
            </ListGroup>
          </Col>
          <Row>

            &nbsp;
          </Row>
        </Grid>
      </Panel.Body></Panel>

    );
  }



  render() {

    if (this.state.redirectToHome === true) {
      return <Redirect to={{ pathname: "/" }} />
    }

    if (this.state.checkTrattamentoDati === null || this.state.checkValutazioneImpatto === null) {
      return <Spinner />;
    }

    if ((this.state.checkTrattamentoDati === false || this.state.checkValutazioneImpatto === false) && this.state.startQuestionario === true) {
      return <ModaleErroreGenerica
        chiudiModaleClicked={() => { this.setState({ startQuestionario: false }) }}
        informazioniErrore="Per poter procedere alla compilazione del questionario è necessario contrassegnare entrambe le spunte." />
    }

    if (this.state.checkTrattamentoDati === false || this.state.checkValutazioneImpatto === false || this.state.startQuestionario === false) {

      const messaggioValutazioneImpatto = "L'operatore dichiara di essere a conoscenza della valutazione di impatto del trattamento effettuato dal proprio Ente di appartenenza (D.P.I.A.) nonché delle misure tecniche od organizzative adottate per ridurre tali rischi.";
      const messaggioTrattamentoDati = "L'operatore dichiara che l'utente è stato informato sullo specifico trattamento dei propri dati ed ha espresso il proprio consenso ai sensi del Reg. (UE) 2016/679.";

      let checkboxValutazioneImpatto = <Checkbox onClick={this.toggleCheckValutazioneImpatto}>{messaggioValutazioneImpatto}</Checkbox>
      if (this.state.checkValutazioneImpatto === true) {
        checkboxValutazioneImpatto = <Checkbox checked onClick={this.toggleCheckValutazioneImpatto}>{messaggioValutazioneImpatto}</Checkbox>
      }

      let checkboxTrattamentoDati = <Checkbox onClick={this.toggleCheckTrattamentoDati}>{messaggioTrattamentoDati}</Checkbox>
      if (this.state.checkTrattamentoDati === true) {
        checkboxTrattamentoDati = <Checkbox checked onClick={this.toggleCheckTrattamentoDati}>{messaggioTrattamentoDati}</Checkbox>
      }

      return (
        <React.Fragment>
          <Grid fluid={true}>
            <Row>
              <Col xs={12}>
                {checkboxValutazioneImpatto}
              </Col>
              <Col xs={12}>
                {checkboxTrattamentoDati}
              </Col>
            </Row>
            <Row>&nbsp;</Row>
            <Row>
              <Col xs={12}><Button style={{ width: "100%" }} bsStyle="success" onClick={this.toggleStartQuestionario}>Inizia la compilazione</Button></Col>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    if (this.state.redirectToVisualizzazioneEValidazione === true) {
      return <Redirect to={{
        pathname: "/VisualizzaValidaQuestionarioISA", state: {
          IdQuestionario: this.props.location.state.questionarioCreato.id,
          codicePseudonomizzatoQuestionario: this.props.location.state.questionarioCreato.codicePseudonomizzato,
          utente: this.props.location.state.utente,
          questionarioCreato: this.props.location.state.questionarioCreato,
          risposte: this.state.risposte,
        }
      }} />
    }

    const modale = this.popolaModale();

    if (this.props.questionarioISA === null) {
      return <Spinner />;
    }

    const domandaCorrente = this.props.questionarioISA.domande[this.state.domandaCorrente - 1];
    const titoloDimensione = this.props.questionarioISA.domande[this.state.nrUltimaDomandaDimensioneCorrente + (this.state.nrDimensioneCorrente - 1) * 10].titolo;

    let codicePseudonomizzato = "";
    try {
      codicePseudonomizzato = this.props.location.state.questionarioCreato.codicePseudonomizzato;

    } catch (e) {
      if (globals.debug < 3) console.log('error', e);
      codicePseudonomizzato = "To Do";
    }

    return (

      <Fragment>
        <Well>
          <Link to="/">Home</Link> / <Link to="/CreaQuestionariASSO">Crea compilazione</Link> / <Link to={{ pathname: "/CreazioneQuestionari", state: { utente: this.props.location.state.utente } }}>Scegli ASSO o ISA</Link> / Compilazione ISA
        </Well>
        <Grid fluid={true}>
          <Row id="topdiv">
            <Col xs={9}><h4>ISA  {codicePseudonomizzato}</h4></Col>
            <Col xs={3}><h3 style={{ textAlign: "right" }}>
              {

                // la freccia a sx si abilita se siamo almeno sulla seconda dimensione
                this.state.nrDimensioneCorrente > 1 ?
                  <React.Fragment><i style={{ cursor: "pointer", fontSize: "xx-large" }} onClick={this.dimensionePrecedenteClickedHandler} className="fa fa-chevron-circle-left"></i>&nbsp;</React.Fragment>
                  :
                  <React.Fragment>
                    <i style={{ color: "lightgrey", fontSize: "xx-large" }} className="fa fa-chevron-circle-left"></i>
                    &nbsp;
                </React.Fragment>
              }
              {

                // la freccia a dx si abilita se 
                //  1 se siamo su una dimensione di cui abbiamo compilato tutte le risposte
                //  2 siamo tornati indietro in una dimensione su cui abbiamo compilato tutte le risposte
                // 3 abbiamo finito le domande (date tutte e 50 risposte)  

                //        siamo sull'ultima dimensione compilata                                        e   abbiamo compilato l'ultima                                                                            
                ((Math.floor(this.state.risposte.length % 10) === this.state.nrDimensioneCorrente) && (domandaCorrente.ordinale_domanda % 10 === 0))
                  ||
                  (Math.floor(this.state.risposte.length / 10) + 1 > this.state.nrDimensioneCorrente)
                  ||
                  this.props.questionarioISA.domande.length === this.state.risposte.length
                  ?
                  <React.Fragment>
                    <i style={{ cursor: "pointer", color: "black", fontSize: "xx-large" }} onClick={this.dimensioneSuccessivaClickedHandler} className="fa fa-chevron-circle-right"></i>
                    &nbsp;
                </React.Fragment>
                  /* Caso NON risposto a tutte le domande   */
                  :
                  <React.Fragment>
                    <i style={{ color: "lightgrey", fontSize: "xx-large" }} className="fa fa-chevron-circle-right"></i>
                    &nbsp;
                </React.Fragment>
              }

            </h3></Col>
          </Row>
          {/* nonostante il nome domandaCorrente.titolo, si tratta della descrizione della dimensione */}
          <Row>
            <Col xs={12}><h4>Dimensione {this.state.nrDimensioneCorrente} ({titoloDimensione})</h4></Col>
          </Row>
          {/* rendering  delle 10 domande */}

          {this.getSezioneDomandaRisposteDimensione(this.state.nrDimensioneCorrente, 0)}
          {this.getSezioneDomandaRisposteDimensione(this.state.nrDimensioneCorrente, 1)}
          {this.getSezioneDomandaRisposteDimensione(this.state.nrDimensioneCorrente, 2)}
          {this.getSezioneDomandaRisposteDimensione(this.state.nrDimensioneCorrente, 3)}
          {this.getSezioneDomandaRisposteDimensione(this.state.nrDimensioneCorrente, 4)}
          {this.getSezioneDomandaRisposteDimensione(this.state.nrDimensioneCorrente, 5)}
          {this.getSezioneDomandaRisposteDimensione(this.state.nrDimensioneCorrente, 6)}
          {this.getSezioneDomandaRisposteDimensione(this.state.nrDimensioneCorrente, 7)}
          {this.getSezioneDomandaRisposteDimensione(this.state.nrDimensioneCorrente, 8)}
          {this.getSezioneDomandaRisposteDimensione(this.state.nrDimensioneCorrente, 9)}

          <Row>
            <Col className="text-right" xs={12}>{this.state.domandaCorrente + 1}/{this.props.questionarioISA.domande.length}</Col>
            <Col xs={12}><ProgressBar now={(this.state.domandaCorrente + 1) * 100 / this.props.questionarioISA.domande.length} /></Col>
          </Row>
          {modale}
        </Grid>
        <script>
          var scroll = new SmoothScroll('a[href*="#"]');
        </script>
      </Fragment>
    )
  }


  /// Ciclo di vita del container
  componentDidMount = () => {
    if (this.props.questionarioISA === null) {
      this.props.scaricaQuestionarioISA(this.props.loginToken);
    } else {
      this.ripristinaStatoQuestionario();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    // in caso di cambio dimensione , con questa istruzione ci posizionaimo alla prima domanda.
    if (prevState !== null && prevState.nrDimensioneCorrente !== this.state.nrDimensioneCorrente) {
      //document.body.scrollTop = document.documentElement.scrollTop = 0;
      const topdiv = document.querySelector('#topdiv');
      
      if (topdiv) {
        topdiv.scrollIntoView({ 
          behavior: 'smooth', block: 'end'
        }, false); 
      }
    }

    if (this.props.questionarioISA !== prevProps.questionarioISA) {
      this.ripristinaStatoQuestionario();
    }

  }


  ripristinaStatoQuestionario = () => {

    if (typeof this.props.location.state.questionarioCreato !== "undefined" && this.props.location.state.questionarioCreato !== null) {

      let startQuestionario = false;
      let informativa_privacy = false;
      let valutazione_impatto = false;
      if (this.props.location.state.questionarioCreato.informativa_privacy !== false &&
        this.props.location.state.questionarioCreato.valutazione_impatto !== false) {
        startQuestionario = true;
        valutazione_impatto = true;
        informativa_privacy = true;
      }

      // Se arriviamo qui, siamo arrivati dalla creazione , dalla ricerca di un esistente o dalla visualizzazione
      //  per sapere che arrivati dalla Visualizzazione,
      //c'eravano andati siccome c'erano 50 risposte 
      // se torniamo qua dobbiamo evitare il ritorno automatico
      if (typeof this.props.location.state.redirectFromVisualizzazione !== "undefined" && this.props.location.state.redirectFromVisualizzazione !== null) {
        if (this.props.location.state.redirectFromVisualizzazione === true) {

          this.setState({
            domandaCorrente: 49,
            risposte: this.props.location.state.questionarioCreato.risposte,
            idQuestionarioRisposto: this.props.location.state.questionarioCreato.id,
            redirectToVisualizzazioneEValidazione: false,
            checkTrattamentoDati: informativa_privacy,
            checkValutazioneImpatto: valutazione_impatto,
            startQuestionario: startQuestionario,
            //con queste ultime 2 impostazioni si stabilisce la pagina  (dimensione) in cui posizionarsi
            //e l'ultima domanda della dimensione a cui si è data la risposta 
            // nrUltimaDomandaDimensioneCorrente serve per fare il controllo sulla compilazione in sequenza
            //siccome in questo caso torniamo dalla valutazione , ci posizionamo sulla penultima 
            //per consentire il click sull'ultima  
            nrDimensioneCorrente: 5,
            nrUltimaDomandaDimensioneCorrente: 9,
          });
          return;
        }
      }

      //redirect automatico alla visualizzazione/validazione nel caso di 50 risposte
      if (this.props.location.state.questionarioCreato.risposte.length === 50) {
        this.setState({
          domandaCorrente: this.props.location.state.questionarioCreato.risposte.length,
          risposte: this.props.location.state.questionarioCreato.risposte,
          idQuestionarioRisposto: this.props.location.state.questionarioCreato.id,
          redirectToVisualizzazioneEValidazione: true,
          checkTrattamentoDati: informativa_privacy,
          checkValutazioneImpatto: valutazione_impatto,
          startQuestionario: startQuestionario,
        });
      }
      else {
        this.setState({
          domandaCorrente: this.props.location.state.questionarioCreato.risposte.length,
          risposte: this.props.location.state.questionarioCreato.risposte,
          idQuestionarioRisposto: this.props.location.state.questionarioCreato.id,
          checkTrattamentoDati: informativa_privacy,
          checkValutazioneImpatto: valutazione_impatto,
          startQuestionario: startQuestionario,
          //calcolo della posizione (dimensione - ultima domanda risposta) con cui si determina dove posizionarsi nel rendering
          nrDimensioneCorrente: Math.floor(this.props.location.state.questionarioCreato.risposte.length / 10) + 1,
          nrUltimaDomandaDimensioneCorrente: this.props.location.state.questionarioCreato.risposte.length % 10,
        });

      }

    }
  }

  setupdateQuestionarioISAResults = (record) => {
  }

  //da finire: gestire una modale ??
  setErrorMessageUpdateRisposte = (errorMessage) => {
    this.setState({
      messaggioErroreRecuperoRisposte: errorMessage
    });
  }


  updateQuestionarioISA = (successFn,
    errorFn,
    asso_questionari_compilati,
    asso_domande,
    asso_risposte
  ) => {

    const data = {
      token: this.props.loginToken,
      method: "insertupdaterispostequestionarioISA",
      param: {
        asso_questionari_compilati: asso_questionari_compilati,
        asso_domande: asso_domande,
        asso_risposte: asso_risposte,
        valutazione_impatto: this.state.checkValutazioneImpatto,
        informativa_privacy: this.state.checkTrattamentoDati,
      }
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const nuovoRecord = JSON.parse(response.data.data);
          successFn(nuovoRecord);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata creazione questionario error: " + error);
        errorFn(error.message);
      });

  };


}

/// Redux
const mapStateToProps = state => {
  return {
    questionarioISA: state.questionarioISA,
    messaggioErroreScaricamentoQuestionarioISA: state.messaggioErroreISA,
    loginToken: state.loginToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    scaricaQuestionarioISA: (token) => dispatch(actions.scaricaQuestionarioISA(token)),
    effettuaLogout: () => dispatch(actions.effettuaLogout()),

  }
};

// #endregion
export default connect(mapStateToProps, mapDispatchToProps)(QuestionarioISA);