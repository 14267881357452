import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as globals from '../comuni/constants';


// #region login
export const effettuaLogoutSuccesso = () => {
  return {
    type: actionTypes.EFFETTUA_LOGOUT,
  };
}

export const effettuaLogout = () => {
  setTimeout(() => {window.location = '/' + globals.urlSottoPortale}, 100);
  return (dispatch, getState) => {
    dispatch(effettuaLogoutSuccesso());
  }
}

export const effettuaLoginSuccesso = (loginData) => {
  return {
    type: actionTypes.EFFETTUA_LOGIN_SUCCESSO,
    loginData: loginData
  };
}

export const effettuaLoginErrore = (codiceErrore, messaggioErroreLogin) => {
  return {
    type: actionTypes.EFFETTUA_LOGIN_ERRORE,
    messaggioErroreLogin: messaggioErroreLogin,
    codiceErroreLogin: codiceErrore,
  };
}

export const effettuaLogin = (username, password) => {

  return (dispatch, getState) => {
    let data = {
      token: "",
      method: "login",
      param: {
        username: username, 
        password: password, 
      }
    };

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {
        if (globals.debug < 3) console.log(response.data);

        if (response.data.result.success === true) {
          const innerData = JSON.parse(response.data.data);
          if (globals.debug < 3) console.log("Il token è " + innerData.token);
          dispatch(effettuaLoginSuccesso(innerData));
        } else {
          if (globals.debug < 3)  console.log("Errore logico di login " + JSON.stringify(response.data));
          dispatch(effettuaLoginErrore(response.data.result.code, response.data.result.message));
        }

      })
      .catch(function (error) {
        if (globals.debug < 3)  console.log("Eccezione: " + JSON.stringify(error))
        // handle error
        dispatch(effettuaLoginErrore(-1, error.message));
      });

  }
};
// #endregion login


// #region ASSO
export const scaricamentoQuestonarioASSOSuccesso = (questionario) => {
  return {
    type: actionTypes.SCARICA_QUESTIONARIO_ASSO_SUCCESSO,
    questionario: questionario
  };
}

export const scaricamentoQuestonarioASSOErrore = (msg) => {
  return {
    type: actionTypes.SCARICA_QUESTIONARIO_ASSO_ERRORE,
    messaggio: msg
  };
}


export const scaricaQuestionarioASSO = (token) => {

  return (dispatch, getState) => {

    const data = {
      token: token,
      method: "getdefinizionequestionarioasso",
      param: {}
    };

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const questionario = JSON.parse(response.data.data);
          if (globals.debug < 3) console.log(questionario);
          dispatch(scaricamentoQuestonarioASSOSuccesso(questionario));
        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          dispatch(scaricamentoQuestonarioASSOErrore(response.data.result.message));
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata definizione compilazione error: " + error);
        dispatch(scaricamentoQuestonarioASSOErrore(error.message));
      });
  }
};

// #endregion ASSO

// #region ISA


export const scaricamentoQuestonarioISASuccesso = (questionario) => {
  return {
    type: actionTypes.SCARICA_QUESTIONARIO_ISA_SUCCESSO,
    questionario: questionario
  };
}

export const scaricamentoQuestonarioISAErrore = (msg) => {
  return {
    type: actionTypes.SCARICA_QUESTIONARIO_ISA_ERRORE,
    messaggio: msg
  };
}


export const scaricaQuestionarioISA = (token) => {

  return (dispatch, getState) => {

    const data = {
      token: token,
      method: "getdefinizionequestionarioisa",
      param: {}
    };

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const questionario = JSON.parse(response.data.data);
          if (globals.debug < 3) console.log(questionario);
          dispatch(scaricamentoQuestonarioISASuccesso(questionario));
        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          dispatch(scaricamentoQuestonarioISAErrore(response.data.result.message));
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata definizione compilazione error: " + error);
        dispatch(scaricamentoQuestonarioISAErrore(error.message));
      });
  }
};


// #endregion

export const scaricamentoMessaggiSezioneSuccesso = (messaggiSezione) => {
  return {
    type: actionTypes.SCARICA_MESSAGGI_SEZIONE_SUCCESSO,
    messaggiSezione: messaggiSezione
  };
}

export const scaricamentoMessaggiSezioneErrore = (msg) => {
  return {
    type: actionTypes.SCARICA_MESSAGGI_SEZIONE_ERRORE,
    messaggio: msg
  };
}

export const scaricaMessaggiSezione = (idMessaggi, cacheMessaggi) => {

  const idMessaggiDaScaricare = idMessaggi.filter((obj, i) => {
    const inCache = cacheMessaggi.filter((obj2, i2) => {
      return (obj2.id === obj.id);
    });

    return inCache.length === 0;
  });

  const messaggiScaricati = idMessaggiDaScaricare.map((obj3, i3) => {
    return {
      id: obj3.id,
      sezione: "c",
      testo: "Testo scaricato " + i3
    };
  });

  return (dispatch, getState) => {
    dispatch(scaricamentoMessaggiSezioneSuccesso(messaggiScaricati));
  }




};


