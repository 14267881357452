import * as actionTypes from './actionTypes';

const initialState = {
    questionarioASSO: null,
    messaggioErroreASSO: null,
    questionarioISA: null,
    messaggioErroreISA: null,
    cacheMessaggiASSO: [],
    cacheUtenti: [],
    messaggioErroreCaricamentoMessaggiSezione: null,
    utentiASSO: null,
    messaggioErroreCaricaUtenti: null,
    utenteCreato: null,
    messaggioErroreCreaUtenti: null,
    loginToken: '68B100FC-41D5-4AEF-8BCE-87D95E29C748',
    userProfile: {
        token: '68B100FC-41D5-4AEF-8BCE-87D95E29C748',
        username: '',
        nome: null,
        cognome: null
    },
    messaggioErroreLogin: null,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EFFETTUA_LOGOUT:
            return {
                ...state,
                loginToken: null,
                userProfile: null,
                messaggioErroreLogin: null,
                codiceErroreLogin: null,
            }

        case actionTypes.EFFETTUA_LOGIN_SUCCESSO:
            return {
                ...state,
                loginToken: action.loginData.token,
                userProfile: action.loginData,
                messaggioErroreLogin: null,
                codiceErroreLogin: null,
            }

        case actionTypes.EFFETTUA_LOGIN_ERRORE:
            let messaggioErroreLogin = action.messaggioErroreLogin;
            if (state.messaggioErroreLogin === action.messaggioErroreLogin) {
                messaggioErroreLogin = action.messaggioErroreLogin + " ";
            }
            return {
                ...state,
                loginToken: null,
                userProfile: null,
                messaggioErroreLogin: messaggioErroreLogin,
                codiceErroreLogin: action.codiceErroreLogin,
            }

        case actionTypes.SCARICA_MESSAGGI_SEZIONE_SUCCESSO:
            return {
                ...state,
                cacheMessaggiASSO: [...action.messaggiSezione, ...state.cacheMessaggiASSO],
                messaggioErroreCaricamentoMessaggiSezione: null,
            }

        case actionTypes.SCARICA_MESSAGGI_SEZIONE_ERRORE:
            return {
                ...state,
                cacheMessaggiASSO: null,
                messaggioErroreCaricamentoMessaggiSezione: action.messaggio
            }

        case actionTypes.SCARICA_QUESTIONARIO_ASSO_SUCCESSO:
            return {
                ...state,
                questionarioASSO: action.questionario,
                messaggioErroreASSO: null
            }

        case actionTypes.SCARICA_QUESTIONARIO_ASSO_ERRORE:
            return {
                ...state,
                questionarioASSO: null,
                messaggioErroreASSO: action.messaggio
            }

        case actionTypes.SCARICA_QUESTIONARIO_ISA_SUCCESSO:
            return {
                ...state,
                questionarioISA: action.questionario,
                messaggioErroreISA: null
            }

        case actionTypes.SCARICA_QUESTIONARIO_ISA_ERRORE:
            return {
                ...state,
                questionarioISA: null,
                messaggioErroreISA: action.messaggio
            }

        case "@@INIT":
            break;

        default:
            console.log("Action sconosciuta: " + action.type);
    }
    return state;
};

export default reducer;