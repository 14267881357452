import React, { Component, Fragment } from 'react';
import { Table, Panel, Button, Grid, Row, Col, FormGroup, Well, ProgressBar } from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import ModaleStampaQuestionario from '../components/ModaleStampaQuestionario';
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoDefinizioneQuestionarioISA from '../components/ModaleErroreScaricamentoDefinizioneQuestionarioISA';
import axios from 'axios';
import moment from 'moment-timezone';
import '../css/tooltip.css';
import './VisualizzaQuestionarioISA.css';
import ModaleErroreScaricamentoInformazioniQuestionarioISA from '../components/ModaleErroreScaricamentoInformazioniQuestionarioISA';
import ModaleErroreScaricamentoDatiGraficoQuestionarioISA from '../components/ModaleErroreScaricamentoDatiGraficoQuestionarioISA';
import ModaleErroreStampaPdf from '../components/ModaleErroreStampaPdf';
import * as globals from '../comuni/constants';


class VisualizzaQuestionarioISA extends Component {

    state = {
        apriModaleStampa: false,
        domandaCorrente: 0,
        questionario: null,
        redirectToHome: false,
        questionarioISATrovato: null,
        messaggioErroreQuestionarioIsaCercato: null,
        //24.4.2019 per sezione D , recupero con axiso by this.recuperaQuestionarioASSOCorrelato()
        questionarioASSOCorrelato: null,
        visualizzaModaleErroreQuestionarioASSOCorrelato: false,
        messaggioErroreQuestionarioASSOCorrelato: null,
        //par grafico sezione C
        datiPerGrafico: null,
        visualizzaModaleErroreDatiGraficoQuestionarioISA: false,
        messaggioErroreDatiGraficoISA: null,
        messaggioErroreStampaPdf: null,
        visualizzaModaleErroreStampaPdf: false,
        base64PdfQuestionario: null,
        richiestaCreazionePdf: false,
        nomeFilesStampaPdf: null,
    };

    // #region Eventi
    chiudiModaleCliccato = () => {
        this.setState({
            apriModaleStampa: false,
            redirectToHome: false
        });
    }

    stampaCliccato = () => {

        let cssHtml = "<!DOCTYPE html>  " +
            " <html lang=\"en\" style=\"font-size: 1.2em\"> " +
            " <head> " +
            "    <meta charset=\"utf-8\">" +
            "   <meta name=\"viewport\" content=\"width=device-width, initial-scale=1, shrink-to-fit=no\"> " +
            " <meta name=\"theme-color\" content=\"#000000\">" +
            "<link" +
            "   href=\"https://fonts.googleapis.com/css?family=Titillium+Web:400,200,200italic,300italic,300,400italic,600,600italic,700,700italic,900\" " +
            "  rel=\"stylesheet\" type=\"text/css\">" +
            "<link rel=\"stylesheet\" title=\"Carattere normale\" type=\"text/css\" media=\"screen\"" +
            "    href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/struttura.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/bootstrap-3.3.7.min.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/bootstrap-3.3.7-theme.min.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/all.css\">" +
            "<link rel=\"stylesheet\" type=\"text/css\" media=\"screen\"" +
            "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/ita-bootstrap.css\">" +
            "<link rel=\"stylesheet\" title=\"Carattere normale\" type=\"text/css\" media=\"screen\"" +
            "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/0-template-agid_modello_11947_16589.css\">" +
            "<link rel=\"alternate stylesheet\" title=\"Carattere grande\" type=\"text/css\" media=\"screen\"" +
            "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/caratteregrande.css\" disabled=\"\">" +
            "<link rel=\"alternate stylesheet\" title=\"Contrasto elevato\" type=\"text/css\" media=\"screen\"" +
            "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/fortecontrasto.css\" disabled=\"\">" +
            "<link rel=\"alternate stylesheet\" title=\"Senza grafica\" type=\"text/css\" media=\"screen\"" +
            "   href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/senzagrafica.css\" disabled=\"\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/nyroModal.css\" type=\"text/css\"" +
            "   media=\"screen\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/tipsy.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/jquery.tagit.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/tagit.ui-zendesk.css\">" +
            "<link rel=\"stylesheet\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/VisualizzaQuestionarioISA.css\">" +
            "<link rel=\"stylesheet\" type=\"text/css\" media=\"print\" href=\"" + globals.urlPortale + "/" + globals.urlSottoPortale + "/css/stampa.css\">" +
            "<title>Demo ASSO-ISA</title>" +
            "</head>" +
            "<body><div class=\"container-fluid\" style=\"font-size:16px!important\">";

        document.getElementById("chartHiddenDiv").style.display = "block";
        document.getElementById("chartDiv").style.display = "none";


        let pdfInHtmp = document.getElementById("toPdf").innerHTML;

        let fineHtml = "</div></body> </html>"

        this.setState({
            richiestaCreazionePdf: true,
            base64PdfQuestionario: null,
            messaggioErroreStampaPdf: null,
            visualizzaModaleErroreStampaPdf: false,
            nomeFilesStampaPdf: null
        });

        this.creaStampaPdf(cssHtml + pdfInHtmp + fineHtml, this.props.location.state.questionario.codicePseudonomizzato);

        document.getElementById("chartHiddenDiv").style.display = "none";
        document.getElementById("chartDiv").style.display = "block";

    }

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }
    // #endregion

    // #region funzioni helper per il render
    getIndiceQuestionario = () => {
        let tabella = null;

        tabella = this.props.questionarioISA.domande.map((obj, i) => {

            const risposta = obj.risposte.find((valore, indice) => {
                return valore.numero === this.state.questionario.risposte[i];
            });

            const descrizioneRisposta = risposta ? risposta.descrizione : "domanda non ancora risposta";
            const numDomanda = obj.numDomanda;
            const descrizioneDomanda = obj.descrizione;

            return (
                <tr key={i}>
                    <td>
                        <div>
                            <h5>{numDomanda}.  {descrizioneDomanda} - <strong>{descrizioneRisposta}</strong></h5>
                        </div>
                    </td>
                </tr>
            );

        });

        return (
            <React.Fragment>
                <h4>{this.props.questionarioISA.domande[0].titolo}</h4>
                <Table striped bordered condensed hover>
                    <tbody>
                        {tabella.slice(0, 10)}
                    </tbody>
                </Table>
                <div className="page_break">  &nbsp; </div>
                <h4>{this.props.questionarioISA.domande[10].titolo}</h4>
                <Table striped bordered condensed hover>
                    <tbody>
                        {tabella.slice(10, 20)}
                    </tbody>
                </Table>
                <div className="page_break">  &nbsp; </div>
                <h4>{this.props.questionarioISA.domande[20].titolo}</h4>
                <Table striped bordered condensed hover>
                    <tbody>
                        {tabella.slice(20, 30)}
                    </tbody>
                </Table>
                <div className="page_break">  &nbsp; </div>
                <h4>{this.props.questionarioISA.domande[30].titolo}</h4>
                <Table striped bordered condensed hover>
                    <tbody>
                        {tabella.slice(30, 40)}
                    </tbody>
                </Table>
                <div className="page_break">  &nbsp; </div>
                <h4>{this.props.questionarioISA.domande[40].titolo}</h4>
                <Table striped bordered condensed hover>
                    <tbody>
                        {tabella.slice(40, 50)}
                    </tbody>
                </Table>
                <div className="page_break">  &nbsp; </div>
            </React.Fragment>
        );
    }

    getHeaderSezione(nomeSezione) {
        switch (nomeSezione.toLowerCase()) {

            case "a":
                return "Dati di compilazione";

            case "b":
                return "ISA - Indicatori Sviluppo Autonomie";

            case "c":
                return "Punteggi Dimensioni ISA";

            case "d":
                return "Correlazione con ASSO - Attivazione Sociale Sostenibile";

            case "e":
                return "Dimensione A - Capacità socio cognitive";

            case "f":
                return "Specifiche fascia età Giovani: < 25 anni (Fascia 1 dell'Item 0)";

            case "g": // Competenze per il lavoro
                return "Specifiche fascia età Under 45: >=25 e <45 (Fascia 2, 3 dell'Item 0)";

            case "h": // Competenze per il lavoro
                return "Specifiche fascia età Over 45: >=45 (Fascia 4, 5, 6 dell'Item 0)";

            case "i":
                return "Dimensione B - Assertività e competenze sociali";

            case "j":
                return "Specifiche fascia età Giovani: < 25 anni (Fascia 1 dell'Item 0)";

            case "k":
                return "Specifiche fascia età Under 45: >=25 e <45 (Fascia 2, 3 dell'Item 0)o";

            case "l":
                return "Specifiche fascia età Over 45: >=45 (Fascia 4, 5, 6 dell'Item 0)";
            case "m":
                return "Dimensione C - Emotività";

            case "n":
                return "Specifiche fascia età Giovani: < 25 anni (Fascia 1 dell'Item 0)";

            case "o":
                return "Specifiche fascia età Under 45: >=25 e <45 (Fascia 2, 3 dell'Item 0)";
            case "p":
                return "Specifiche fascia età Over 45: >=45 (Fascia 4, 5, 6 dell'Item 0)";
            case "q":
                return "Dimensione D - Valori e motivazioni";
            case "r":
                return "Specifiche fascia età Giovani: < 25 anni (Fascia 1 dell'Item 0)";
            case "s":
                return "Specifiche fascia età Under 45: >=25 e <45 (Fascia 2, 3 dell'Item 0)";
            case "t":
                return "Specifiche fascia età Over 45: >=45 (Fascia 4, 5, 6 dell'Item 0)";
            case "u":
                return "Dimensione E - Capacità di autodeterminazioni";
            case "v":
                return "Specifiche fascia età Giovani: < 25 anni (Fascia 1 dell'Item 0)";


            case "w":
                return "Specifiche fascia età Under 45: >=25 e <45 (Fascia 2, 3 dell'Item 0)";

            case "x":
                return "Specifiche fascia età Over 45: >=45 (Fascia 4, 5, 6 dell'Item 0))";

            default:
                return "Intestazione non trovata"
        }
    }

    getSezioneA = () => {

        const nomeSezione = "a";
        const idControllo = "Sezione " + nomeSezione.toUpperCase();
        const headerSezione = this.getHeaderSezione(nomeSezione);

        // let dataValidazione = (this.props.location.state.questionario.dataValidazione === null ? "" : moment(this.props.location.state.questionario.dataValidazione, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY"));

        // let dataNascita = (this.props.location.state.utente.dataNascita === null ? "" : moment(this.props.location.state.utente.dataNascita, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY"));

        const dataNascitaCorta = moment(this.props.location.state.utente.dataNascita, "YYYY-MM-DDT");
        const NascitaCorta = dataNascitaCorta.format('LL');

        if (this.state.questionario === null) {
            return (<FormGroup controlId={idControllo}>
                <Row className="container-fluid" style={{ textAlign: "left" }} >{idControllo}   {headerSezione}</Row>
                <Panel><Panel.Body><Spinner /></Panel.Body></Panel>
            </FormGroup>);
        }

        return (
            <FormGroup controlId={idControllo}>
                <h3>{idControllo}   {headerSezione}</h3>
                <Panel>
                    <Panel.Body>

                        <Col xs={12} sm={6}>Nome Cognome Utente: {this.props.location.state.utente.nome} {this.props.location.state.utente.cognome}  </Col>
                        <Col xs={12} sm={6}>Comune di Residenza: {this.props.location.state.utente.comuneResidenza}</Col>
                        <Col xs={12} sm={6}>Data di nascita: {NascitaCorta}</Col>
                        <Col xs={12} sm={6}>Cittadinanza: {this.props.location.state.utente.cittadinanza}</Col>
                        <Col xs={12} sm={6}>Genere: {this.props.location.state.utente.sesso === "F" ? "Femmina" : "Maschio"}</Col>
                        <Col xs={12} sm={6}>Codice Fiscale: {this.props.location.state.utente.codiceFiscale}</Col>

                        {/* <Col xs={12} sm={6}>
                            Codice Pseudonomizzato  : '{this.state.questionario.codicePseudonomizzato}''
                        </Col>
                        <Col xs={12} sm={6}>
                            Data Validazione  :   '{dataValidazione}''
                            </Col>
                        <Col xs={12} sm={6}>
                            Codice pseudonomizzato utente :   '{this.props.location.state.utente.codicePseudonomizzato}''
                            </Col>
                        <Col xs={12} sm={6}>
                            Dati richiedente  :   {this.props.location.state.utente.cognome}    {this.props.location.state.utente.nome}
                        </Col>

                        <Col xs={12} sm={6}>
                            Data nascita :'{dataNascita},'
                            </Col>
                        <Col xs={12} sm={6}>
                            Genere : '{this.props.location.state.utente.sesso}' , cittadinanza : '{this.props.location.state.utente.cittadinanza}'
                            </Col>
                        <Col xs={12} sm={6}>
                            Comune di residenza : '{this.props.location.state.utente.comuneResidenza}'
                            </Col>
                        <Col xs={12} sm={6}>
                            Operatore : '{this.props.location.state.questionario.operatore}' , struttura : '{this.props.location.state.questionario.struttura}'
                            </Col> */}
                        {this.getSezioneDMini()}
                    </Panel.Body>
                </Panel>
            </FormGroup>

        );
    }

    getSezioneD = () => {

        const nomeSezione = "d";
        const idControllo = "Sezione " + nomeSezione.toUpperCase();
        const headerSezione = this.getHeaderSezione(nomeSezione);

        if (this.state.questionario === null) {
            return (<FormGroup controlId={idControllo}>
                <Row className="container-fluid" style={{ textAlign: "left" }} >{idControllo}   {headerSezione}</Row>
                <Panel><Panel.Body><Spinner /></Panel.Body></Panel>
            </FormGroup>);
        }

        let codicePseudoN = "";
        let dataValidazione = "";
        let punteggioQ = "";

        let dataNascita = "";
        let nome = "";
        let cognome = "";
        let sesso = "";
        let cittadinanza = "";
        let comuneResidenza = "";
        let codicePseudAnag = "";

        if (this.state.questionarioASSOCorrelato === null) {
            codicePseudoN = "";
            dataValidazione = "";
            punteggioQ = "";

        }
        else {
            codicePseudoN = (this.state.questionarioASSOCorrelato.codicePseudonomizzato === null ? "" : this.state.questionarioASSOCorrelato.codicePseudonomizzato);
            dataValidazione = (this.state.questionarioASSOCorrelato.dataValidazione === null ? "" : moment(this.state.questionarioASSOCorrelato.dataValidazione, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY"));
            punteggioQ = (this.state.questionarioASSOCorrelato.ValoreQ === null ? "" : this.state.questionarioASSOCorrelato.ValoreQ);
        }

        if (typeof this.props.location.state.utente === "undefined" || this.props.location.state.utente === null) {

            dataNascita = "";
            nome = "";
            cognome = "";
            sesso = "";
            cittadinanza = "";
            comuneResidenza = "";
            codicePseudAnag = "";
        }
        else {
            dataNascita = (this.props.location.state.utente.dataNascita === null ? "" : moment(this.props.location.state.utente.dataNascita, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY"));
            nome = (this.props.location.state.utente.nome === null ? "" : this.props.location.state.utente.nome);
            cognome = (this.props.location.state.utente.cognome === null ? "" : this.props.location.state.utente.cognome);
            sesso = (this.props.location.state.utente.sesso === null ? "" : this.props.location.state.utente.sesso);
            cittadinanza = (this.props.location.state.utente.cittadinanza === null ? "" : this.props.location.state.utente.cittadinanza);
            comuneResidenza = (this.props.location.state.utente.comuneResidenza === null ? "" : this.props.location.state.utente.comuneResidenza);
            codicePseudAnag = (this.props.location.state.utente.codicePseudonomizzato === null ? "" : this.props.location.state.utente.codicePseudonomizzato);
        }

        return (

            <FormGroup controlId={idControllo}>
                <Row className="container-fluid" style={{ textAlign: "left" }} >{idControllo}   {headerSezione}</Row>
                <Panel>
                    <Panel.Body>
                        <Row>
                            Codice Pseudonomizzato ASSO correlato :  '{codicePseudoN}'
                        </Row>
                        <Row>
                            Data Validazione  :  '{dataValidazione} , Punteggio : {punteggioQ}
                        </Row>
                        <Row>
                            Codice pseudonomizzato utente :   '{codicePseudAnag}''
                        </Row>
                        <Row>
                            Dati richiedente :  '{cognome}    {nome}'
                        </Row>
                        <Row>
                            Data nascita :'{dataNascita},'
                        </Row>
                        <Row>
                            Genere : '{sesso}' , cittadinanza : '{cittadinanza}'
                        </Row>
                        <Row>
                            Comune di residenza : '{comuneResidenza}'
                        </Row>
                        <Row>
                            Operatore : '{this.props.location.state.questionario.operatore}' , struttura : '{this.props.location.state.questionario.struttura}'
                        </Row>
                    </Panel.Body>
                </Panel>

            </FormGroup>

        );
    }

    getSezioneDMini = () => {

        let codicePseudoN = "";
        let dataValidazione = "";
        let punteggioQ = "";


        if (this.state.questionarioASSOCorrelato === null) {
            codicePseudoN = "";
            dataValidazione = "";
            punteggioQ = "";

        }
        else {
            codicePseudoN = (this.state.questionarioASSOCorrelato.codicePseudonomizzato === null ? "" : this.state.questionarioASSOCorrelato.codicePseudonomizzato);
            dataValidazione = (this.state.questionarioASSOCorrelato.dataValidazione === null ? "" : moment(this.state.questionarioASSOCorrelato.dataValidazione, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY"));
            punteggioQ = (this.state.questionarioASSOCorrelato.ValoreQ === null ? "" : this.state.questionarioASSOCorrelato.ValoreQ);
        }

        return (

            <React.Fragment>
                    <Col xs={12} style={{ textAlign: "left", fontWeight: "bold" }}>&nbsp;</Col>
                    <Col xs={12} style={{ textAlign: "left", fontWeight: "bold" }}>ASSO COLLEGATO</Col>
                    <Col xs={12} sm={6}>
                        Codice Pseudonomizzato:  '{codicePseudoN}'
                        </Col>
                    <Col xs={12} sm={6}>
                        Data Validazione  :  '{dataValidazione} , Punteggio : {punteggioQ}
                    </Col>
            </React.Fragment>
        );
    }

    getSezioneC = () => {

        const nomeSezione = "c";
        const idControllo = "Sezione " + nomeSezione.toUpperCase();
        const headerSezione = this.getHeaderSezione(nomeSezione);

        if (this.state.questionario === null || this.state.datiPerGrafico === null) {
            return (
                <FormGroup controlId={idControllo}>
                    <Row className="container-fluid" style={{ textAlign: "left" }} >{idControllo}   {headerSezione}</Row>
                    <Panel>
                        <Panel.Body><Spinner /></Panel.Body>
                    </Panel>
                </FormGroup>);
        }

        return (

            <FormGroup controlId={idControllo}>
                <Row className="container-fluid" style={{ textAlign: "left" }} >{idControllo}   {headerSezione}</Row>
                <Panel>
                    <Panel.Body>
                        <Row>
                            <div id="chartHiddenDiv" style={{ display: "none" }} >
                                <img id="jpg-export" alt="Immagine grafico sezione C "   ></img>
                            </div>
                        </Row>

                        <Row style={{ fontSize: "14px" }}>
                            <div id="chartDiv" style={{ height: "calc((100vw) * 0.3)" }}>
                            </div>
                            <div id="statsDiv">
                                <div className="container-fluid">
                                    <span className="labels"><span className="onelabel">Media</span><span className="onelabel">Dev.Std</span></span>
                                    <div className="tableWrapper">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.datiPerGrafico[0].MediaPunteggio.toFixed(2).toString().replace('.', ',')}</td>
                                                    <td>{this.state.datiPerGrafico[1].MediaPunteggio.toFixed(2).toString().replace('.', ',')}</td>
                                                    <td>{this.state.datiPerGrafico[2].MediaPunteggio.toFixed(2).toString().replace('.', ',')}</td>
                                                    <td>{this.state.datiPerGrafico[3].MediaPunteggio.toFixed(2).toString().replace('.', ',')}</td>
                                                    <td>{this.state.datiPerGrafico[4].MediaPunteggio.toFixed(2).toString().replace('.', ',')}</td>
                                                </tr>
                                                <tr>
                                                    <td>{this.state.datiPerGrafico[0].DevstandardPunteggio.toFixed(2).toString().replace('.', ',')}</td>
                                                    <td>{this.state.datiPerGrafico[1].DevstandardPunteggio.toFixed(2).toString().replace('.', ',')}</td>
                                                    <td>{this.state.datiPerGrafico[2].DevstandardPunteggio.toFixed(2).toString().replace('.', ',')}</td>
                                                    <td>{this.state.datiPerGrafico[3].DevstandardPunteggio.toFixed(2).toString().replace('.', ',')}</td>
                                                    <td>{this.state.datiPerGrafico[4].DevstandardPunteggio.toFixed(2).toString().replace('.', ',')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div id="totDiv">
                                <div className="container-fluid">
                                    <span className="labels">Totale {this.getTotaleISA()}</span>
                                    <div className="tableWrapper"><ProgressBar now={this.getTotaleISA()} /> </div>
                                </div>
                            </div>
                        </Row>
                    </Panel.Body>
                </Panel>
            </FormGroup>

        );
    }

    getTotaleISA = () => {
        let totale = 0;

        this.state.datiPerGrafico.forEach((item, index) => {
            console.log(item);
            totale += item.SommaPunteggio;
        });

        totale = totale * 2 / 5;

        return totale;
    }

    drawChart = () => {

        if (this.state.datiPerGrafico === null || document.getElementById("chartDiv") === null) {
            return;
        }

        var Plotly = require('../utility/custom-plotly.js');
        var d3 = Plotly.d3;
        var img_jpg = d3.select('#jpg-export');
        const xValue = ['Sociocognitiva', 'Relazionale', 'Emotiva', 'Valoriale e motivazionale', 'Prelavorativa e dell\'agire'];

        var yValue = [
            this.state.datiPerGrafico[0].SommaPunteggio * 2,
            this.state.datiPerGrafico[1].SommaPunteggio * 2,
            this.state.datiPerGrafico[2].SommaPunteggio * 2,
            this.state.datiPerGrafico[3].SommaPunteggio * 2,
            this.state.datiPerGrafico[4].SommaPunteggio * 2,
        ];

        var trace1 = {
            x: xValue,
            y: yValue,
            type: 'bar',
            text: yValue,
            textposition: 'outside',
            hoverinfo: 'none',
            marker: {
                // color: 'rgb(158,202,225)',
                color: 'rgb(255,165,0)',
                opacity: 0.4,
                line: {
                    color: 'rbg(8,48,107)',
                    width: 1.5
                },
            }
        };

        var data = [trace1];

        var layout = {
            title: '',
            yaxis: {
                ticks: '',
                tickmode: 'array',
                tickvals: [0, 16, 32, 48, 66, 82, 100],
                ticktext: ['0', 'basso', '', 'medio', '', 'alto', '100'],
                tickangle: -90,
            },
            font: {
                fontWeight: 'bold'
            }
        };

        Plotly.newPlot('chartDiv', data, layout, { showSendToCloud: true, responsive: true })
            .then(
                function (gd) {
                    Plotly.toImage(gd, { height: 300, width: 1000 })
                        .then(
                            function (url) {
                                img_jpg.attr("src", url);
                                return Plotly.toImage(gd, { format: 'jpeg', height: 300, width: 1000 });
                            }
                        )
                });

    }

    getSezione = (nomeSezione) => {

        const idControllo = "Sezione " + nomeSezione.toUpperCase();
        const headerSezione = this.getHeaderSezione(nomeSezione);

        if (this.state.questionario === null) {
            return (<FormGroup controlId={idControllo}>
                <Row className="container-fluid" style={{ textAlign: "left" }} >{idControllo}   {headerSezione}</Row>
                <Panel><Panel.Body><Spinner /></Panel.Body></Panel>
            </FormGroup>);
        }

        const messaggioSezione = this.state.questionario.testi.find((obj, i) => {
            return obj.sezione.toUpperCase() === nomeSezione.toUpperCase();
        });

        if (!messaggioSezione) {
            return null;
        }

        return (
            <FormGroup controlId={idControllo}>
                <Row className="container-fluid" style={{ textAlign: "left" }} >{idControllo}   {headerSezione}</Row>
                <Panel><Panel.Body>{messaggioSezione.testo}</Panel.Body></Panel>
            </FormGroup>
        );
    }

    popolaModale = () => {
        let modale = null;

        if (this.state.apriModaleStampa === true) {
            modale = <ModaleStampaQuestionario
                chiudiModaleClicked={this.chiudiModaleCliccato}
                nomeFilesPdf={this.state.nomeFilesStampaPdf}
                filePdf={this.state.base64PdfQuestionario}
                tipoQuestionario="ISA"
            />;
        }

        if (this.props.messaggioErroreScaricamentoQuestionarioISA !== null) {
            return <ModaleErroreScaricamentoDefinizioneQuestionarioISA
                messaggioErrore={this.props.messaggioErroreScaricamentoQuestionarioISA}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }
        if (this.props.visualizzaModaleErroreQuestionarioASSOCorrelato === true) {
            return <ModaleErroreScaricamentoInformazioniQuestionarioISA
                messaggioErrore={this.props.messaggioErroreQuestionarioASSOCorrelato}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }


        if (this.state.visualizzaModaleErroreDatiGraficoQuestionarioISA === true) {
            return <ModaleErroreScaricamentoDatiGraficoQuestionarioISA
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }


        if (this.props.visualizzaModaleErroreQuestionarioIsaCercato === true) {
            modale = <ModaleErroreScaricamentoInformazioniQuestionarioISA
                messaggioErrore={this.props.messaggioErroreQuestionarioIsaCercato}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        if (this.props.visualizzaModaleErroreStampaPdf === true) {
            return <ModaleErroreStampaPdf
                messaggioErrore={this.state.messaggioErrore}
                chiudiModaleClicked={this.chiudiModaleErroreClicked} />
        }

        return modale;
    }

    getAnagraficaUtente = () => {
        const dataNascita = moment(this.props.location.state.utente.dataNascita, "YYYY-MM-DDTHH:mm:ss");
        const eta = moment().diff(dataNascita, 'years');

        return (
            <React.Fragment>
                <Row>
                    <Col xs={12} md={2}>ID: {this.props.location.state.utente.codicePseudonomizzato}</Col>
                    <Col xs={12} md={2}>Genere: {this.props.location.state.utente.sesso === "F" ? "Femmina" : "Maschio"}</Col>
                    <Col xs={12} md={4}>Età: {eta} anni</Col>
                    <Col xs={12} md={4}>Cittadinanza: {this.props.location.state.utente.cittadinanza}</Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>Comune: {this.props.location.state.utente.comuneResidenza}</Col>
                    <Col xs={12} md={4}>Operatore: {this.props.location.state.questionario.operatore}</Col>
                    <Col xs={12} md={4}>Struttura: {this.props.location.state.questionario.struttura}</Col>
                </Row>
            </React.Fragment>
        );
    }

    getAnagraficaCompilatore = () => {

        return (
            <React.Fragment>
                <Row>
                    <Panel>
                        <Panel.Body>
                            <Col xs={12} sm={6}>Compilatore: {this.state.questionario.compilatore.nomecompilatore} {this.state.questionario.compilatore.cognomecompilatore} </Col>
                            <Col xs={12} sm={6}>Gruppo:  {this.state.questionario.compilatore.nomeEnte}</Col>
                        </Panel.Body>
                    </Panel>
                </Row>
                <div className="page_break">  &nbsp; </div>
                <div className="page_break">  &nbsp; </div>
            </React.Fragment>
        );
    }


    // #endregion

    render() {

        if (this.state.redirectToHome === true) {
            return <Redirect to={{ pathname: "/" }} />
        }

        const modale = this.popolaModale();

        if ((typeof this.props.questionarioISA === "undefined" || this.props.questionarioISA === null) ||
            this.state.questionario === null) {
            return <Spinner />;

        }

        if (this.state.base64PdfQuestionario === null && this.state.richiestaCreazionePdf === true) {
            return <Spinner />;
        }

        return (
            <Fragment>
                <Well>
                    <Link to="/">Home</Link> / <Link to="/RicercaUtentiPerISA">Ricerca ISA</Link> / <Link to={{ pathname: "/RicercaQuestionariISA", state: { utente: this.props.location.state.utente } }}>Seleziona ISA</Link> / Visualizza ISA
                </Well>
                <Grid fluid={true}>
                    <Row>
                        <Col xs={6} md={2}>
                            <Button onClick={this.stampaCliccato} bsStyle="success">Salva copia</Button>
                        </Col>
                        <Col className="text-right" xs={6} md={3}>
                        </Col>
                        <Col xs={12}>
                            <h4>Visualizza ISA per utente <span style={{ fontWeight: "normal" }}> &nbsp;-&nbsp;
                                {this.props.location.state.utente.nome} {this.props.location.state.utente.cognome}, C.F.:&nbsp;{this.props.location.state.utente.codiceFiscale}
                            </span></h4>
                        </Col>
                    </Row>
                    <Row>
                        <h5 style={{ textAlign: "center" }} >Codice ISA {this.state.questionario.codicePseudonomizzato} </h5>
                    </Row>
                    <div id="toPdf">
                        <Row>
                            {this.getSezioneA()}
                        </Row>
                        <Row>
                            {this.getAnagraficaCompilatore()}
                        </Row>
                        <Row>
                            <h3 style={{ textAlign: "left" }} >Sezione B ISA - Indicatori Sviluppo Autonomie </h3>
                        </Row>
                        <Row>
                            {this.getIndiceQuestionario()}
                        </Row>
                        <Row>
                            {this.getSezioneC()}
                        </Row>
                    </div>
                    {modale}
                </Grid>
            </Fragment>
        );
    }

    componentDidUpdate = () => {
        this.drawChart();
    }

    componentDidMount = () => {

        if (this.props.questionarioISA === null) {
            this.props.scaricaQuestionarioISA(this.props.loginToken);
        }

        this.scaricaQuestionarioByIdQuestionario(this.props.location.state.questionario.id);

        this.recuperaQuestionarioASSOCorrelato();

        this.recuperaDatiGraficoQuestionarioISA(this.props.location.state.questionario.id);

        this.drawChart();
    }

    mostraDatiGraficoQuestionarioISA = (datiGrafico) => {
        this.setState({
            datiPerGrafico: datiGrafico
        });

    }

    mostraModaleErroreDatiGraficoQuestionarioISA = (messaggioErrore) => {
        this.setState({
            visualizzaModaleErroreDatiGraficoQuestionarioISA: true,
            messaggioErroreDatiGraficoISA: messaggioErrore
        });

    }

    recuperaDatiGraficoQuestionarioISA = (idquestionario) => {

        const successFn = this.mostraDatiGraficoQuestionarioISA;
        const errorFn = this.mostraModaleErroreDatiGraficoQuestionarioISA;
        const id = idquestionario;

        const data = {
            token: this.props.loginToken,
            method: "getdatipergraficoquestionarioisabyid",
            param: {
                id: id,
            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const nuovoRecord = JSON.parse(response.data.data);
                    successFn(nuovoRecord);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata recupero ASSO correlato error: " + error);
                errorFn(error.message);
            });

    };

    scaricaQuestionarioByIdQuestionario = (idQuestionario) => {

        const data = {
            token: this.props.loginToken,
            method: "getquestionarioISAbyId",
            param: {
                id: idQuestionario,
            }
        };

        const setData = (questionario) => {
            this.setState({
                questionario: questionario,
            });
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    if (globals.debug < 3) console.log(JSON.parse(response.data.data));
                    setData(JSON.parse(response.data.data));

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata scaricaQuestionarioByIdQuestionario ISA error: " + error);
            });

    }

    mostraQuestionarioAssoCorrelato = (questionarioCorrelato) => {
        this.setState({
            questionarioASSOCorrelato: questionarioCorrelato
        });

    }

    mostraModaleErroreQuestionarioCorrelato = (messaggioErrore) => {
        this.setState({
            visualizzaModaleErroreQuestionarioASSOCorrelato: true,
            messaggioErroreQuestionarioASSOCorrelato: messaggioErrore
        });

    }

    // #region trova l'ASSO con tutte le risposte o lo crea , senza, nel caso non esiste, 
    recuperaQuestionarioASSOCorrelato = () => {

        const successFn = this.mostraQuestionarioAssoCorrelato;
        const errorFn = this.mostraModaleErroreQuestionarioCorrelato;
        const idAnagraficaRichiedente = this.props.location.state.utente.id;

        const data = {
            token: this.props.loginToken,
            method: "getquestionarioassobyidrichiedente",
            param: {
                anagrafica_richiedenti: idAnagraficaRichiedente,
            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const nuovoRecord = JSON.parse(response.data.data);
                    successFn(nuovoRecord);

                } else {
                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata recupero ASSO correlato error: " + error);
                errorFn(error.message);
            });

    };

    mostraStampaPdf = (datiPdfCreato) => {


        this.setState({
            apriModaleStampa: true,
            messaggioErroreStampaPdf: null,
            visualizzaModaleErroreStampaPdf: false,
            base64PdfQuestionario: datiPdfCreato.base64Pdf,
            richiestaCreazionePdf: false,
            nomeFilesStampaPdf: datiPdfCreato.nomeFilesPdf,
        });

    }

    mostraModaleErroreStampaPdf = (messaggioErrore) => {
        this.setState({
            visualizzaModaleErroreStampaPdf: true,
            messaggioErroreStampaPdf: messaggioErrore,
            apriModaleStampa: false,
            filesStampaPdf: null,
            richiestaCreazionePdf: false,
            nomeFilesStampaPdf: null,
        });

    }

    creaStampaPdf = (pdfDaHtml, codicePseudonomizzato) => {
        const successFn = this.mostraStampaPdf;
        const errorFn = this.mostraModaleErroreStampaPdf;

        const data = {
            token: this.props.loginToken,
            method: "getpdfisa",
            param: {
                stringaHtml: pdfDaHtml,
                codicePseudonomizzato: codicePseudonomizzato,
                tipoQuestionarioAsso: false

            }
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    if (globals.debug < 3) console.log(JSON.parse(response.data.data));
                    const datiPdfCreato = JSON.parse(response.data.data);
                    successFn(datiPdfCreato);

                } else {
                    if (globals.debug < 3) console.log(response.data.result.message);
                    if (response.data.result.code === 401) {
                        loginErrorFn();
                    } else {
                        errorFn(response.data.result.message);
                    }
                }
            })
            .catch(function (error) {
                if (globals.debug < 3) console.log("chiamata creazione pdf  Isa questionario error: " + error);
                errorFn(error.message);
            });
    }
    // #endregion
}

// #region Redux
const mapStateToProps = state => {
    return {
        cacheMessaggi: state.cacheMessaggiISA,
        questionarioISA: state.questionarioISA,
        messaggioErroreScaricamentoQuestionarioISA: state.messaggioErroreISA,
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        scaricaQuestionarioISA: (token) => dispatch(actions.scaricaQuestionarioISA(token)),
        scaricaMessaggiSezione: (idMessaggi, cacheMessaggi) => dispatch(actions.scaricaMessaggiSezione(idMessaggi, cacheMessaggi)),
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};
// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(VisualizzaQuestionarioISA);
