import React, { Component, Fragment } from 'react';
import { ProgressBar, ListGroup, ListGroupItem, Panel, Well, Grid, Row, Col, Checkbox, Button, Label } from 'react-bootstrap';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as util from '../utility/objectsUtilities';
import Spinner from '../components/Spinner';
import ModaleErroreScaricamentoDefinizioneQuestionarioASSO from '../components/ModaleErroreScaricamentoDefinizioneQuestionarioASSO';
import axios from 'axios';
import '../css/questionari.css';
import ModaleErroreGenerica from '../components/ModaleErroreGenerica';
import * as globals from '../comuni/constants';


class QuestionarioASSO extends Component {

  state = {

    domandaCorrente: 0,
    risposte: [],
    questionarioInviabile: false,
    risultatiValidazione: null,
    redirectToHome: false,
    messaggioErroreRecuperoRisposte: null,
    idQuestionarioRisposto: null,
    redirectToIndex: false,
    selectedRow: null,
    checkValutazioneImpatto: false,
    checkTrattamentoDati: false,
    startQuestionario: false,
  };

  // #region Eventi
  rispostaClickedHandler = (risposta) => {

    if (this.state.selectedRow === null || this.state.selectedRow !== risposta.numero - 1) {
      this.setState({
        selectedRow: risposta.numero - 1
      });

      return;
    }

    let idQuestionario = this.state.idQuestionarioRisposto;
    let idDomanda = this.props.questionarioASSO.domande[this.state.domandaCorrente].id;
    let idRisposta = this.props.questionarioASSO.domande[this.state.domandaCorrente].risposte[risposta.numero - 1].id;

    this.updateQuestionarioASSO(this.setupdateQuestionarioASSOResults, this.setErrorMessageUpdateRisposte, idQuestionario, idDomanda, idRisposta);

    const risposte = [...this.state.risposte];
    risposte[this.state.domandaCorrente] = risposta.numero;

    if (typeof this.props.location.state.questionarioCreato !== "undefined" && this.props.location.state.questionarioCreato !== null &&
      typeof this.props.location.state.questionarioCreato.risposte !== "undefined" && this.props.location.state.questionarioCreato.risposte !== null &&
      this.props.location.state.questionarioCreato.risposte.length >= this.state.domandaCorrente) {
      this.props.location.state.questionarioCreato.risposte[this.state.domandaCorrente] = risposta.numero;
    }

    if ((this.state.domandaCorrente + 1) === this.props.questionarioASSO.domande.length) {
      this.setState({
        risposte: [...risposte],
        redirectToIndex: true,
        selectedRow: null,
      });

      return;
    }

    let prossimaDomanda = (this.state.domandaCorrente < this.props.questionarioASSO.domande.length - 1 ? this.state.domandaCorrente + 1 : this.state.domandaCorrente);

    this.setState({
      domandaCorrente: prossimaDomanda,
      risposte: [...risposte],
      selectedRow: null,
    });
  }


  domandaSuccessivaClickedHandler = (risposta) => {

    let prossimaDomanda = (this.state.domandaCorrente < this.state.risposte.length &&
      this.state.domandaCorrente < this.props.questionarioASSO.domande.length - 1 ?
      this.state.domandaCorrente + 1 : this.state.domandaCorrente);

    // Gestione caso specifico "nessuna scolarità"
    if (prossimaDomanda === 1 && this.state.risposte[0] === 7) {
      prossimaDomanda = 2;
    }

    this.setState({
      domandaCorrente: prossimaDomanda,
      selectedRow: null,
    });
  }

  domandaPrecedenteClickedHandler = (risposta) => {

    let prossimaDomanda = (this.state.domandaCorrente > 0 ? this.state.domandaCorrente - 1 : this.state.domandaCorrente);

    // Gestione caso specifico "nessuna scolarità"
    if (prossimaDomanda === 1 && this.state.risposte[0] === 7) {
      prossimaDomanda = 0;
    }

    this.setState({
      domandaCorrente: prossimaDomanda,
      selectedRow: null,
    });
  }

  indexClickedHandler = (risposta) => {

    this.setState({
      redirectToIndex: true,
      selectedRow: null,
    });
  }

  // #endregion



  // #region modale

  popolaModale = () => {
    let modale = null;

    if (this.props.messaggioErroreScaricamentoQuestionarioASSO !== null) {
      modale = <ModaleErroreScaricamentoDefinizioneQuestionarioASSO
        messaggioErrore={this.props.messaggioErroreScaricamentoQuestionarioASSO}
        chiudiModaleClicked={this.chiudiModaleErroreClicked} />
    }

    return modale;
  }

  chiudiModaleErroreClicked = () => {
    this.setState({ redirectToHome: true });
  }


  // #endregion

  // #region Render
  getRisposteDomandaCorrente = (domandaCorrente) => {

    let risposte = null;

    risposte = domandaCorrente.risposte.map((obj, i) => {

      const handler = () => {
        this.rispostaClickedHandler(obj);
      };

      let classe = null;

      if (typeof this.props.location.state.questionarioCreato !== "undefined" && this.props.location.state.questionarioCreato !== null &&
        typeof this.props.location.state.questionarioCreato.risposte !== "undefined" && this.props.location.state.questionarioCreato.risposte !== null &&
        this.props.location.state.questionarioCreato.risposte.length >= domandaCorrente.ordinale_domanda) {

        if (i === this.props.location.state.questionarioCreato.risposte[domandaCorrente.ordinale_domanda - 1] - 1) {
          classe = "previouslyselected";
        }
      }

      if (this.state.selectedRow !== null && i === this.state.selectedRow) {
        classe = "selected";
      }

      return <ListGroupItem className={classe} onClick={handler} key={i}><Row><Col xs={12}>{obj.numero}. {obj.descrizione}</Col></Row></ListGroupItem>
    });

    return risposte;
  }

  getRightNavbarButtons = () => {
    // Caso generale, non abbiamo ancora risposto a tutte le domande e siamo posizionati sulla prossima da compilare
    let rightNavButtons = (<React.Fragment>
      <i style={{ color: "lightgray", fontSize: "xx-large" }} className="fa fa-chevron-circle-right"></i>
      &nbsp;
      <i style={{ color: "lightgray", fontSize: "xx-large" }} className="fa fa-check-circle"></i>
    </React.Fragment>);

    // Abbiamo risposto a tutte le domande
    if (this.props.questionarioASSO.domande.length === this.state.risposte.length) {
      rightNavButtons = (
        <React.Fragment>
          <i style={{ cursor: "pointer", color: "black", fontSize: "xx-large" }} onClick={this.domandaSuccessivaClickedHandler} className="fa fa-pencil"></i>
          &nbsp;
          {(this.state.questionarioInviabile === true)
            ?
            <i style={{ cursor: "pointer", color: "green", fontSize: "xx-large" }} className="fa fa-check-circle"></i>
            :
            <i style={{ color: "red", fontSize: "xx-large" }} className="fa fa-exclamation-circle"></i>
          }
        </React.Fragment>
      );
    }

    // non abbiamo ancora risposto a tutte le domande e siamo posizionati su una domanda che non è l'ultima risposta
    if (this.state.risposte.length > this.state.domandaCorrente) {
      rightNavButtons = (
        <React.Fragment>
          <i style={{ cursor: "pointer", color: "black", fontSize: "xx-large" }} onClick={this.domandaSuccessivaClickedHandler} className="fa fa-chevron-circle-right"></i>
          &nbsp;
          <i style={{ color: "lightgray", fontSize: "xx-large" }} className="fa fa-check-circle"></i>
        </React.Fragment>
      );
    }

    return rightNavButtons;
  }

  toggleCheckValutazioneImpatto = () => {
    this.setState({ checkValutazioneImpatto: !this.state.checkValutazioneImpatto });
  }

  toggleCheckTrattamentoDati = () => {
    this.setState({ checkTrattamentoDati: !this.state.checkTrattamentoDati });
  }

  toggleStartQuestionario = () => {
    this.setState({ startQuestionario: !this.state.startQuestionario });
  }


  render() {

    if (this.state.redirectToHome === true) {
      return <Redirect to={{ pathname: "/" }} />
    }

    if ((this.state.checkTrattamentoDati === false || this.state.checkValutazioneImpatto === false) && this.state.startQuestionario === true) {
      return <ModaleErroreGenerica
        chiudiModaleClicked={() => { this.setState({ startQuestionario: false }) }}
        informazioniErrore="Per poter procedere alla compilazione del questionario è necessario contrassegnare entrambe le spunte." />
    }

    if (this.state.checkTrattamentoDati === false || this.state.checkValutazioneImpatto === false || this.state.startQuestionario === false) {

      const messaggioValutazioneImpatto = "L'operatore dichiara di essere a conoscenza della valutazione di impatto del trattamento effettuato dal proprio Ente di appartenenza (D.P.I.A.) nonché delle misure tecniche od organizzative adottate per ridurre tali rischi.";
      const messaggioTrattamentoDati = "L'operatore dichiara che l'utente è stato informato sullo specifico trattamento dei propri dati ed ha espresso il proprio consenso ai sensi del Reg. (UE) 2016/679.";

      let checkboxValutazioneImpatto = <Checkbox onClick={this.toggleCheckValutazioneImpatto}>{messaggioValutazioneImpatto}</Checkbox>
      if (this.state.checkValutazioneImpatto === true) {
        checkboxValutazioneImpatto = <Checkbox checked onClick={this.toggleCheckValutazioneImpatto}>{messaggioValutazioneImpatto}</Checkbox>
      }

      let checkboxTrattamentoDati = <Checkbox onClick={this.toggleCheckTrattamentoDati}>{messaggioTrattamentoDati}</Checkbox>
      if (this.state.checkTrattamentoDati === true) {
        checkboxTrattamentoDati = <Checkbox checked onClick={this.toggleCheckTrattamentoDati}>{messaggioTrattamentoDati}</Checkbox>
      }

      return (
        <React.Fragment>
          <Grid fluid={true}>
            <Row>
              <Col xs={12}>
                {checkboxValutazioneImpatto}
              </Col>
              <Col xs={12}>
                {checkboxTrattamentoDati}
              </Col>
            </Row>
            <Row>&nbsp;</Row>
            <Row>
              <Col xs={12}><Button style={{ width: "100%" }} bsStyle="success" onClick={this.toggleStartQuestionario}>Inizia la compilazione</Button></Col>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    if (this.state.domandaCorrente === 1 && this.state.risposte[0] === 7) {
      // Nel caso specifico di risposta 7 alla domanda 0 e passaggio alla successiva
      // renderizziamo niente in attesa di passare alla domanda dopo.
      return null;
    }

    if (this.state.redirectToIndex === true) {
      return <Redirect to={{
        pathname: "/IndiceQuestionariASSO", state: {
          risposte: this.state.risposte,
          IdQuestionario: this.props.location.state.questionarioCreato.id,
          codicePseudonomizzatoQuestionario: this.props.location.state.questionarioCreato.codicePseudonomizzato,
          utente: this.props.location.state.utente,
          questionarioCreato: this.props.location.state.questionarioCreato,
        }
      }} />
    }

    const modale = this.popolaModale();

    if (this.props.questionarioASSO === null) {
      return <Spinner />;
    }


    const domandaCorrente = this.props.questionarioASSO.domande[this.state.domandaCorrente];
    const risposte = this.getRisposteDomandaCorrente(domandaCorrente);

    let rightNavButtons = this.getRightNavbarButtons();
    let codicePseudonomizzato = this.props.location.state.questionarioCreato.codicePseudonomizzato ? this.props.location.state.questionarioCreato.codicePseudonomizzato : this.props.location.state.questionarioCreato.codicePseudonomizzatoQuestionario;

    return (
      <Fragment>
        <Well>
          <Link to="/">Home</Link> / <Link to="/CreaQuestionariASSO">Crea compilazione</Link> / <Link to={{ pathname: "/CreazioneQuestionari", state: { utente: this.props.location.state.utente } }}>Scegli ASSO o ISA</Link> / Compilazione ASSO
        </Well>
        <Grid fluid={true}>

          <Well bsSize="small">
            <Grid fluid={true}>
              <Row>
                <h4>ASSO {codicePseudonomizzato}  </h4>
              </Row>
              <Row>
                <h3>{domandaCorrente.titolo}</h3>
              </Row>
            </Grid>
          </Well>
          <Row>
            <Col xs={12}>
              <div className="text-right">
                {
                  this.state.domandaCorrente > 0 ?
                    <React.Fragment><i style={{ cursor: "pointer", fontSize: "xx-large" }} onClick={this.domandaPrecedenteClickedHandler} className="fa fa-chevron-circle-left"></i>&nbsp;</React.Fragment>
                    :
                    <React.Fragment>
                      <i style={{ color: "lightgrey", fontSize: "xx-large" }} className="fa fa-chevron-circle-left"></i>
                      &nbsp;
                  </React.Fragment>
                }
                {rightNavButtons}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="text-left"><h5>Item {domandaCorrente.numDomanda}</h5></Col>
            <Col xs={6} className="text-right" style={{ verticalAlign: "bottom" }}><Label style={{ cursor: "pointer", color: "white", fontSize: "small" }} onClick={this.indexClickedHandler} bsStyle="primary">Vai al riepilogo</Label></Col>
          </Row>
          <Row>
            <Panel >
              <Panel.Body>{domandaCorrente.descrizione}</Panel.Body>
            </Panel>
          </Row>
          <ListGroup className="striped">
            {risposte}
          </ListGroup>
          <Row>
            &nbsp;
          </Row>
          <Row>
            <Col className="text-right" xs={12}>{this.state.domandaCorrente + 1}/{this.props.questionarioASSO.domande.length}</Col>
            <Col xs={12}><ProgressBar now={(this.state.domandaCorrente + 1) * 100 / this.props.questionarioASSO.domande.length} /></Col>
          </Row>
          {modale}
        </Grid>

      </Fragment>)
  }

  // #endregion

  // #region Ciclo di vita del container
  componentDidMount = () => {

    if (globals.debug < 3) console.log("componentDidMount");

    if (this.props.questionarioASSO === null) {
      this.props.scaricaQuestionarioASSO(this.props.loginToken);
    } else {
      this.ripristinaStatoQuestionario();
    }

  }


  ripristinaStatoQuestionario = () => {
    //Rilevazione parametri ricevuti da IndiceQuestionario per tornare allo stato corretto (item e risposte)
    // ci si puo' arrivare dalla ricerca utenti, dalla Verifica e dall'indice
    // dall'indice arrivano anche le risposte in this.props.location.state.questionarioCreato.risposte
    if (typeof this.props.location.state.questionarioCreato !== "undefined" && this.props.location.state.questionarioCreato !== null) {

      let startQuestionario = false;
      let informativa_privacy = false;
      let valutazione_impatto = false;
      if (this.props.location.state.questionarioCreato.informativa_privacy !== false &&
        this.props.location.state.questionarioCreato.valutazione_impatto !== false) {
        startQuestionario = true;
        valutazione_impatto = true;
        informativa_privacy = true;
      }

      if (typeof this.props.location.state.item !== "undefined" && this.props.location.state.item !== null &&
        typeof this.props.location.state.item.id !== "undefined" && this.props.location.state.item.id !== null) {
        // Se arriviamo qui veniamo dall'indice o dalla validazione

        if (typeof this.props.location.state.questionarioCreato.risposte !== "undefined" && this.props.location.state.questionarioCreato.risposte !== null &&
          this.props.location.state.questionarioCreato.risposte.length > 0) {

          //per evitare loop
          if (!util.isEquivalent(this.props.location.state.questionarioCreato.risposte, this.state.risposte)) {

            this.setState({
              domandaCorrente: this.props.location.state.item.ordinale_domanda - 1,
              risposte: this.props.location.state.questionarioCreato.risposte,
              idQuestionarioRisposto: this.props.location.state.questionarioCreato.id,
              checkTrattamentoDati: informativa_privacy,
              checkValutazioneImpatto: valutazione_impatto,
              startQuestionario: startQuestionario,
            });
          }
          else { //caso ritorno  dalla Verifica : non esistono le risposte

            this.setState(
              {
                domandaCorrente: this.props.location.state.item.ordinale_domanda - 1,
                checkTrattamentoDati: true,
                checkValutazioneImpatto: true,
                startQuestionario: true,
              });
          }//per evitare loop
        }
      } // caso provenienza dall'indice o dalla validazione
      else {
        // Se arriviamo qui non siamo venuti ne dall'indice ne dalla validazione
        if (this.props.location.state.questionarioCreato.risposte.length === this.props.questionarioASSO.domande.length) {
          this.setState({
            risposte: this.props.location.state.questionarioCreato.risposte,
            redirectToIndex: true,
            checkTrattamentoDati: true,
            checkValutazioneImpatto: true,
            startQuestionario: true,
          });
        }
        else {
          this.setState({
            domandaCorrente: this.props.location.state.questionarioCreato.risposte.length,
            risposte: this.props.location.state.questionarioCreato.risposte,
            idQuestionarioRisposto: this.props.location.state.questionarioCreato.id,
            checkTrattamentoDati: informativa_privacy,
            checkValutazioneImpatto: valutazione_impatto,
            startQuestionario: startQuestionario,
          });
        }
      }
    }
  }


  setupdateQuestionarioASSOResults = (record) => {
  }

  setErrorMessageUpdateRisposte = (errorMessage) => {
    this.setState({
      messaggioErroreRecuperoRisposte: errorMessage
    });
  }


  updateQuestionarioASSO = (successFn,
    errorFn,
    asso_questionari_compilati,
    asso_domande,
    asso_risposte
  ) => {

    const data = {
      token: this.props.loginToken,
      method: "insertupdaterispostequestionarioasso",
      param: {
        asso_questionari_compilati: asso_questionari_compilati,
        asso_domande: asso_domande,
        asso_risposte: asso_risposte,
        valutazione_impatto: this.state.checkValutazioneImpatto,
        informativa_privacy: this.state.checkTrattamentoDati,
      }
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const nuovoRecord = JSON.parse(response.data.data);
          successFn(nuovoRecord);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (response.data.result.code === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata creazione questionario error: " + error);
        errorFn(error.message);
      });

  };


  componentDidUpdate = () => {
    if (globals.debug < 3) console.log("componentDidUpdate");

    if (this.state.domandaCorrente === 1 && this.state.risposte[0] === 7) {
      this.rispostaClickedHandler(this.props.questionarioASSO.domande[1].risposte[0]);
    }
  }


  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (globals.debug < 3) console.log("getSnapshotBeforeUpdate");

    if (this.props.questionarioASSO !== prevProps.questionarioASSO) {
      this.ripristinaStatoQuestionario();
    }

    return null;
  }
  // #endregion



}

// #region Redux
const mapStateToProps = state => {
  return {
    questionarioASSO: state.questionarioASSO,
    messaggioErroreScaricamentoQuestionarioASSO: state.messaggioErroreASSO,
    loginToken: state.loginToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    scaricaQuestionarioASSO: (token) => dispatch(actions.scaricaQuestionarioASSO(token)),
    effettuaLogout: () => dispatch(actions.effettuaLogout()),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(QuestionarioASSO);
