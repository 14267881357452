import React, { Component } from 'react';
import FieldGroup from './FieldGroup';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';

class ModaleModificaPassword extends Component {

    state = {
        messaggioErrore: null,
    };

    validaInput = () => {
        let messaggioErrore = "";

        if (document.getElementById("username").value.length === 0) {
            messaggioErrore += "Devi inserire lo username. ";
        }

        if (document.getElementById("vecchiapassword").value.length === 0) {
            messaggioErrore += "Devi inserire la vecchia password. ";
        }

        if (document.getElementById("nuovapassword").value.length === 0) {
            messaggioErrore += "Devi inserire la password. ";
        }

        if (document.getElementById("confermapassword").value.length === 0) {
            messaggioErrore += "Devi inserire la conferma password. ";
        }

        if (document.getElementById("nuovapassword").value !== document.getElementById("confermapassword").value) {
            messaggioErrore += "Le password devono essere identiche. ";
        }

        if (messaggioErrore.length === 0) {
            this.effettuaCambioPassword(
                document.getElementById("username").value,
                document.getElementById("vecchiapassword").value,
                document.getElementById("nuovapassword").value);
        } else {
            this.setState({ messaggioErrore: messaggioErrore });
        }

    }

    effettuaCambioPassword = (username, vecchiapassword, nuovapasword) => {

        const data = {
            token: "",
            method: "login",
            param: {
                username: username, 
                password: vecchiapassword, 
                nuova_password: nuovapasword,
            }
        };

        const successFn = () => {
            this.props.effettuaLogin("", "");
        }

        const errorFn = (messaggio) => {
            this.setState({ messaggioErrore: messaggio });
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {
                if (globals.debug < 3)console.log(response.data);

                if (response.data.result.success === true) {
                    successFn();
                } else {
                    if (globals.debug < 3)console.log("Errore logico di login " + JSON.stringify(response.data));
                    errorFn(response.data.result.message);
                }

            })
            .catch(function (error) {
                if (globals.debug < 3)console.log("Eccezione: " + JSON.stringify(error))
                errorFn(error.message);
            });

    };

    render() {

        return (<div id="messaggioVisibileModal" className="modal fade in" style={{ display: "block" }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <span>Devi modificare la password</span>
                    <form>
                        <div className="modal-body row">
                            <FieldGroup
                                id="username"
                                type="text"
                                label="Nome utente"
                                placeholder="nome utente"
                            />
                            <FieldGroup
                                id="vecchiapassword"
                                type="password"
                                label="Vecchia password"
                                placeholder="vecchia password"
                            />
                            <FieldGroup
                                id="nuovapassword"
                                type="password"
                                label="Nuova password"
                                placeholder="nuova password"
                            />
                            <FieldGroup
                                id="confermapassword"
                                type="password"
                                label="Conferma password"
                                placeholder="conferma password"
                            />
                        </div>
                    </form>
                    <span style={{ color: "red" }}>{this.state.messaggioErrore}</span>
                    <div className="modal-footer row">
                        <button onClick={this.props.chiudiModaleClicked} type="button" className="btn btn-md btn-default" data-dismiss="modal">Annulla</button>
                        <button onClick={() => { this.validaInput(); }} type="button" className="btn btn-md btn-success">Conferma</button>
                    </div>
                </div>
            </div>
        </div>);
    }
}


const mapDispatchToProps = dispatch => {
    return {
        effettuaLogin: (username, password) => dispatch(actions.effettuaLogin(username, password)),
    }
};
// #endregion


export default connect(null, mapDispatchToProps)(ModaleModificaPassword);

